import { useEffect } from "react";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	IconButton,
	Link,
	Chip,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CommentIcon from '@mui/icons-material/Comment';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { jwt } from "../../utils/index.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.third.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
	activeChip: {
		backgroundColor: theme.palette.green.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
	inactiveChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
	actionButton: {
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const Composition = ({
	setChangeLiveDocumentPopupOpen,
	publication,
	classes,
	setAddDocumentVersionPopupOpen,
	deleteFileHandler,
	setComment,
	setVersionCommentPopupOpen,
}) => {
	const newClasses = useStyles();
	useEffect(() => {
		// console.log("Composition mounted!");
	}, []);
	return (
		<Grid
			container
			item
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			// xs={3.95}
			sx={{
				borderRadius: "20px",
			}}
			className={classes.publicationInfoCard}
		>
			<Grid
				container
				item
				width="100%"
				pl={2}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{"Composition"}
					</Typography>
				</Grid>
			</Grid>
			<List dense>
				<ListItem
					sx={{
						mt: 1,
					}}
					secondaryAction={(
						<IconButton
							edge="end"
							onClick={() => setChangeLiveDocumentPopupOpen(true)}
						>
							<DriveFileRenameOutlineIcon className={newClasses.actionButton} />
						</IconButton>
					)}
				>
					<ListItemAvatar>
						<Avatar sx={{ bgcolor: "third.main" }}>
							<FavoriteIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={(
							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"Live document:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" pr={1.5} color={publication.document_live_url ? "green.main" : "gray"}>
									{
										publication.document_live_url
											? (
												<Link href={publication.document_live_url} target="_blank" rel="noopener" className={newClasses.link}>
													{"Link"}
												</Link>
											)
											: "No URL"
									}
								</Typography>
							</Grid>
						)}
					/>
				</ListItem>
				<ListItem
					sx={{
						pt: 1,
					}}
					secondaryAction={(
						<IconButton
							edge="end"
							onClick={() => setAddDocumentVersionPopupOpen(true)}
						>
							<ControlPointIcon className={newClasses.actionButton} />
						</IconButton>
					)}
				>
					<ListItemAvatar>
						<Avatar sx={{ bgcolor: "third.main" }}>
							<DocumentScannerIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={(
							<>
								<Grid container style={{ alignItems: "baseline" }}>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Document versions:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									{publication.finalFilesRevisions.length === 0 && (
										<Typography fontSize="0.9rem" color="gray">
											{"No versions"}
										</Typography>
									)}
								</Grid>
								{
									publication.finalFilesRevisions.map((e, ind) => (
										<Grid
											key={ind}
											container
											style={{
												alignItems: "center",
											}}
										>
											<Chip
												label={`V${e.version}`}
												className={
													ind === 0
														? newClasses.activeChip
														: newClasses.inactiveChip
												}
												sx={{
													mr: 1,
													mt: 0.3,
												}}
												onDelete={() => {
													deleteFileHandler(e.savedName, e._id);
												}}
											/>
											{e.comments && (
												<CommentIcon
													sx={{
														mr: 1,
													}}
													className={classes.buttonCursor}
													color="third"
													onClick={() => {
														setComment(e.comments);
														setVersionCommentPopupOpen(true);
													}}
												/>
											)}
											<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.folder}/${e.savedName}?token=${jwt.getToken()}`} target="_blank" className={newClasses.link}>
												{`${e.originalName.slice(0, 18)}
												${e.originalName.length > 18 ? "..." : ""}`}
											</Link>
										</Grid>
									))
								}

							</>
						)}
					/>
				</ListItem>
			</List>
		</Grid>
	);
};

export default Composition;
