import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Link,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import FileUpload from "../FileUpload.js";
import Form from "../Form.js";
import { jwt } from "../../utils/index.js";
import { addAssetToPublication } from "../../api/index.js";
import Popup from "../Popup.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.third.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const newAssetFormContent = [
	{
		customType: "input",
		id: "title",
		type: "input",
		multiline: false,
		width: 400,
		placeholder: "Title",
	},
	{
		customType: "button",
		id: "submit",
		type: "submit",
		text: "Add asset",
	},
];

const newAssetFormContentLink = [
	{
		customType: "input",
		id: "title",
		type: "input",
		multiline: false,
		width: 400,
		placeholder: "Title",
	},
	{
		customType: "input",
		id: "link",
		type: "input",
		multiline: true,
		minRows: 3,
		width: 400,
		placeholder: "Link",
	},
	{
		customType: "button",
		id: "submit",
		type: "submit",
		text: "Add asset",
	},
];

const AddAssetPopup = ({
	publicationId,
	addAssetType,
	open,
	setOpen,
	setIsLoading,
	folder,
	success,
	error,
	getAssets,
	setTabValue,
	fetchData,
}) => {
	const [savedName, setSavedName] = useState("");
	const [originalName, setOriginalName] = useState("");
	const classes = useStyles();

	useEffect(() => {
		// console.log("Component mounted!");
	}, []);

	useEffect(() => {
		// console.log(addAssetType);
	}, [addAssetType]);

	const setUploadSuccessInfo = ({ originalName: oName, saveName: sName }) => {
		console.log(sName, oName);
		setSavedName(sName);
		setOriginalName(oName);
	};

	const uploadSuccess = async ({ title, link }) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addAssetToPublication(
				publicationId, folder, originalName, savedName, addAssetType, title, link,
			);

			if (successCode) {
				success(message);
				getAssets();
			} else {
				error(message);
			}

			setOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setSavedName(null);
		setOriginalName(null);
		setIsLoading(false);
		if (addAssetType === 'file') {
			setTabValue(0);
		} else if (addAssetType === 'image') {
			setTabValue(1);
		} else { // 'link'
			setTabValue(2);
		}
	};

	return (
		<Popup
			width="800px"
			open={open}
			title={`Add new ${addAssetType}`}
			onClose={() => {
				setOpen(false);
			}}
		>
			{
				addAssetType === "file" || addAssetType === "image"
					? (
						<Grid
							container
							flexDirection="column"
							flex={1}
							sx={{
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								mb: 3,
							}}
						>
							<FileUpload
								id="file-upload"
								folder={folder}
								component="button"
								color="third"
								onSuccess={setUploadSuccessInfo}
								onUploadStart={() => {
									setIsLoading(true);
								}}
								onUploadStop={() => {
									setIsLoading(false);
								}}
							/>
							<Grid sx={{ mt: 1 }}>
								{!savedName && <Typography color="grey.dark">{"No uploaded file!"}</Typography>}
								{savedName
									&& (
										<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${folder}/${savedName}?token=${jwt.getToken()}`} target="_blank" className={classes.link}>
											{originalName || "Download File"}
										</Link>
									)}
							</Grid>
						</Grid>
					) : ""
			}
			<Form
				content={addAssetType === 'link' ? newAssetFormContentLink : newAssetFormContent}
				onSubmit={uploadSuccess}
			/>
		</Popup>
	);
};

export default AddAssetPopup;
