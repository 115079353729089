import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useEffect, useState } from "react";
import Spinner from "../components/Spinner.js";
import { useSnackbar, dayjs, jwt } from "../utils/index.js";
import {
	removePublication,
	getUserPublications,
	getTeamsData,
} from "../api/index.js";
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import PublicationFilters from "../components/PublicationFilters.js";
import { PublicationsComponent } from "../components/PublicationsComponent.js";

const useStyles = makeStyles((theme) => ({
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		borderRadius: theme.spacing(2),
		alignItems: "center",
	},
}));

const Publications = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [teams, setTeams] = useState([{ value: "All teams", text: "All teams" }]);
	const [publications, setPublications] = useState([]);
	const [filteredPublications, setFilteredPublications] = useState([]);

	const [publicationToDelete, setPublicationToDelete] = useState({});
	const [deletePublicationPopupOpen, setDeletePublicationPopupOpen] = useState(false);
	const classes = useStyles();

	// const [expandIconColor, setExpandIconColor] = useState('');
	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const user = jwt.decode();
				const { success: scs, publications: pbs } = await getUserPublications(user.id);
				const cleanPbs = pbs.filter((item) => item !== null);
				// Sort the publications by last updated
				cleanPbs.sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)));
				// console.log(cleanPbs);
				if (scs) {
					setPublications(cleanPbs);
				} else {
					error();
				}

				const { success: scst, teams: tms } = await getTeamsData();
				if (scst) {
					const teamsList = tms.map((e) => ({ value: e.name, text: e.name }));
					teamsList.unshift({ value: "All teams", text: "All teams" });
					setTeams(teamsList);
				} else {
					error();
				}
			} catch {
				error();
			}

			setIsLoading(false);
		},
		[error],
	);

	const deletePublication = async () => {
		setIsLoading(true);
		const { success: successCode, message } = await removePublication(publicationToDelete._id);

		if (successCode) {
			success(message);
		} else {
			error(message);
		}

		await fetchData();

		setIsLoading(false);
		setDeletePublicationPopupOpen(false);
		setPublicationToDelete({});
	};

	const declineDeletePublication = () => {
		setDeletePublicationPopupOpen(false);
		setPublicationToDelete({});
	};

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={deletePublicationPopupOpen}
				title="Delete publication?"
				content={`Are you sure you want to delete the publication "${publicationToDelete?.title}"?`}
				onDecline={declineDeletePublication}
				onAccept={deletePublication}
			/>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					className={classes.titleBox}
					justifyContent="space-between"
				>
					<Grid
						item
						ml={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h6" color="#ffffff">
							{"Your publications"}
						</Typography>
					</Grid>
					<PublicationFilters
						showTeamFilter
						showTypesFilter
						showStatesFilter
						teams={teams}
						publications={publications}
						setFilteredPublications={setFilteredPublications}
					/>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="space-between"
				>
					<Grid
						container
						item
						width="100%"
						display="flex"
						flexDirection="column"
					>
						<PublicationsComponent
							filteredPublications={filteredPublications}
							setPublicationToDelete={setPublicationToDelete}
							setDeletePublicationPopupOpen={setDeletePublicationPopupOpen}
							showTeam="true"
							publicPreview="false"
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(Publications);
