
export const calculateTimestampToShow = (timestamp) => {
	const now = new Date();
	// console.log(now);
	// console.log(timestamp);
	if (typeof timestamp === 'string') {
		timestamp = new Date(timestamp);
	}

	let diff = now.getTime() - timestamp.getTime();
	diff = (diff / 1000).toFixed(0); // in seconds
	let extension = "";
	if (diff < 60) {
		extension = "seconds ago";
	} else if (diff < 60 * 60) {
		diff = Math.floor(diff / 60);
		extension = diff === 1 ? "minute ago" : "minutes ago";
	} else if (diff < 60 * 60 * 24) {
		diff = Math.floor(diff / (60 * 60));
		extension = diff === 1 ? "hour ago" : "hours ago";
	} else {
		diff = Math.floor(diff / (60 * 60 * 24));
		extension = diff === 1 ? "day ago" : "days ago";
	}

	return `${diff} ${extension}`;
};

export const formatDate = (isoDate) => {
	if (!isoDate) return '';
	const date = isoDate.split('T')[0];
	const year = date.split('-')[0];
	const month = date.split('-')[1];
	const day = date.split('-')[2];
	return `${day}/${month}/${year}`;
};

