import { Grid, Typography, Chip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Popup from "../components/Popup.js";
import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";
import { getTeamProjects, createTeamProject, removeTeamProject } from "../api/index.js";
import { Project } from "../components/Project.js";
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import PublicIcon from '@mui/icons-material/Public';

const useStyles = makeStyles((theme) => ({
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		borderRadius: theme.spacing(2),
		alignItems: "center",
	},
	publicPageChip: {
		color: theme.palette.third.main,
		borderColor: theme.palette.third.main,
		border: "1px solid",
		"& .MuiChip-label": {
			color: theme.palette.third.main,
		},
		fontSize: "0.9rem",
		"&:hover": {
			borderColor: theme.palette.third.dark,
			color: theme.palette.third.dark,
			"& .MuiChip-label": {
				color: theme.palette.third.dark,
			},
		},
	},
	addProjectChip: {
		backgroundColor: theme.palette.light.main,
		"& .MuiChip-label": {
			color: "white",
		},
		fontSize: "1.2rem",
		"&:hover": {
			backgroundColor: theme.palette.light.dark,
		},
	},
}));

const TeamProjects = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState(projects);
	const [popupOpen, setPopupOpen] = useState(false);

	const [deleteProject, setDeleteProject] = useState({ id: null, name: null });
	const [deleteProjectPopupOpen, setDeleteProjectPopupOpen] = useState(false);
	const { teamid } = useParams();
	const classes = useStyles();
	const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, projects: prjs } = await getTeamProjects(teamid);
				if (scs) {
					// Order projects by startDate
					prjs.sort((a, b) => {
						if (a.startDate < b.startDate) {
							return 1;
						}

						if (a.startDate > b.startDate) {
							return -1;
						}

						return 0;
					});
					setProjects(prjs);
				} else {
					error();
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, teamid],
	);

	const submitHandler = async (values) => {
		setIsLoading(true);

		try {
			const { success: successCode, message } = await createTeamProject(teamid, values.title, values.website, values.logo);

			if (successCode) {
				success(message);
				setPopupOpen(false);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const onDelete = async () => {
		setIsLoading(true);

		const { success: successCode, message } = await removeTeamProject(teamid, deleteProject?._id);
		if (successCode) {
			success("Project deleted!");
		} else {
			console.log(message);
			error();
		}

		await fetchData();
		setDeleteProjectPopupOpen(false);
		setIsLoading(false);
		setDeleteProject({ id: null, title: null });
	};

	const onDecline = () => {
		setDeleteProjectPopupOpen(false);
		setDeleteProject({ id: null, title: null });
	};

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFilteredProjects(projects.filter((us) => isFuzzyMatch(us.title, searchFilter)));
	}, [searchFilter, projects]);

	const formContent = [
		{
			customType: "input",
			id: "title",
			type: "text",
			placeholder: "Title",
		},
		{
			customType: "input",
			id: "website",
			type: "text",
			placeholder: "Website URL",
		},
		{
			customType: "input",
			id: "logo",
			type: "text",
			placeholder: "Logo URL",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Create",
		},
	];

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					className={classes.titleBox}
				>
					<Grid
						item
						xs={7}
						pl={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h6" color="#ffffff">
							{"Team projects"}
						</Typography>
						<Chip
							icon={<PublicIcon color="third" />}
							label="Public page"
							variant="outlined"
							className={classes.publicPageChip}
							sx={{
								ml: 1,
							}}
							onClick={() => {
								navigate(`/teams/${teamid}/projects/public`);
							}}
						/>
						<Chip
							key={-1}
							label="+"
							className={classes.addProjectChip}
							sx={{
								mr: 0.5,
								ml: 1,
								"& .MuiChip-label": {
									color: "#ffffff",
								},
							}}
							onClick={() => setPopupOpen(true)}
						/>
					</Grid>
					<Grid
						item
						xs={5}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						height="100%"
					>
						<Search value={searchFilter} width="500px" onChange={(event) => setSearchFilter(event.target.value)} />
					</Grid>
				</Grid>

				{/* Current projects */}
				<Grid
					container
					item
					width="100%"
					p={1}
					pl={3}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5" color="#ffffff">
						{"Ongoing projects"}
					</Typography>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{filteredProjects
						.filter((p) => (p.endDate === undefined || p.endDate === null || new Date(p.endDate) > new Date()) && !p.isPlatform)
						.map((project, index) => (
							<Project
								key={`project-${index}`}
								project={project}
								index={index}
								setDeleteProject={setDeleteProject}
								setDeleteProjectPopupOpen={setDeleteProjectPopupOpen}
							/>
						))}
				</Grid>

				{/* Platforms / websites */}
				<Grid
					container
					item
					width="100%"
					p={1}
					pl={3}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5" color="#ffffff">
						{"Platforms / Websites"}
					</Typography>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{filteredProjects
						.filter((p) => p.isPlatform)
						.map((project, index) => (
							<Project
								key={`project-${index}`}
								project={project}
								index={index}
								setDeleteProject={setDeleteProject}
								setDeleteProjectPopupOpen={setDeleteProjectPopupOpen}
							/>
						))}
				</Grid>

				{/* Past projects */}
				<Grid
					container
					item
					width="100%"
					p={1}
					pl={3}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5" color="#ffffff">
						{"Past projects"}
					</Typography>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{filteredProjects
						.filter((p) => p.endDate !== undefined && p.endDate !== null && new Date(p.endDate) < new Date() && !p.isPlatform)
						.map((project, index) => (
							<Project
								key={`project-${index}`}
								project={project}
								index={index}
								setDeleteProject={setDeleteProject}
								setDeleteProjectPopupOpen={setDeleteProjectPopupOpen}
							/>
						))}
				</Grid>
			</Grid>
			<Popup
				width="800px"
				open={popupOpen}
				title="Create project"
				onClose={() => setPopupOpen(false)}
			>
				<Form content={formContent} onSubmit={submitHandler} />
			</Popup>
			<AreYouSurePopup
				open={deleteProjectPopupOpen}
				title="Delete project?"
				content={`Are you sure you want to delete the project "${deleteProject?.title}"?`}
				onDecline={onDecline}
				onAccept={onDelete}
			/>
		</>
	);
};

export default memo(TeamProjects);
