import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ToggleButton, Grid, Typography, Chip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	contentCard: {
		minHeight: "60px",
		borderRadius: "20px",
		alignItems: "center",
		background: theme.palette.secondary.main,
		boxShadow: "10px 3px 10px -5px #262835",
		flexDirection: "row",
		justifyContent: "space-evenly",
	},
	publicChip: {
		backgroundColor: theme.palette.green.main,
		"& .MuiChip-label": {
			color: "#ffffff",
		},
	},
	privateChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: "#ffffff",
		},
	},
	showButton: {
		backgroundColor: theme.palette.third.main,
		color: "#ffffff",
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
	deleteButton: {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.main,
		border: "1px solid",
		"&:hover": {
			borderColor: theme.palette.error.dark,
			color: theme.palette.error.dark,
		},
	},
}));

export const Project = ({
	project,
	index,
	setDeleteProject,
	setDeleteProjectPopupOpen,
}) => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Grid
			container
			item
			xs={2.8}
			m={0.5}
			p={3}
			className={classes.contentCard}
		>
			<Grid
				item
				container
				xs={5}
				width="100%"
				flexDirection="column"
				alignContent="center"
				justifyContent="center"
			>
				{project.logoUrl && (
					<Grid item p={1} backgroundColor="#fff" marginBottom="15px" display="flex" justifyContent="center">
						<img
							src={project.logoUrl}
							alt=""
							style={{
								height: "50px",
								objectFit: "contain",
							}}
						/>
					</Grid>
				)}
				{!project.logoUrl && (
					<Typography fontSize="1.2rem" color="#fff" fontWeight="bold" textAlign="center">
						{project.title.slice(0, 50)}
						{project.title.length > 50 ? "..." : ""}
					</Typography>
				)}
				{index !== undefined && (
					<Chip
						label={project.public ? "Public" : "Private"}
						className={project.public ? classes.publicChip : classes.privateChip}
						sx={{
							mr: 0.5,
							mt: 0.5,
							fontWeight: "bold",
						}}
					/>
				)}
				<Grid
					item
					container
					flexDirection="row"
					justifyContent="center"
					mt={1}
				>
					<ToggleButton
						value="Show"
						title="Show"
						size="small"
						aria-label="Show"
						className={classes.showButton}
						sx={{ mx: 1 }}
						onClick={() => { navigate(`/teams/${project.team}/projects/${project._id}${index === undefined ? "/public" : ""}`); }}
					>
						<VisibilityIcon color="white" />
					</ToggleButton>
					{index !== undefined && (
						<ToggleButton
							value="Delete"
							title="Delete"
							size="small"
							aria-label="delete"
							className={classes.deleteButton}
							sx={{ mx: 1 }}
							onClick={() => {
								setDeleteProject(project);
								setDeleteProjectPopupOpen(true);
							}}
						>
							<DeleteIcon color="inherit" />
						</ToggleButton>
					)}
				</Grid>
			</Grid>
			<Grid
				item
				container
				xs={6}
				alignContent="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Typography fontSize="0.9rem" color="#fff" fontWeight="bold" mb={0.5}>
					{project.title.slice(0, 50)}
					{project.title.length > 50 ? "..." : ""}
				</Typography>
				<Typography fontSize="0.8rem" color="#fff">
					{project.description?.slice(0, 150)}
					{project.description?.length > 150 ? "..." : ""}
				</Typography>
				{/* Add startDate and endDate */}
				<Grid item mt={1} display="flex" alignItems="center">
					<Typography color="green.main" fontSize="1rem">
						{"• Start: "}
					</Typography>
					<Typography color="#fff" fontSize="0.8rem" ml={1}>
						{project.startDate ? new Date(project.startDate).toDateString() : "—"}
					</Typography>
				</Grid>
				<Grid item display="flex" alignItems="center">
					<Typography color="green.main" fontSize="1rem">
						{"• End: "}
					</Typography>
					<Typography color="#fff" fontSize="0.8rem" ml={1}>
						{project.endDate ? new Date(project.endDate).toDateString() : "Ongoing"}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};
