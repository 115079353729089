import { useParams, useNavigate } from "react-router-dom";
import { Grid, ToggleButton, Typography } from "@mui/material";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { memo, useCallback, useEffect, useState } from "react";
import Spinner from "../components/Spinner.js";
import { useSnackbar } from "../utils/index.js";
import GenericMdEditor from "../components/MdEditor.js";
import ProjectInformationElement from "../components/ProjectInformationElement.js";
import {
	getPublicProject,
} from "../api/index.js";

const PublicProjectInfo = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState([]);
	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const { projectid } = useParams();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, project: prj, message } = await getPublicProject(projectid);
				if (scs) {
					setProject(prj);
				} else {
					console.log(message);
					error(message ?? '');
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					xs={12}
					display="flex"
					flexDirection="column"
					sx={{
						borderRadius: "20px",
					}}
				>
					<Grid
						item
						container
						xs={12}
						flexDirection="row"
						justifyContent="space-between"
					>
						<Grid
							item
							xs={3.9}
							overflow='auto'
							mt={2}
						>
							<Grid
								item
								width="100%"
							>
								{project.logoUrl && (
									<img
										src={project.logoUrl}
										alt=""
										style={{
											maxHeight: "100px",
											backgroundColor: "#ffffff",
											borderRadius: "10px",
											align: "center",
											padding: "10px",
										}}
									/>
								)}
								{!project.logoUrl && <ImageNotSupportedIcon color="third.main" fontSize="large" />}
							</Grid>

							<ProjectInformationElement
								project={project}
								title="Title"
								infoKey="title"
							/>
							<ProjectInformationElement
								project={project}
								title="Moto"
								infoKey="oneLiner"
							/>
							<ProjectInformationElement
								project={project}
								title="Description"
								infoKey="description"
							/>
							<ProjectInformationElement
								project={project}
								title="Funding source"
								infoKey="fundingSource"
							/>
							<ProjectInformationElement
								project={project}
								title="Operational body"
								infoKey="operationalBody"
							/>
							<ProjectInformationElement
								project={project}
								title="Funding code"
								infoKey="fundingCode"
							/>
							<ProjectInformationElement
								project={project}
								title="Start date"
								infoKey="startDate"
								infoType="date"
							/>
							<ProjectInformationElement
								project={project}
								title="End date"
								infoKey="endDate"
								infoType="date"
							/>
							<ProjectInformationElement
								project={project}
								title="Total budget"
								infoKey="totalBudget"
								postfix="€"
							/>
							<ProjectInformationElement
								project={project}
								title="Team budget"
								infoKey="teamBudget"
								postfix="€"
							/>
							<ProjectInformationElement
								project={project}
								title="Team role"
								infoKey="teamRole"
							/>
							<ProjectInformationElement
								project={project}
								title="External page"
								infoKey="externalPage"
							/>
							<ProjectInformationElement
								project={project}
								title="Research strand"
								infoKey="researchStrand"
								infoType="checkboxes-dropdown"
							/>
							<Grid
								item
								width="100%"
							>
								{project.snapshotUrl && (
									<img
										src={project.snapshotUrl}
										alt=""
										style={{
											maxHeight: "100px",
											backgroundColor: "#ffffff",
											borderRadius: "10px",
											align: "center",
											padding: "10px",
										}}
									/>
								)}
							</Grid>
							<ToggleButton
								value="Show"
								title="Show"
								size="small"
								aria-label="Show"
								sx={{ mt: 2 }}
								onClick={() => { navigate(`/teams/${project.team}/projects/public`); }}
							>
								<Typography
									fontSize="0.8rem"
									color="#73a2c0"
								>
									{"Back to projects"}
								</Typography>
								{/* <VisibilityIcon color="white" /> */}
							</ToggleButton>
						</Grid>
						<Grid
							item
							xs={8}
							sx={{
								width: "100%",
							}}
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
						>
							{project && (
								<GenericMdEditor
									readonly
									screen="ProjectInfo"
									element={project}
									setIsLoading={setIsLoading}
									success={success}
									error={error}
									mdHeader="Project full description"
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(PublicProjectInfo);
