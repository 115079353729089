/* eslint-disable arrow-body-style */
import { Fragment, useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { PublicationsList } from "./PublicationsList.js";

export const PublicationsBlock = ({
	item,
	yearIndex,
	setPublicationToDelete,
	setSelectedPublication,
	setDeletePublicationPopupOpen,
	showTeam,
	publicPreview,
}) => {
	const [totalPubs, setTotalPubs] = useState(0);

	const calculateOffset = (catIndex, pubIndex) => {
		// console.log(catIndex, pubIndex);
		let totalPubsOfYear = 0;
		for (const pub of item.publications) {
			totalPubsOfYear += pub.pubsOfType.length;
		}

		let sum = 0;
		for (let i = 0; i < catIndex; i++) {
			sum += item.publications[i].pubsOfType.length; // all pubs up to this point
		}

		return totalPubsOfYear + 1 - (sum + (pubIndex + 1));
	};

	useEffect(() => {
		let totalPubsOfYear = 0;
		for (const pub of item.publications) {
			totalPubsOfYear += pub.pubsOfType.length;
		}

		setTotalPubs(totalPubsOfYear);
	}, [item]);

	return (
		<Grid
			item
			container
			sx={{
				backgroundColor: "secondary.main",
				borderRadius: "20px",
				pb: 2,
			}}
		>
			<Grid
				container
				direction="row"
				alignItems="center"
				sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
				backgroundColor="third.main"
			>
				<Typography
					sx={{
						mx: 3,
						fontSize: "1.4rem",
						fontWeight: "bold",
						color: "green.main",
						backgroundColor: "white.main",
						py: 1,
						px: 2,
						ml: 4,
					}}
				>
					{item.year}
				</Typography>
				{
					item.publications.map((pub, k) => {
						return (
							<Fragment key={`${yearIndex}--${k}`}>
								<Typography
									key={`chip-${yearIndex}-${k}`}
									sx={{
										mr: 0.7,
										color: "dark.main",
									}}
								>
									{`${pub.type.text} - `}
								</Typography>
								<Typography
									key={`chip-2-${yearIndex}-${k}`}
									sx={{
										mr: 2,
										fontWeight: "bold",
										color: "dark.main",
									}}
								>
									{` ${pub.pubsOfType.length}`}
								</Typography>
							</Fragment>
						);
					})
				}
				<Typography
					sx={{
						mr: 2,
						color: "dark.main",
					}}
				>
					{"|"}
				</Typography>
				<Typography
					sx={{
						mr: 0.7,
						color: "primary.main",
					}}
				>
					{"Total -"}
				</Typography>
				<Typography
					sx={{
						mr: 2,
						fontWeight: "bold",
						color: "primary.main",
					}}
				>
					{totalPubs}
				</Typography>
			</Grid>

			<Grid
				container
				sx={{ mx: 2 }}
				direction="row"
				alignItems="center"
			>
				{
					item.publications.map((pub, catIndex) => {
						return (
							<Grid key={`pub-${catIndex}`} width="100%" sx={{ mt: 2 }}>
								{pub.type.text !== "Master thesis" && pub.type.text !== "Phd thesis" && pub.type.text !== "Proposal" && (
									<Typography sx={{ ml: 1, mb: 0.5, color: "white.main" }} variant="h5">{`${pub.type.text}s`}</Typography>
								)}
								<PublicationsList
									catIndex={catIndex}
									calculateOffset={calculateOffset}
									totalPubsOfYear={totalPubs}
									filteredPublications={pub.pubsOfType}
									setPublicationToDelete={setPublicationToDelete}
									setSelectedPublication={setSelectedPublication}
									setDeletePublicationPopupOpen={setDeletePublicationPopupOpen}
									showTeam={showTeam}
									publicPreview={publicPreview}
								/>
							</Grid>
						);
					})
				}
			</Grid>
		</Grid>

	);
};
