import * as Yup from "yup";

import { passwordMinLength } from "./constants.js";

Yup.addMethod(Yup.string, "isValidQueryParam", function (message) {
	return this.test("is-valid-query-param", message, function (value) {
		const { path, createError } = this;
		console.log(value);
		// Define the regex to allow only valid URL query characters
		const validQueryParamRegex = /^[\w!$%&'()*+,/:;=?@~-]*$/;

		// Check if the value matches the regex
		const isValid = validQueryParamRegex.test(value);
		console.log(isValid);
		// Return true if valid, otherwise create a validation error
		return (
			isValid
			|| createError({ path, message: message || "Invalid characters in query parameter" })
		);
	});
});

const email = Yup
	.string()
	.email("Invalid e-mail address")
	.required("E-mail address is required");

const username = Yup
	.string()
	.required("Username is required");

const agree = Yup.boolean().oneOf([true], "You have to agree");

const password = Yup
	.string()
	.min(passwordMinLength, `Password should contain at least ${passwordMinLength} characters`)
	.required("Password is required");

const confirmPassword = Yup
	.string()
	.min(passwordMinLength, `Password should contain at least ${passwordMinLength} characters`)
	.required("Password is required")
	.oneOf([Yup.ref("password")], "Passwords must match");

const name = Yup
	.string()
	.required("Name is required");

const description = Yup
	.string();

const website = Yup
	.string()
	.url("You must provide a valid website URL");

const logo = Yup
	.string()
	.url("You must provide a valid logo URL");

const title = Yup
	.string()
	.required("Title is required");

const publicationType = Yup
	.string()
	.url("You must provide a valid publication class")
	.required("Publication class is required");

const publicationState = Yup
	.string()
	.url("You must provide a valid publication state")
	.required("Publication state is required");

const slug = Yup
	.string()
	.required("Slug is required")
	.isValidQueryParam("Invalid slug");

const schemas = {
	authenticationSchema: Yup.object({ username, password }),
	forgotPasswordSchema: Yup.object({ username }),
	resetPasswordSchema: Yup.object({ password, confirmPassword }),
	signUpSchema: Yup.object({ username, email, password, confirmPassword }),
	exampleSchema: Yup.object({ username, password, agree }),
	inviteUserSchema: Yup.object({ email }),
	newTeamSchema: Yup.object({ name, description, website, logo }),
	addUserToTeamSchema: Yup.object({ email }),
	addPublicationToTeamSchema: Yup.object({ title, publicationType, publicationState }),
	// addTeamMemberToPublicationSchema: Yup.object({ member }),
	addNonTeamMemberToPublicationSchema: Yup.object({ email }),
	validQueryParamsSchema: Yup.object({ slug }),
};

export default schemas;
