/* eslint-disable no-else-return */
/* eslint-disable unicorn/catch-error-name */
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Typography, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import StarIcon from '@mui/icons-material/Star';

import { memo, useCallback, useEffect, useState, useMemo } from "react";
import Popup from "../components/Popup.js";
import FileUpload from "../components/FileUpload.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import Dialog from "../components/Dialog.js";
import {
	Events,
	Metadata,
	Composition,
	Reviews,
	Assignments,
	Comments,
	MdEditor,
	Assets,
} from '../components/PublicationComponents/index.js';
import { useSnackbar, jwt, useWindowDimensions } from "../utils/index.js";
import { publicationStates } from "../utils/publication-constants.js";
import {
	getPublication,
	addTeamAuthor,
	addExternalAuthor,
	deleteTeamAuthorFromPublication,
	deleteNonTeamAuthorFromPublication,
	deleteExternalAuthorFromPublication,
	statesChangePublication,
	typeChangePublication,
	// titleChangePublication,
	abstractChangePublication,
	keywordsChangePublication,
	liveDocumentChangePublication,
	publishedInfoChangePublication,
	targetChangePublication,
	addReviewingProcess,
	addTeamMemberReviewer,
	addNonTeamMemberReviewer,
	deleteReviewerFromReview,
	// deleteReviewFromPublication,
	deadlineChangePublication,
	deleteFile,
	addDocumentVersionPublication,
	deleteFileVersionFromPublication,
	addReviewPublication,
	setDocumentForReview,
	addRevisedDocument,
	addReviewersComments,
	addResponseToReviewers,
	lockReviewingProcess,
	addPreprintPublication,
	addCameraReadyPublication,
	deadlineChangeReview,
	addPublicationComment,
	moveAuthor,
	addSupervisor,
	deleteSupervisorFromPublication,
	addCorrespondingAuthor,
	removeCorrespondingAuthor,
} from "../api/index.js";

import {
	AreYouSurePopup,
	EditPublicationPopup,
	AddNonTeamMemberPopup,
} from "../components/Popups/index.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;
const STATES_TO_KEEP = 12;

const useStyles = makeStyles((theme) => ({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		padding: "0px 0px 0px 0px !important",
		color: theme.palette.white.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.white.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.white.main, // Set the desired color for the select's border when focused
		},
	},
	link: {
		color: theme.palette.grey.dark,
		textDecoration: "underline",
		"&:hover": {
			color: theme.palette.grey.dark,
			textDecoration: "underline",
		},
	},
	timeline: {
		// Reduce overall padding to make the timeline more compact
		padding: "6px 16px",
		margin: 0,
	},
	timelineItem: {
		// Reduce margin between timeline items
		marginTop: 0,
		marginBottom: 0,
		padding: 0,
		minHeight: 0,
	},
	timelineDot: {
		// Make the timeline dots smaller
		width: 0,
		height: 0,
	},
	publicationInfoCard: {
		marginBottom: '6px',
		width: "33%",
		// flexGrow: 1
		'@media (max-width: 1000px)': {
			width: '50%',
		},
		'@media (max-width: 650px)': {
			width: '100%',
		},
	},
	publicationTypeChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: theme.palette.primary.main,
		},
	},
	actionChip: {
		backgroundColor: theme.palette.light.main,
		"& .MuiChip-label": {
			color: theme.palette.primary.main,
		},
		"&:hover": {
			backgroundColor: theme.palette.light.dark,
		},
	},
	activeStateChip: {
		backgroundColor: theme.palette.green.main,
		"& .MuiChip-label": {
			color: "white",
		},
		"&:hover": {
			backgroundColor: theme.palette.green.dark,
		},
	},
	inactiveStateChip: {
		color: theme.palette.third.main,
		borderColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: theme.palette.third.main,
		},
		"&:hover": {
			color: theme.palette.third.dark,
			borderColor: theme.palette.third.dark,
			"& .MuiChip-label": {
				color: theme.palette.third.dark,
			},
		},
	},
	editAvatar: {
		backgroundColor: theme.palette.third.main,
		marginLeft: "6px",
		marginRight: "6px",
		width: "38px",
		height: "38px",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
	selectBox: {
		marginLeft: "10px",
		borderRadius: "10px",
		backgroundColor: theme.palette.white.main,
		"&:hover": {
			backgroundColor: theme.palette.white.dark,
		},
	},
	correspondingAuthorChecked: {
		fill: theme.palette.light.main,
	},
	correspondingAuthorUnchecked: {
		fill: 'white',
	},
}));

const PublicationInfo = () => {
	const windowDimensions = useWindowDimensions();
	const showEvents = useMemo(() => windowDimensions?.width > 1200, [windowDimensions.width]);

	const { error, success } = useSnackbar();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [publication, setPublication] = useState([]);
	const [review, setReview] = useState(null);
	const [comment, setComment] = useState(null);

	const [authorToDelete, setAuthorToDelete] = useState("");
	const [deleteAuthorPopupOpen, setDeleteAuthorPopupOpen] = useState(false);

	const [supervisorToDelete, setSupervisorToDelete] = useState("")
	const [deleteSupervisorPopupOpen, setDeleteSupervisorPopupOpen] = useState(false)
	const [addSupervisorPopupOpen, setAddSupervisorPopupOpen] = useState(false);
	const [newTeamMemberAsSupervisor, setNewTeamMemberAsSupervisor] = useState("");

	const [addAssistantSupervisorChipClicked, setAddAssistantSupervisorChipClicked] = useState(false)
	const [supervisorAlreadyExistsPopupOpen, setSupervisorAlreadyExistsPopupOpen] = useState(false)
	const [deleteAssistantSupervisorIconClicked, setDeleteAssistantSupervisorIconClicked] = useState(false)

	const [addCommentPopupOpen, setAddCommentPopupOpen] = useState(false);
	const [addTeamMemberPopupOpen, setAddTeamMemberPopupOpen] = useState(false);
	const [addNonTeamMemberPopupOpen, setAddNonTeamMemberPopupOpen] = useState(false);
	const [addNonRegisteredMemberPopupOpen, setAddNonRegisteredMemberPopupOpen] = useState(false);
	const [editPublicationPopupOpen, setEditPublicationPopupOpen] = useState(false);
	const [changeAbstractPopupOpen, setChangeAbstractPopupOpen] = useState(false);
	const [changeKeywordsPopupOpen, setChangeKeywordsPopupOpen] = useState(false);
	const [changeLiveDocumentPopupOpen, setChangeLiveDocumentPopupOpen] = useState(false);
	const [addDocumentVersionPopupOpen, setAddDocumentVersionPopupOpen] = useState(false);
	const [versionCommentPopupOpen, setVersionCommentPopupOpen] = useState(false);
	const [addReviewPopupOpen, setAddReviewPopupOpen] = useState(false);
	const [addPreprintPopupOpen, setAddPreprintPopupOpen] = useState(false);
	const [addCameraReadyPopupOpen, setAddCameraReadyPopupOpen] = useState(false);
	const [addRevisedDocumentPopupOpen, setAddRevisedDocumentPopupOpen] = useState(false);
	const [addReviewersCommentsPopupOpen, setAddReviewersCommentsPopupOpen] = useState(false);
	const [addResponseToReviewersPopupOpen, setAddResponseToReviewersPopupOpen] = useState(false);
	const [changePublishedPopupOpen, setChangePublishedPopupOpen] = useState(false);
	const [changeTargetPopupOpen, setChangeTargetPopupOpen] = useState(false);
	const [addTeamMemberReviewPopupOpen, setAddTeamMemberReviewPopupOpen] = useState(false);
	const [addNonTeamMemberReviewPopupOpen, setAddNonTeamMemberReviewPopupOpen] = useState(false);
	const [deadline, setDeadline] = useState(null);
	const [commentMentions, setCommentMentions] = useState([]);
	const [initialEventsSize, setInitialEventsSize] = useState(0);
	const [newTeamMemberAsAuthor, setNewTeamMemberAsAuthor] = useState("");
	const [newTeamMemberAsReviewer, setNewTeamMemberAsReviewer] = useState("");

	const [abstractShowDialog, setAbstractShowDialog] = useState(false);
	const { id: myid } = jwt.decode();

	const { publicationid } = useParams();
	const classes = useStyles();
	const [teamMembers, setTeamMembers] = useState([]);
	const [allPublicationAuthorsAndMembers, setAllPublicationAuthorsAndMembers] = useState([]);

	const [correspondingAuthors, setCorrespondingAuthors] = useState([]);
	const [correspondingAuthorAlreadyExistsPopupOpen, setCorrespondingAuthorAlreadyExistsPopupOpen] = useState(false);
	const [removeCorrespondingAuthorPopup, setRemoveCorrespondingAuthorPopup] = useState(false);
	const [correspondingAuthorToRemove, setCorrespondingAuthorToRemove] = useState("");

	// Add authors handling
	const [anchorElAddAuthor, setAnchorElAddAuthor] = useState(null);
	const openAddAuthor = Boolean(anchorElAddAuthor);
	const handleClickAddAuthor = (event) => {
		setAnchorElAddAuthor(event.currentTarget);
	};

	const handleCloseAddAuthor = () => {
		setAnchorElAddAuthor(null);
	};

	// Add review handling
	const [anchorElAddReview, setAnchorElAddReview] = useState(null);
	const openAddReview = Boolean(anchorElAddReview);
	const handleClickAddReview = (event) => {
		setAnchorElAddReview(event.currentTarget);
	};

	const handleCloseAddReview = () => {
		setAnchorElAddReview(null);
	};

	// Add reviewer handling
	const [anchorElAddReviewer, setAnchorElAddReviewer] = useState(null);
	const openAddReviewer = Boolean(anchorElAddReviewer);
	const handleClickAddReviewer = (event) => {
		setAnchorElAddReviewer(event.currentTarget);
	};

	const handleCloseAddReviewer = () => {
		setAnchorElAddReviewer(null);
	};

	// Add document for review handling
	const [anchorElAddDocumentForReview, setAnchorElAddDocumentForReview] = useState(null);
	const openAddDocumentForReview = Boolean(anchorElAddDocumentForReview);
	const handleClickAddDocumentForReview = (event) => {
		setAnchorElAddDocumentForReview(event.currentTarget);
	};

	const handleCloseAddDocumentForReview = () => {
		setAnchorElAddDocumentForReview(null);
	};

	// const steps = publicationStates;

	const [saveName, setSaveName] = useState("");
	const [originalName, setOriginalName] = useState("");
	const [folder, setFolder] = useState(null);

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, publication: pu, message } = await getPublication(publicationid);

				if (scs) {
					console.log("Publication:", pu);
					setInitialEventsSize(pu.eventsCount);
					// Reviews handling
					pu.finalReviews = pu.finalReviews.filter((item) => item.deleted !== true);
					pu.finalReviews.reverse();
					// Files revisions handling
					pu.finalFilesRevisions = pu.finalFilesRevisions.filter((item) => item !== null);
					pu.finalFilesRevisions.reverse();
					// Assignments handling
					pu.finalAssignments = pu.finalAssignments.filter((item) => item !== null);
					pu.finalAssignments.reverse();
					pu.finalAssignments.sort((a, b) => {
						if (a.resolved && !b.resolved) {
							return 1;
						} else if (!a.resolved && b.resolved) {
							return -1;
						} else {
							return 0;
						}
					});
					// Comments handling
					pu.finalComments = pu.finalComments.filter((item) => item !== null);
					pu.finalComments.reverse();
					// Authors handling
					pu.teamUsersNames.sort((a, b) => a.text.localeCompare(b.text));
					setTeamMembers(pu.teamUsersNames);
					// all authors and teamMembers (for assignments)
					const allTeamAndAuthors = [...pu.finalTeamAuthorNames, ...pu.finalNonTeamAuthorNames];
					for (const teamMember of pu.teamUsersNames) {
						const id = teamMember.value;
						const fullname = teamMember.text;
						// eslint-disable-next-line max-len
						const foundAuthor = allTeamAndAuthors.find((author) => author._id === id); // check if this member already exists as an author
						if (!foundAuthor) {
							allTeamAndAuthors.push({ _id: id, fullname });
						}
					}

					pu.tags.sort(function (a, b) {
						if (a.toLowerCase() < b.toLowerCase()) {
							return -1;
						}
						if (a.toLowerCase() > b.toLowerCase()) {
							return 1;
						}
						return 0;
					})
					setAllPublicationAuthorsAndMembers(allTeamAndAuthors);

					setCorrespondingAuthors(pu.correspondingAuthors)
					setFolder(pu._id);
					// console.log(pu);
					setPublication(pu);

				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (err) {
				error();
				console.log(err);
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, publicationid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const deleteFileHandler = async (savedName, fileId) => {
		setIsLoading(true);
		const { success: deleteSuccess } = await deleteFile({ folder, saveName: savedName });
		if (deleteSuccess) {
			const { success: pubVersionDeleteSuccess } = await deleteFileVersionFromPublication(publication._id, fileId);
			if (pubVersionDeleteSuccess) {
				setSaveName("");
				setOriginalName("");
				success("Document version deleted successfully");
			} else {
				error();
			}
		} else {
			error();
		}

		await fetchData();
		setIsLoading(false);
	};

	const addCommentFormContent = [
		{
			customType: "wideInput",
			id: "text",
			type: "text",
			value: "",
			multiline: true,
			minRows: 6,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add comment",
		},
	];

	const addTeamMemberFormContent = [
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add author",
		},
	];

	const addSupervisorFormContent = [
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add Supervisor",
		},
	];

	const addAssistantSupervisorFormContent = [
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add Assistant supervisor",
		},
	];

	const addTeamMemberReviewFormContent = [
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add reviewer",
		},
	];

	const addNonTeamMemberReviewFormContent = [
		{
			customType: "input",
			id: "email",
			type: "text",
			placeholder: "e-mail",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add reviewer",
		},
	];

	const addNonRegisteredMemberFormContent = [
		{
			customType: "input",
			id: "fullname",
			type: "text",
			placeholder: "Full name",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add author",
		},
	];

	const changeAbstractFormContent = [
		{
			customType: "wideInput",
			id: "abstract",
			type: "text",
			value: publication.abstract,
			multiline: true,
			minRows: 8,
			width: 800,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Change abstract",
		},
	];

	const changeKeywordsFormContent = [
		{
			customType: "wideInput",
			id: "keywords",
			type: "text",
			value: publication.keywords ? publication.keywords.join(", ") : "",
			multiline: true,
			minRows: 2,
			width: 800,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Change keywords",
		},
	];

	const changeLiveDocumentFormContent = [
		{
			customType: "wideInput",
			id: "live_document",
			type: "text",
			value: publication.document_live_url,
			width: 800,
			placeholder: "Live document URL (Google docs, Overleaf etc.)",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Change live document URL",
		},
	];

	const changePublishedFormContent = [
		{
			customType: "wideInput",
			id: "url",
			type: "text",
			value: publication.published_url,
			width: 800,
			placeholder: "URL of the published paper",
		},
		{
			customType: "wideInput",
			id: "citation",
			type: "text",
			value: publication.citation,
			multiline: true,
			minRows: 10,
			width: 800,
			placeholder: "Citation in Bibtex format",
		},
		{
			customType: "date-picker",
			id: "publication_date",
			type: "desktop",
			label: "Publication date",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Change published information",
		},
	];

	const changeTargetFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "text",
			value: publication.target,
			placeholder: "Title",
		},
		{
			customType: "wideInput",
			id: "url",
			type: "text",
			value: publication.targetUrl,
			placeholder: "Target URL",
		},
		{
			customType: "wideInput",
			id: "template",
			type: "text",
			value: publication.targetTemplate,
			placeholder: "Document template",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Change target",
		},
	];

	const newVersionUploadFormContent = [
		{
			customType: "wideInput",
			id: "comments",
			type: "text",
			multiline: true,
			minRows: 5,
			width: 800,
			placeholder: "Any comments about the current version",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add version",
		},
	];

	const newReviewUploadFormContent = [
		{
			customType: "wideInput",
			id: "comments",
			type: "text",
			multiline: true,
			minRows: 5,
			width: 800,
			placeholder: "Any comments about the current review",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add review",
		},
	];

	const setUploadSuccessInfo = ({ originalName: oName, saveName: sName }) => {
		setSaveName(sName);
		setOriginalName(oName);
	};

	// Document version upload form
	const uploadSuccess = async ({ comments }) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addDocumentVersionPublication(
				publication._id, folder, originalName, saveName, comments,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddDocumentVersionPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setSaveName(null);
		setOriginalName(null);
		setIsLoading(false);
	};

	const reviewUploadSuccess = async ({ comments }) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addReviewPublication(
				publication._id, folder, originalName, saveName, review._id, comments,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddReviewPopupOpen(false);
		} catch (error_) {
			error("Something went terribly wrong...");
			console.log(error_);
			setAddReviewPopupOpen(false);
		}

		await fetchData();
		setSaveName(null);
		setOriginalName(null);
		setIsLoading(false);
	};

	const preprintUploadSuccess = async ({ originalName: oName, saveName: sName }) => {
		setIsLoading(true);
		setSaveName(sName);
		setOriginalName(oName);
		try {
			const { success: successCode, message } = await addPreprintPublication(publication._id, folder, oName, sName);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddPreprintPopupOpen(false);
		} catch (error_) {
			error("Something went terribly wrong...");
			console.log(error_);
			setAddPreprintPopupOpen(false);
		}

		await fetchData();
		setIsLoading(false);
	};

	const clearPreprint = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addPreprintPublication(publication._id, null, null, null);

			if (successCode) {
				success("Preprint cleared");
			} else {
				error(message);
			}
		} catch (error_) {
			error("Something went terribly wrong...");
			console.log(error_);
		}

		await fetchData();
		setIsLoading(false);
	};

	const clearCameraReady = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addCameraReadyPublication(publication._id, null, null, null);

			if (successCode) {
				success("Camera-ready file cleared");
			} else {
				error(message);
			}
		} catch (error_) {
			error("Something went terribly wrong...");
			console.log(error_);
		}

		await fetchData();
		setIsLoading(false);
	};

	const cameraReadyUploadSuccess = async ({ originalName: oName, saveName: sName }) => {
		setIsLoading(true);
		setSaveName(sName);
		setOriginalName(oName);
		try {
			const { success: successCode, message } = await addCameraReadyPublication(
				publication._id, folder, oName, sName,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddCameraReadyPopupOpen(false);
		} catch {
			error("Something went terribly wrong...");
			setAddCameraReadyPopupOpen(false);
		}

		await fetchData();
		setIsLoading(false);
	};

	const revisedDocumentUploadSuccess = async ({ originalName: oName, saveName: sName }) => {
		setIsLoading(true);
		setSaveName(sName);
		setOriginalName(oName);
		try {
			const { success: successCode, message } = await addRevisedDocument(publication._id, review._id, oName, sName, folder);

			if (review.type === "external") {
				const { success: successCode2, message2 } = await addReviewPublication(publication._id, folder, oName, sName, review._id);

				if (successCode2) {
					success(message2);
				} else {
					error(message2);
				}
			}

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddRevisedDocumentPopupOpen(false);
		} catch {
			error("Something went terribly wrong...");
			setAddRevisedDocumentPopupOpen(false);
		}

		await fetchData();
		setIsLoading(false);
	};

	const reviewersCommentsUploadSuccess = async ({ originalName: oName, saveName: sName }) => {
		setIsLoading(true);
		setSaveName(sName);
		setOriginalName(oName);
		try {
			const { success: successCode, message } = await addReviewersComments(publication._id, review._id, oName, sName, folder);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddReviewersCommentsPopupOpen(false);
		} catch {
			error("Something went terribly wrong...");
			setAddReviewersCommentsPopupOpen(false);
		}

		await fetchData();
		setIsLoading(false);
	};

	const responseToReviewersUploadSuccess = async ({ originalName: oName, saveName: sName }) => {
		setIsLoading(true);
		setSaveName(sName);
		setOriginalName(oName);
		try {
			const { success: successCode, message } = await addResponseToReviewers(publication._id, review._id, oName, sName, folder);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddResponseToReviewersPopupOpen(false);
		} catch {
			error("Something went terribly wrong...");
			setAddResponseToReviewersPopupOpen(false);
		}

		await fetchData();
		setIsLoading(false);
	};

	const addCommentSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			console.log(values, commentMentions);
			const { success: successCode, message } = await addPublicationComment(publication._id, commentMentions, values.text);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddCommentPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setCommentMentions([]);
		setIsLoading(false);
	};

	const addTeamMemberSubmitHandler = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addTeamAuthor(
				publication._id,
				newTeamMemberAsAuthor,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddTeamMemberPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setNewTeamMemberAsAuthor("");
		setIsLoading(false);
	};

	const addSupervisorSubmitHandler = async (isMain) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addSupervisor(
				publication._id,
				newTeamMemberAsSupervisor,
				isMain
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddSupervisorPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setNewTeamMemberAsSupervisor("");
		setIsLoading(false);
		setAddAssistantSupervisorChipClicked(false);
	};

	const addTeamMemberReviewSubmitHandler = async () => {
		setIsLoading(true);
		try {
			// eslint-disable-next-line operator-linebreak
			const { success: successCode, message } =
				await addTeamMemberReviewer(publication._id, review._id, newTeamMemberAsReviewer);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddTeamMemberReviewPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setNewTeamMemberAsReviewer("");
		setIsLoading(false);
	};

	const addNonTeamMemberReviewSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			console.log(review, values);
			const { success: successCode, message } = await addNonTeamMemberReviewer(
				publication._id, review._id, values.email,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddNonTeamMemberReviewPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addExternalMemberSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addExternalAuthor(publication._id, values.fullname);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddNonRegisteredMemberPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	// const removeTeamMemberSubmitHandler = async (memberId) => {
	// 	setIsLoading(true);
	// 	try {
	// 		const { success: successCode, message } = await deleteTeamAuthorFromPublication(publication._id, memberId);

	// 		if (successCode) {
	// 			success(message);
	// 		} else {
	// 			error(message);
	// 		}
	// 	} catch { /* empty */ }

	// 	await fetchData();
	// 	setIsLoading(false);
	// };

	const removeAuthorSubmitHandler = async () => {
		const fullname = authorToDelete;
		setIsLoading(true);
		try {
			let successCode = null;
			let message = null;
			// Check case
			if (publication.finalTeamAuthorNames.some((item) => item.fullname === fullname)) {
				// Team member
				const member = publication.finalTeamAuthorNames.find((author) => author.fullname.includes(fullname));
				console.log(member);
				const response = await deleteTeamAuthorFromPublication(publication._id, member._id);
				successCode = response.success;
				message = response.message;
			} else if (publication.finalNonTeamAuthorNames.some((item) => item.fullname === fullname)) {
				// Non team member, but registered
				const member = publication.finalNonTeamAuthorNames.find((author) => author.fullname.includes(fullname));
				console.log(member);
				const response = await deleteNonTeamAuthorFromPublication(publication._id, member._id);
				successCode = response.success;
				message = response.message;
			} else {
				// External author
				const response = await deleteExternalAuthorFromPublication(publication._id, fullname);
				successCode = response.success;
				message = response.message;
			}

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
		setAuthorToDelete("");
		setDeleteAuthorPopupOpen(false);
	};

	const declineRemoveAuthor = () => {
		setAuthorToDelete("");
		setDeleteAuthorPopupOpen(false);
	};

	const removeSupervisorSubmitHandler = async () => {
		const fullname = supervisorToDelete
		console.log('FULLNAME', fullname)
		setIsLoading(true)
		try {
			let successCode = null
			let message = null

			const supervisor = allPublicationAuthorsAndMembers.find((member) => member.fullname === fullname)
			console.log('MEMBER', supervisor)

			const response = await deleteSupervisorFromPublication(publication._id, supervisor._id)
			successCode = response.success
			message = response.message

			if (successCode) {
				success(message);
			} else {
				error(message);
			}


		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
		setSupervisorToDelete("")
		setDeleteSupervisorPopupOpen(false)
		setDeleteAssistantSupervisorIconClicked(false)
	}

	const removeCorrespondingAuthorHandler = async () => {
		const fullname = correspondingAuthorToRemove;
		setIsLoading(true)
		try {
			let successCode = null;
			let message = null;
			// Check case
			if (publication.finalTeamAuthorNames.some((item) => item.fullname === fullname)) {
				// Team member
				const member = publication.finalTeamAuthorNames.find((author) => author.fullname.includes(fullname));
				console.log(member);
				const response = await removeCorrespondingAuthor(publication._id, member._id, fullname);
				successCode = response.success;
				message = response.message;
			} else if (publication.finalNonTeamAuthorNames.some((item) => item.fullname === fullname)) {
				// Non team member, but registered
				const member = publication.finalNonTeamAuthorNames.find((author) => author.fullname.includes(fullname));
				console.log(member);
				const response = await removeCorrespondingAuthor(publication._id, member._id, fullname);
				successCode = response.success;
				message = response.message;
			} else {
				// External author
				const response = await removeCorrespondingAuthor(publication._id, null, fullname);
				successCode = response.success;
				message = response.message;
			}

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
		setCorrespondingAuthorToRemove("");
		setRemoveCorrespondingAuthorPopup(false);
	}

	const declineRemoveSupervisor = () => {
		setSupervisorToDelete("")
		setDeleteSupervisorPopupOpen(false)
		setDeleteAssistantSupervisorIconClicked(false)
	}

	const declineRemoveCorrespondingAuthor = () => {
		setRemoveCorrespondingAuthorPopup(false)
	}

	const moveAuthorHandler = async (fullname, place, newplace) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await moveAuthor(publication._id, fullname, place, newplace);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const lockReviewingProcessHandler = async (reviewId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await lockReviewingProcess(publication._id, reviewId);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const removeReviewerSubmitHandler = async (reviewId, reviewerId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteReviewerFromReview(publication._id, reviewId, reviewerId);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const stateChangeHandle = async (step) => {
		if (publication.finalFilesRevisions.length === 0 && step === "Internal Review") {
			error("You must upload a document version before changing the state to Internal Review");
			return;
		}

		setIsLoading(true);
		try {
			const { success: successCode, message } = await statesChangePublication(publication._id, step);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const typeChangeHandle = async (event) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await typeChangePublication(publication._id, event.target.value);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const commentMentionsChangeHandle = (event) => {
		console.log(event.target);
		if (event.target.value === "all") {
			const newCommentedMembers = [];
			for (const member of allPublicationAuthorsAndMembers) {
				newCommentedMembers.push({
					_id: member._id,
					name: member.fullname,
				});
			}

			setCommentMentions(newCommentedMembers);
		} else {
			for (const member of commentMentions) {
				if (member._id === event.target.value) {
					error("This user is already mentioned");
					return;
				}
			}

			// Find the name and add in state
			for (const member of allPublicationAuthorsAndMembers) {
				if (member._id === event.target.value) {
					setCommentMentions((current) => [...current, {
						_id: member._id,
						name: member.fullname,
					}]);
					break;
				}
			}
		}
	};

	const removeCommentMentionSubmitHandler = (event) => {
		// Find the name and remove from in state
		for (const member of teamMembers) {
			if (member.value === event) {
				setCommentMentions((current) => current.filter((mention) => mention._id !== member.value));
				break;
			}
		}
	};

	const handleClickExternalReviewLock = async (reviewId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addReviewingProcess(publication._id, "internal");

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			const { success: successCodeState, messageState } = await statesChangePublication(publication._id, "Internal Review");

			if (successCodeState) {
				success(messageState);
			} else {
				error(messageState);
			}

			const { success: successCode2, message2 } = await lockReviewingProcess(publication._id, reviewId);

			if (successCode2) {
				success(message2);
			} else {
				error(message2);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changeAbstractSubmitHandler = async (event) => {
		setIsLoading(true);
		console.log(event);
		try {
			const { success: successCode, message } = await abstractChangePublication(publication._id, event.abstract);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setChangeAbstractPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changeKeywordsSubmitHandler = async (event) => {
		setIsLoading(true);
		console.log(event);
		try {
			const { success: successCode, message } = await keywordsChangePublication(publication._id, event.keywords);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setChangeKeywordsPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changeLiveDocumentSubmitHandler = async (event) => {
		setIsLoading(true);
		console.log(event);
		try {
			const { success: successCode, message } = await liveDocumentChangePublication(publication._id, event.live_document);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setChangeLiveDocumentPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const setDocumentForReviewHandle = async (savedName, _folder, version) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await setDocumentForReview(
				publication._id, review._id, savedName, _folder, version,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changeDeadlineHandler = async () => {
		if (deadline === null) return;

		setIsLoading(true);
		console.log("Deadline!!", deadline);
		try {
			const { success: successCode, message } = await deadlineChangePublication(publication._id, deadline);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setDeadline(null);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changeReviewDeadlineHandler = async (reviewId) => {
		if (deadline === null) return;

		setIsLoading(true);
		try {
			const { success: successCode, message } = await deadlineChangeReview(publication._id, reviewId, deadline);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setDeadline(null);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changePublishedSubmitHandler = async (event) => {
		setIsLoading(true);
		console.log(event);
		try {
			const { success: successCode, message } = await publishedInfoChangePublication(
				publication._id,
				event.citation,
				event.url,
				event.publication_date,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setChangePublishedPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const changeTargetSubmitHandler = async (event) => {
		setIsLoading(true);
		console.log(event);
		try {
			const { success: successCode, message } = await targetChangePublication(
				publication._id, event.title, event.url, event.template,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setChangeTargetPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addReviewHandler = async (values) => {
		if (publication.finalFilesRevisions.length === 0) {
			error("You must upload a document version before starting a reviewing process");
			return;
		}

		setIsLoading(true);
		try {
			const { success: successCode, message } = await addReviewingProcess(publication._id, values);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			const revType = values === "internal" ? "Internal Review" : "Revisions";
			const { success: successCodeState, messageState } = await statesChangePublication(publication._id, revType);

			if (successCodeState) {
				success(messageState);
			} else {
				error(messageState);
			}

			// setAddNonRegisteredMemberPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const handleCorrespondingCheckboxChange = async (event, authorName) => {
		if (!event.target.checked) {
			setCorrespondingAuthorToRemove(authorName);
			setRemoveCorrespondingAuthorPopup(true)
		} else {
			if (correspondingAuthors.length >= 1) {
				setCorrespondingAuthorAlreadyExistsPopupOpen(true)
			} else {
				try {
					let successCode = null;
					let message = null;
					// Check case
					if (publication.finalTeamAuthorNames.some((item) => item.fullname === authorName)) {
						// Team member
						const member = publication.finalTeamAuthorNames.find((author) => author.fullname.includes(authorName));
						console.log(member);
						const response = await addCorrespondingAuthor(publication._id, member._id, authorName);
						successCode = response.success;
						message = response.message;
					} else if (publication.finalNonTeamAuthorNames.some((item) => item.fullname === authorName)) {
						// Non team member, but registered
						const member = publication.finalNonTeamAuthorNames.find((author) => author.fullname.includes(authorName));
						console.log(member);
						const response = await addCorrespondingAuthor(publication._id, member._id, authorName);
						successCode = response.success;
						message = response.message;
					} else {
						// External author
						const response = await addCorrespondingAuthor(publication._id, null, authorName);
						successCode = response.success;
						message = response.message;
					}

					if (successCode) {
						success(message);
					} else {
						error(message);
					}

				} catch { /* empty */ }

				await fetchData();
			}
		}
	}

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={deleteAuthorPopupOpen}
				title="Delete Author?"
				content={`Are you sure you want to delete the author "${authorToDelete}"?`}
				onDecline={declineRemoveAuthor}
				onAccept={removeAuthorSubmitHandler}
			/>
			<AreYouSurePopup
				open={deleteSupervisorPopupOpen}
				title={deleteAssistantSupervisorIconClicked ? "Delete Assistant supervisor?" : "Delete Supervisor"}
				content={deleteAssistantSupervisorIconClicked ? `Are you sure you want to delete the Assistant supervisor "${supervisorToDelete}"?` : `Are you sure you want to delete the supervisor "${supervisorToDelete}"?`}
				onDecline={declineRemoveSupervisor}
				onAccept={removeSupervisorSubmitHandler}
			/>
			<AreYouSurePopup
				open={removeCorrespondingAuthorPopup}
				title={"Remove Corresponding author"}
				content={"Are you sure you want to remove the Corresponding author?"}
				onDecline={declineRemoveCorrespondingAuthor}
				onAccept={removeCorrespondingAuthorHandler}
			/>
			<AddNonTeamMemberPopup
				open={addNonTeamMemberPopupOpen}
				setOpen={setAddNonTeamMemberPopupOpen}
				publication={publication}
				setIsLoading={setIsLoading}
				error={error}
				success={success}
				fetchData={fetchData}
			/>
			<EditPublicationPopup
				publication={publication}
				open={editPublicationPopupOpen}
				setOpen={setEditPublicationPopupOpen}
				fetchData={fetchData}
				setIsLoading={setIsLoading}
				classes={classes}
			/>
			<Menu
				id="add-user-menu"
				anchorEl={anchorElAddAuthor}
				open={openAddAuthor}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				onClose={handleCloseAddAuthor}
			>
				{
					(publication?.teamUsersNames?.find((user) => user.value === myid)) && (
						<MenuItem
							onClick={() => {
								setAnchorElAddAuthor(null);
								setAddTeamMemberPopupOpen(true);
							}}
						>
							{"Add team member"}
						</MenuItem>
					)
				}
				<MenuItem
					onClick={() => {
						setAnchorElAddAuthor(null);
						setAddNonTeamMemberPopupOpen(true);
					}}
				>
					{"Add non-team member"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAnchorElAddAuthor(null);
						setAddNonRegisteredMemberPopupOpen(true);
					}}
				>
					{"Add non-registered author"}
				</MenuItem>
			</Menu>
			<Menu
				dense="true"
				id="add-review-menu"
				anchorEl={anchorElAddReview}
				open={openAddReview}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				onClose={handleCloseAddReview}
			>
				<MenuItem
					onClick={() => {
						setAnchorElAddReview(null);
						addReviewHandler("internal");
					}}
				>
					{"Internal review"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAnchorElAddReview(null);
						addReviewHandler("external");
					}}
				>
					{"External review"}
				</MenuItem>
			</Menu>
			<Menu
				dense="true"
				id="add-reviewer-menu"
				anchorEl={anchorElAddReviewer}
				open={openAddReviewer}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				onClose={handleCloseAddReviewer}
			>
				<MenuItem
					onClick={() => {
						setAnchorElAddReviewer(null);
						setAddTeamMemberReviewPopupOpen(true);
					}}
				>
					{"Team member"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setAnchorElAddReviewer(null);
						setAddNonTeamMemberReviewPopupOpen(true);
					}}
				>
					{"Non team member"}
				</MenuItem>
			</Menu>
			{
				publication.finalFilesRevisions && (
					<Menu
						dense="true"
						id="add-reviewer-menu"
						anchorEl={anchorElAddDocumentForReview}
						open={openAddDocumentForReview}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
						onClose={handleCloseAddDocumentForReview}
					>
						{
							publication.finalFilesRevisions.map((e, ind) => (
								<MenuItem
									key={ind}
									onClick={() => {
										setAnchorElAddDocumentForReview(null);
										setDocumentForReviewHandle(e.savedName, e.folder, e.version);
									}}
								>
									{`V${e.version} - ${e.originalName.slice(0, 20)} ${e.originalName.length > 20 ? "..." : ""}`}
								</MenuItem>
							))
						}
					</Menu>
				)
			}

			<Popup
				width="800px"
				open={addCommentPopupOpen}
				title="Add comment"
				onClose={() => {
					setAddCommentPopupOpen(false);
					setCommentMentions([]);
				}}
			>
				<Grid
					container
					flexDirection="column"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Grid
						container
						flexDirection="row"
						flex={1}
						sx={{
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							mb: 1,
						}}
					>
						<Typography color="third.main">{"Mention some members: "}</Typography>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
							<Select
								className={classes.select}
								label="Mentions"
								value=""
								onChange={commentMentionsChangeHandle}
							>
								<MenuItem key="all-0" value="all">{"All"}</MenuItem>
								{
									allPublicationAuthorsAndMembers.map((author, ind) => (
										<MenuItem key={ind} value={author._id}>{author.fullname}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Grid>
					<Grid
						container
						flexDirection="row"
						flex={1}
						sx={{
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							mb: 2,
						}}
					>
						{commentMentions.map((e) => (
							<Chip
								key={e._id}
								label={e.name}
								className={classes.publicationTypeChip}
								variant="outlined"
								sx={{
									mr: 0.5,
								}}
								onDelete={() => {
									removeCommentMentionSubmitHandler(e._id);
								}}
							/>
						))}
					</Grid>

					<Form
						content={addCommentFormContent}
						// validationSchema="addTeamMemberToPublicationSchema"
						onSubmit={addCommentSubmitHandler}
					/>
				</Grid>
			</Popup>
			<Popup
				width="800px"
				open={addTeamMemberPopupOpen}
				title="Add team member as author"
				onClose={() => {
					setAddTeamMemberPopupOpen(false);
					setNewTeamMemberAsAuthor("");
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Choose team member: "}</Typography>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

						<Select
							className={classes.select}
							value={newTeamMemberAsAuthor}
							onChange={(event) => {
								setNewTeamMemberAsAuthor(event.target.value);
							}}
						>
							{
								teamMembers.map((e, ind) => (
									<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Form
					content={addTeamMemberFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={addTeamMemberSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addSupervisorPopupOpen}
				title={addAssistantSupervisorChipClicked ? "Add team member as Assistant supervisor" : "Add team member as Supervisor"}
				onClose={() => {
					setAddSupervisorPopupOpen(false);
					setNewTeamMemberAsSupervisor("");
					setAddAssistantSupervisorChipClicked(false)
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Choose team member: "}</Typography>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

						<Select
							className={classes.select}
							value={newTeamMemberAsSupervisor}
							onChange={(event) => {
								setNewTeamMemberAsSupervisor(event.target.value);
							}}
						>
							{
								teamMembers.map((e, ind) => (
									<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Form
					content={addAssistantSupervisorChipClicked ? addAssistantSupervisorFormContent : addSupervisorFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={() => {
						if (addAssistantSupervisorChipClicked) {
							addSupervisorSubmitHandler(false);
						} else {
							addSupervisorSubmitHandler(true);
						}
					}}
				/>
			</Popup>
			<Popup
				width="800px"
				open={supervisorAlreadyExistsPopupOpen}
				title="Add team member as Supervisor"
				onClose={() => {
					setSupervisorAlreadyExistsPopupOpen(false);
					setAddAssistantSupervisorChipClicked(false);
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Supervisor already exists"}</Typography>
				</Grid>
			</Popup>
			<Popup
				width="800px"
				open={correspondingAuthorAlreadyExistsPopupOpen}
				title="Corresponding author"
				onClose={() => {
					setCorrespondingAuthorAlreadyExistsPopupOpen(false);
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Corresponding author already exists"}</Typography>
				</Grid>
			</Popup>
			<Popup
				width="800px"
				open={addNonRegisteredMemberPopupOpen}
				title="Add not registered user as author"
				onClose={() => setAddNonRegisteredMemberPopupOpen(false)}
			>
				<Form
					content={addNonRegisteredMemberFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={addExternalMemberSubmitHandler}
				/>
			</Popup>

			<Popup
				width="800px"
				open={addTeamMemberReviewPopupOpen}
				title="Add team member as reviewer"
				onClose={() => {
					setAddTeamMemberReviewPopupOpen(false);
					setNewTeamMemberAsReviewer("");
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Choose team member: "}</Typography>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

						<Select
							className={classes.select}
							value={newTeamMemberAsReviewer}
							onChange={(event) => {
								setNewTeamMemberAsReviewer(event.target.value);
							}}
						>
							{
								teamMembers.map((e, ind) => (
									<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Form
					content={addTeamMemberReviewFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={addTeamMemberReviewSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addNonTeamMemberReviewPopupOpen}
				title="Add non-team member as reviewer"
				onClose={() => setAddNonTeamMemberReviewPopupOpen(false)}
			>
				<Form
					content={addNonTeamMemberReviewFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={addNonTeamMemberReviewSubmitHandler}
				/>
			</Popup>

			<Popup
				width="800px"
				open={changeAbstractPopupOpen}
				title="Abstract edit"
				onClose={() => setChangeAbstractPopupOpen(false)}
			>
				<Form
					content={changeAbstractFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={changeAbstractSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={changeKeywordsPopupOpen}
				title="Keywords edit (comma separated)"
				onClose={() => setChangeKeywordsPopupOpen(false)}
			>
				<Form
					content={changeKeywordsFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={changeKeywordsSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={changeLiveDocumentPopupOpen}
				title="Live document edit"
				onClose={() => setChangeLiveDocumentPopupOpen(false)}
			>
				<Form
					content={changeLiveDocumentFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={changeLiveDocumentSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={changePublishedPopupOpen}
				title="Published information edit"
				onClose={() => setChangePublishedPopupOpen(false)}
			>
				<Form
					content={changePublishedFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={changePublishedSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={changeTargetPopupOpen}
				title="Target edit"
				onClose={() => setChangeTargetPopupOpen(false)}
			>
				<Form
					content={changeTargetFormContent}
					// validationSchema="addTeamMemberToPublicationSchema"
					onSubmit={changeTargetSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addDocumentVersionPopupOpen}
				title="Add new document version"
				onClose={() => {
					setAddDocumentVersionPopupOpen(false);
					setSaveName(null);
					setOriginalName(null);
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<FileUpload
						id="file-upload"
						folder={folder}
						component="button"
						color="third"
						onSuccess={setUploadSuccessInfo}
						onUploadStart={() => {
							setIsLoading(true);
						}}
						onUploadStop={() => {
							setIsLoading(false);
						}}
					/>
					<Grid item sx={{ ml: 2 }}>
						{!saveName && <Typography color="grey.main">{"No uploaded file!"}</Typography>}
						{saveName
							&& (
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${folder}/${saveName}?token=${jwt.getToken()}`} target="_blank" color="green.main">
									{originalName || "Download File"}
								</Link>
							)}
					</Grid>
				</Grid>
				<Form
					content={newVersionUploadFormContent}
					onSubmit={uploadSuccess}
				/>
			</Popup>
			<Popup
				width="600px"
				title="Version comment"
				open={versionCommentPopupOpen}
				onClose={() => setVersionCommentPopupOpen(false)}
			>
				<Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography color="#fff">
						{comment}
					</Typography>
				</Grid>
			</Popup>
			<Popup
				width="800px"
				open={addReviewPopupOpen}
				title="Add new review"
				onClose={() => {
					setAddReviewPopupOpen(false);
					setSaveName(null);
					setOriginalName(null);
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<FileUpload
						id="file-upload-review"
						folder={folder}
						component="button"
						color="third"
						onSuccess={setUploadSuccessInfo}
						onUploadStart={() => {
							setIsLoading(true);
						}}
						onUploadStop={() => {
							setIsLoading(false);
						}}
					/>
					<Grid item sx={{ ml: 2 }}>
						{!saveName && <Typography color="grey.dark">{"No uploaded file!"}</Typography>}
						{saveName
							&& (
								<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${folder}/${saveName}?token=${jwt.getToken()}`} target="_blank" color="third.main">
									{originalName || "Download File"}
								</Link>
							)}
					</Grid>
				</Grid>
				<Form
					content={newReviewUploadFormContent}
					onSubmit={reviewUploadSuccess}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addPreprintPopupOpen}
				title="Add new preprint"
				onClose={() => setAddPreprintPopupOpen(false)}
			>
				<FileUpload
					id="file-upload-review"
					folder={folder}
					component="button"
					color="third"
					onSuccess={preprintUploadSuccess}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addCameraReadyPopupOpen}
				title="Add new camera-ready file"
				onClose={() => setAddCameraReadyPopupOpen(false)}
			>
				<FileUpload
					id="file-upload-review"
					folder={folder}
					component="button"
					color="third"
					onSuccess={cameraReadyUploadSuccess}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addRevisedDocumentPopupOpen}
				title="Add new revised document"
				onClose={() => setAddRevisedDocumentPopupOpen(false)}
			>
				<FileUpload
					id="revision-upload"
					folder={folder}
					component="button"
					color="third"
					onSuccess={revisedDocumentUploadSuccess}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addReviewersCommentsPopupOpen}
				title="Add reviewers' comments document"
				onClose={() => setAddReviewersCommentsPopupOpen(false)}
			>
				<FileUpload
					id="revision-upload"
					folder={folder}
					component="button"
					color="third"
					onSuccess={reviewersCommentsUploadSuccess}
				/>
			</Popup>
			<Popup
				width="800px"
				open={addResponseToReviewersPopupOpen}
				title="Add response to reviewers document"
				onClose={() => setAddResponseToReviewersPopupOpen(false)}
			>
				<FileUpload
					id="revision-upload"
					folder={folder}
					component="button"
					color="third"
					onSuccess={responseToReviewersUploadSuccess}
				/>
			</Popup>

			<Dialog
				open={!!abstractShowDialog}
				title="Abstract"
				confirmButton="Ok"
				text={publication.abstract}
				cancelButton={null}
				onConfirm={() => {
					setAbstractShowDialog(null);
				}}
			/>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			// marginX={2}
			>
				<Grid
					container
					item
					width="100%"
					mt={2}
					// p={1}
					pr={2}
					display="flex"
					minHeight="60px"
					borderRadius="20px"
					alignItems="center"
					sx={{
						backgroundColor: "secondary.main",
						borderRadius: "20px",
					}}
				>
					<Grid
						item
						xs={11}
						display="flex"
						flexDirection="row"
						alignItems="center"
						minHeight="60px"
					>
						<Avatar
							className={classes.editAvatar}
							onClick={() => { setEditPublicationPopupOpen(true); }}
						>
							<EditIcon />
						</Avatar>
						<Typography variant="h6" color="#ffffff">
							{publication.title}
						</Typography>
					</Grid>
					<Grid
						item
						xs={1}
						display="flex"
						flexDirection="row"
						alignItems="center"
						minHeight="60px"
						justifyContent="flex-end"

					>
						{publication.state && (
							<Grid
								container
								display="flex"
								flexDirection="column"
								alignItems="flex-end"
							>
								<Chip
									label={publication.publicationType}
									className={classes.publicationTypeChip}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid
					container
					mt={2}
					width="100%"
					flexDirection="row"
					alignContent="center"
					justifyContent="center"
				>
					{publication.state_history && (
						<Stepper alternativeLabel activeStep={publication.state_history.length - 1}>
							{
								publication.state_history.map((step, index) => (
									step.timestamp && (
										<Step
											key={step._id}
											hidden={index < publication.state_history.length - STATES_TO_KEEP}
											sx={{
												"& .MuiStepLabel-root .Mui-completed": {
													color: "third.main", // circle color (COMPLETED)
												},
												"& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
												{
													color: "third.main", // Just text label (COMPLETED)
													fontSize: "0.7rem",
												},
												"& .MuiStepLabel-root .Mui-active": {
													color: "green.main", // circle color (ACTIVE)
												},
												"& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
												{
													color: "common.white", // Just text label (ACTIVE)
													fontSize: "0.8rem",
												},
												"& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
													fill: "white", // circle's number (ACTIVE)
												},
											}}
										>
											<StepLabel>
												<Typography fontSize="0.6rem">
													{step.timestamp.slice(0, step.timestamp.search("T"))}
												</Typography>
												{step.state}
											</StepLabel>
										</Step>
									)
								))
							}
						</Stepper>
					)}
				</Grid>
				<Grid
					container
					mt={2}
					width="100%"
					flexDirection="row"
					alignContent="center"
					justifyContent="center"
				>
					{publication.state && (
						<Typography color="third.main" fontSize="0.9rem" mt={0.6} mr={0.5}>
							{"Change state: "}
						</Typography>
					)}
					{
						publication.state && publicationStates.slice(1).map((step) => (
							<Grid
								key={step.value}
								item
								sx={{
									mx: 0.5,
								}}
							>
								<Chip
									label={step.value}
									className={step.value === publication.state.state ? classes.activeStateChip : classes.inactiveStateChip}
									variant={step.value === publication.state.state ? "filled" : "outlined"}
									sx={{
										mb: 0.5,
									}}
									onClick={() => {
										stateChangeHandle(step.value);
									}}
								/>
							</Grid>
						))
					}
				</Grid>
				<Grid
					container
					mt={2}
					width="100%"
					flexDirection="row"
					alignContent="center"
					justifyContent="space-between"
				>
					{publication.team_authors && (
						<Grid
							container
							mt={2}
							pb={1}
							width={publication.metaType === "master_thesis" ? "70%" : "100%"}
						>
							<Grid
								container
								item
								width="100%"
								p={1}
								pl={2}
								mb={1}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								borderRadius="20px"
								alignItems="center"
								backgroundColor="secondary.main"
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Authors"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
								>
									<Chip
										label="Add author"
										className={classes.actionChip}
										sx={{
											mr: 0.5,
										}}
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										onClick={handleClickAddAuthor}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								mt={1}
								pl={1}
								mb={1}
								width="100%"
							>
								{publication.orderedAuthorsNames.map((e, ind) => (
									<Grid
										key={e}
										container
										alignItems="center"
										sx={{
											mr: 0.5,
											mb: 0.5,
											pl: 1,
											pr: 1,
											border: 1,
											borderColor: publication.external_authors.includes(e) ? "white.main" : (publication.finalNonTeamAuthorNames.some((el) => el.fullname === e) ? "third.main" : "third.main"),
											borderRadius: "20px",
											backgroundColor: publication.finalTeamAuthorNames.some((el) => el.fullname === e) ? "third.main" : "primary.main",
											flexDirection: "row",
											width: "fit-content",
										}}
									>
										<Typography color={publication.external_authors.includes(e) ? "white.main" : (publication.finalNonTeamAuthorNames.some((el) => el.fullname === e) ? "third.main" : "primary.main")} pl={1} fontSize="0.85rem">
											{e}
										</Typography>
										<IconButton
											edge="end"
											onClick={() => {
												console.log("full name", e);
												setAuthorToDelete(e);
												setDeleteAuthorPopupOpen(true);
											}}
										>
											<DeleteIcon color={publication.external_authors.includes(e) ? "white" : (publication.finalNonTeamAuthorNames.some((el) => el.fullname === e) ? "third" : "primary")} />
										</IconButton>
										<Grid
											label={e.name}
											className={classes.selectBox}
										>
											<Select
												value={ind + 1}
												className={classes.select}
												sx={{
													height: "13px",
													fontSize: "0.9rem",
												}}
												onChange={(event) => {
													moveAuthorHandler(e, ind, event.target.value - 1);
												}}
											>
												{
													[...Array.from({ length: publication.orderedAuthorsNames.length }).keys()].map((ind2) => (
														<MenuItem
															key={ind2 + 1}
															value={ind2 + 1}
														>
															{ind2 + 1}
														</MenuItem>
													))
												}
											</Select>
										</Grid>
										{publication.metaType === "publication" &&
											<>
												<Tooltip title={!correspondingAuthors.includes(e) ? "Make corresponding author" : "Remove corresponding author"}>
													<Checkbox
														checked={correspondingAuthors.includes(e)}
														icon={<StarIcon className={classes.correspondingAuthorUnchecked} />}
														checkedIcon={<StarIcon className={classes.correspondingAuthorChecked} />}
														onChange={(event) => handleCorrespondingCheckboxChange(event, e)}
													/>
												</Tooltip>
											</>
										}
									</Grid>
								))}
								{
									publication.orderedAuthorsNames.length === 0 && (
										<Typography color="gray" pl={2}>
											{"No authors"}
										</Typography>
									)
								}
							</Grid>
						</Grid>
					)}
					{publication.metaType === "master_thesis" && publication.supervisors && (
						<Grid
							container
							mt={2}
							pb={1}
							width="29.5%"
						>
							<Grid
								container
								item
								width="100%"
								p={1}
								pl={2}
								mb={1}
								display="flex"
								minHeight="50px"
								maxHeight="50px"
								borderRadius="20px"
								alignItems="center"
								backgroundColor="secondary.main"
							>
								<Grid
									item
									xs={9}
								>
									<Typography variant="h6" color="#ffffff">
										{"Supervisors"}
									</Typography>
								</Grid>
								<Grid
									item
									xs={3}
									display="flex"
									flexDirection="row"
									alignItems="center"
									justifyContent="flex-end"
								>
									<Chip
										label="Add Supervisor"
										className={classes.actionChip}
										sx={{
											mr: 0.5,
										}}
										onClick={() => { publication.orderedSupervisorsNames.length !== 0 ? (publication.orderedSupervisorsNames[0].isMain ? setSupervisorAlreadyExistsPopupOpen(true) : setAddSupervisorPopupOpen(true)) : setAddSupervisorPopupOpen(true) }}
									/>
									<Chip
										label="Add Assistant supervisor"
										className={classes.actionChip}
										sx={{
											mr: 0.5,
										}}
										onClick={() => { setAddSupervisorPopupOpen(true); setAddAssistantSupervisorChipClicked(true); }}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								mt={1}
								pl={1}
								mb={1}
								width="100%"
							>
								{publication.orderedSupervisorsNames.map((e, ind) => (
									<Tooltip title={e.isMain ? "Supervisor" : "Assistant Supervisor"} arrow key={ind}>
										<Grid
											key={e}
											container
											alignItems="center"
											sx={{
												mr: 0.5,
												mb: 0.5,
												pl: 1,
												pr: 1,
												border: e.isMain ? 3.5 : 1,
												borderColor: e.isMain ? '#F8B400' : "third.main",
												borderRadius: "20px",
												backgroundColor: "third.main",
												flexDirection: "row",
												width: "fit-content",
											}}
										>
											<Typography pl={1} fontSize="0.85rem">
												{e.name}
											</Typography>
											<IconButton
												edge="end"
												onClick={() => {
													console.log("full name", e.name);
													setSupervisorToDelete(e.name);
													setDeleteSupervisorPopupOpen(true);
													{ !e.isMain ? setDeleteAssistantSupervisorIconClicked(true) : setDeleteAssistantSupervisorIconClicked(false) }
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Grid>
									</Tooltip>
								))}
								{
									publication.orderedSupervisorsNames.length === 0 && (
										<Typography color="gray" pl={2}>
											{"No supervisors specified"}
										</Typography>
									)
								}
							</Grid>
						</Grid>
					)}
				</Grid>
				{publication.team_authors && (
					<Grid
						container
						mt={2}
						mb={2}
						width="100%"
						display="flex"
						justifyContent="space-between"
						alignContent="flex-start"
					>
						{/* Events Sidebar */}
						{
							showEvents && (
								<Grid
									container
									item
									alignContent="flex-start"
									xs={2}
									backgroundColor="secondary.main"
									borderRadius="20px"
								>
									<Events
										publication={publication}
										initialEventsSize={initialEventsSize}
									/>
								</Grid>
							)
						}
						{/* Main components */}
						<Grid
							container
							item
							xs={showEvents ? 9.95 : 0}
							width="100%"
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignContent="flex-start"
						>
							{/* Preparation & Publication components */}
							<Metadata
								setIsLoading={setIsLoading}
								isLoading={isLoading}
								classes={classes}
								publication={publication}
								setAbstractShowDialog={setAbstractShowDialog}
								setChangeAbstractPopupOpen={setChangeAbstractPopupOpen}
								setChangeKeywordsPopupOpen={setChangeKeywordsPopupOpen}
								setChangePublishedPopupOpen={setChangePublishedPopupOpen}
								success={success}
								error={error}
								setAddPreprintPopupOpen={setAddPreprintPopupOpen}
								clearPreprint={clearPreprint}
								setAddCameraReadyPopupOpen={setAddCameraReadyPopupOpen}
								clearCameraReady={clearCameraReady}
								typeChangeHandle={typeChangeHandle}
								setChangeTargetPopupOpen={setChangeTargetPopupOpen}
								changeDeadlineHandler={changeDeadlineHandler}
								deadline={deadline}
								setDeadline={setDeadline}
								fetchData={fetchData}
							/>
							<Composition
								setChangeLiveDocumentPopupOpen={setChangeLiveDocumentPopupOpen}
								publication={publication}
								classes={classes}
								setAddDocumentVersionPopupOpen={setAddDocumentVersionPopupOpen}
								deleteFileHandler={deleteFileHandler}
								setComment={setComment}
								setVersionCommentPopupOpen={setVersionCommentPopupOpen}
							/>
							<Assets
								publication={publication}
								classes={classes}
								setIsLoading={setIsLoading}
								success={success}
								error={error}
								fetchData={fetchData}
							/>
							<Reviews
								myid={myid}
								publication={publication}
								classes={classes}
								handleClickAddReview={handleClickAddReview}
								lockReviewingProcessHandler={lockReviewingProcessHandler}
								deadline={deadline}
								setDeadline={setDeadline}
								changeReviewDeadlineHandler={changeReviewDeadlineHandler}
								removeReviewerSubmitHandler={removeReviewerSubmitHandler}
								setReview={setReview}
								handleClickAddDocumentForReview={handleClickAddDocumentForReview}
								setAddReviewersCommentsPopupOpen={setAddReviewersCommentsPopupOpen}
								handleClickAddReviewer={handleClickAddReviewer}
								setAddReviewPopupOpen={setAddReviewPopupOpen}
								setAddRevisedDocumentPopupOpen={setAddRevisedDocumentPopupOpen}
								setVersionCommentPopupOpen={setVersionCommentPopupOpen}
								setComment={setComment}
								setAddResponseToReviewersPopupOpen={setAddResponseToReviewersPopupOpen}
								handleClickExternalReviewLock={handleClickExternalReviewLock}
								success={success}
								error={error}
								setIsLoading={setIsLoading}
								fetchData={fetchData}
							/>
							<Assignments
								publication={publication}
								setPublication={setPublication}
								allPublicationAuthorsAndMembers={allPublicationAuthorsAndMembers}
								classes={classes}
								success={success}
								error={error}
								setIsLoading={setIsLoading}
								fetchData={fetchData}
							/>
							<Comments
								publication={publication}
								setAddCommentPopupOpen={setAddCommentPopupOpen}
								classes={classes}
								allPublicationAuthorsAndMembers={allPublicationAuthorsAndMembers}
								setIsLoading={setIsLoading}
								error={error}
								success={success}
								fetchData={fetchData}
							/>
							<MdEditor
								myid={myid}
								publication={publication}
								classes={classes}
								setIsLoading={setIsLoading}
								success={success}
								error={error}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default memo(PublicationInfo);
