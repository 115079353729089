import { Grid, Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar } from "../utils/index.js";
import { getTeamMembers, addUserToTeam, deleteUserFromTeam, saveMemberInformation, addNotRegisteredUserToTeam, setMembersOrder, getTeamMinimalInfo } from "../api/index.js";
import { Member } from "../components/Member.js";
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import PublicIcon from '@mui/icons-material/Public';

const useStyles = makeStyles((theme) => ({
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		borderRadius: theme.spacing(2),
	},
	publicPageChip: {
		color: theme.palette.third.main,
		borderColor: theme.palette.third.main,
		border: "1px solid",
		"& .MuiChip-label": {
			color: theme.palette.third.main,
		},
		fontSize: "0.9rem",
		"&:hover": {
			borderColor: theme.palette.third.dark,
			color: theme.palette.third.dark,
			"& .MuiChip-label": {
				color: theme.palette.third.dark,
			},
		},
	},
	registeredChip: {
		backgroundColor: theme.palette.light.main,
		"& .MuiChip-label": {
			color: "white",
		},
		fontSize: "1.2rem",
		"&:hover": {
			backgroundColor: theme.palette.light.dark,
		},
	},
	nonRegisteredChip: {
		backgroundColor: theme.palette.error.main,
		"& .MuiChip-label": {
			color: "white",
		},
		fontSize: "1.2rem",
		"&:hover": {
			backgroundColor: theme.palette.error.dark,
		},
	},
}));

const TeamMembers = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [members, setMembers] = useState([]);
	const [team, setTeam] = useState(null);
	const [ordered, setOrdered] = useState([]);
	const [addUserPopupOpen, setAddUserPopupOpen] = useState(false);
	const [addNotRegisteredUserPopupOpen, setAddNotRegisteredUserPopupOpen] = useState(false);
	const [deleteMember, setDeleteMember] = useState(null);
	const [deleteMemberPopupOpen, setDeleteMemberPopupOpen] = useState(false);
	const [editMember, setEditMember] = useState(null);
	const [editMemberPopupOpen, setEditMemberPopupOpen] = useState(false);
	const navigate = useNavigate();
	const classes = useStyles();

	const { teamid } = useParams();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, team: tm, message } = await getTeamMinimalInfo(teamid);
				if (scs) {
					setTeam(tm);
				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			try {
				const { success: scs, members: mems, ordered: ord } = await getTeamMembers(teamid);
				console.log("Members", ord);
				if (scs) {
					setMembers(mems);
					setOrdered(ord);
				} else {
					error();
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, teamid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const addUserSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addUserToTeam(teamid, values.email);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddUserPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const addNonRegisteredUserSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addNotRegisteredUserToTeam(teamid, values);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddNotRegisteredUserPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const editUserSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await saveMemberInformation(editMember._id, values);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setEditMemberPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const moveMemberHandler = async (status, place, newplace) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await setMembersOrder(teamid, status, place, newplace);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setEditMemberPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const onDelete = async () => {
		setIsLoading(true);
		console.log(deleteMember);
		const { success: successCode, message } = await deleteUserFromTeam(teamid, deleteMember?._id);
		if (successCode) {
			success("Member deleted!");
		} else {
			console.log(message);
			error();
		}

		await fetchData();
		setDeleteMemberPopupOpen(false);
		setIsLoading(false);
		setDeleteMember({ id: null, fullname: null });
	};

	const onDecline = () => {
		setDeleteMemberPopupOpen(false);
		setDeleteMember({ id: null, fullname: null });
	};

	const addUserFormContent = [
		{
			customType: "input",
			id: "email",
			type: "text",
			placeholder: "e-mail",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add user",
		},
	];

	const editUserFormContent = [
		{
			customType: "wideInput",
			id: "title",
			type: "text",
			placeholder: "Title (e.g. Dr.)",
			value: editMember?.title ?? "",
		},
		{
			customType: "wideInput",
			id: "role",
			type: "text",
			placeholder: "Role (e.g. Project manager, Developer etc.)",
			value: editMember?.role ?? "",
		},
		{
			customType: "radio",
			id: "status",
			label: "Status",
			defaultValue: editMember?.status ?? "Member",
			color: "third",
			row: true,
			labelPlacement: "end",
			items: [
				{ value: "Member", label: "Member" },
				{ value: "Past member", label: "Past member" },
				{ value: "Collaborator", label: "Collaborator" },
			],
		},
		{
			customType: "wideInput",
			id: "specialization",
			type: "text",
			placeholder: "Specialization (e.g. Software engineering, Machine learning etc.)",
			value: editMember?.specialization ?? "",
		},
		{
			customType: "wideInput",
			id: "pictureUrl",
			type: "text",
			placeholder: "Picture URL",
			value: editMember?.pictureUrl ?? "",
		},
		{
			customType: "wideInput",
			id: "description",
			type: "text",
			placeholder: "Golden paragraph",
			multiline: true,
			minRows: 8,
			value: editMember?.description ?? "",
		},
		{
			customType: "wideInput",
			id: "externalPage",
			type: "text",
			placeholder: "External page",
			value: editMember?.externalPage ?? "",
		},
		{
			customType: "switch",
			id: "hidden",
			label: "Hidden",
			defaultValue: editMember?.hidden ?? false,
			color: "third",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save member information",
		},
	];

	const addNotRegisteredMemberForm = [
		{
			customType: "wideInput",
			id: "fullname",
			type: "text",
			placeholder: "Fullname",
			value: "",
		},
		...editUserFormContent,
	];

	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={addUserPopupOpen}
				title="Add registered user to team"
				onClose={() => setAddUserPopupOpen(false)}
			>
				<Form content={addUserFormContent} validationSchema="addUserToTeamSchema" onSubmit={addUserSubmitHandler} />
			</Popup>
			<Popup
				width="800px"
				open={addNotRegisteredUserPopupOpen}
				title="Add non-registered user to team"
				onClose={() => setAddNotRegisteredUserPopupOpen(false)}
			>
				<Form
					content={addNotRegisteredMemberForm}
					onSubmit={addNonRegisteredUserSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={editMemberPopupOpen}
				title="Edit team-specific member information"
				onClose={() => setEditMemberPopupOpen(false)}
			>
				<Form
					content={editUserFormContent}
					onSubmit={editUserSubmitHandler}
				/>
			</Popup>
			<AreYouSurePopup
				open={deleteMemberPopupOpen}
				title="Delete member?"
				content={`Are you sure you want to delete the member "${deleteMember?.fullname}"?`}
				onDecline={onDecline}
				onAccept={onDelete}
			/>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="flex-start"
				justifyContent="center"
			>
				<Grid
					container
					item
					className={classes.titleBox}
				>
					<Grid
						item
						p={2}
						width="100%"
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						height="60px"
					>
						<Grid item display="flex" flexDirection="row">
							<Typography variant="h6" color="#ffffff">
								{`${team?.name} members`}
							</Typography>
							<Chip
								icon={<PublicIcon color="inherit" />}
								label="Public page"
								variant="outlined"
								className={classes.publicPageChip}
								sx={{
									ml: 2,
								}}
								onClick={() => {
									navigate(`/teams/${team._id}/members/public`);
								}}
							/>
						</Grid>
						<Grid item>
							<Typography variant="h7" color="#ffffff" ml={4}>
								{"Add registered member"}
							</Typography>
							<Chip
								label="+"
								className={classes.registeredChip}
								sx={{
									mr: 0.5,
									ml: 1,
								}}
								onClick={() => setAddUserPopupOpen(true)}
							/>
							<Typography variant="h7" color="#ffffff" ml={3}>
								{"Add non-registered member"}
							</Typography>
							<Chip
								label="+"
								className={classes.nonRegisteredChip}
								sx={{
									mr: 0.5,
									ml: 1,
								}}
								onClick={() => {
									setEditMember(null);
									setAddNotRegisteredUserPopupOpen(true);
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Typography variant="h5" color="#ffffff" my={2} ml={3}>
					{"Team members"}
				</Typography>
				<Grid
					container
					width="100%"
					justifyContent="space-evenly"
					px={2}
				>
					{ordered?.members?.map((member, index) => (
						<Member
							key={`member-${index}`}
							member={member}
							index={index}
							count={ordered?.members?.length}
							setDeleteMember={setDeleteMember}
							setDeleteMemberPopupOpen={setDeleteMemberPopupOpen}
							setEditMember={setEditMember}
							setEditMemberPopupOpen={setEditMemberPopupOpen}
							moveMemberHandler={moveMemberHandler}
						/>
					))}
				</Grid>
				{members.some((m) => m.status === "Collaborator") && (
					<Typography variant="h5" color="#ffffff" my={2} ml={3}>
						{"Collaborators"}
					</Typography>
				)}
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{ordered?.collaborators?.map((member, index) => (
						<Member
							key={`member-${index}`}
							member={member}
							index={index}
							count={ordered?.collaborators?.length}
							setDeleteMember={setDeleteMember}
							setDeleteMemberPopupOpen={setDeleteMemberPopupOpen}
							setEditMember={setEditMember}
							setEditMemberPopupOpen={setEditMemberPopupOpen}
							moveMemberHandler={moveMemberHandler}
						/>
					))}
				</Grid>
				{members.some((m) => m.status === "Past member") && (
					<Typography variant="h5" color="#ffffff" my={2} ml={3}>
						{"Past members"}
					</Typography>
				)}
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{ordered?.pastMembers?.map((member, index) => (
						<Member
							key={`member-${index}`}
							member={member}
							index={index}
							count={ordered?.pastMembers?.length}
							setDeleteMember={setDeleteMember}
							setDeleteMemberPopupOpen={setDeleteMemberPopupOpen}
							setEditMember={setEditMember}
							setEditMemberPopupOpen={setEditMemberPopupOpen}
							moveMemberHandler={moveMemberHandler}
						/>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export default memo(TeamMembers);
