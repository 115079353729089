/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Grid, Typography, Select, MenuItem, FormControl, Box } from "@mui/material";
import Popup from "../Popup.js";
import { editAssignment } from "../../api/index.js";
import Form from "../Form.js";
import { updateTaskInProject } from "../../utils/cycloptAPI.js";
import DatePicker from "../DatePicker.js";

const EditAssignmentPopup = ({
	open,
	setOpen,
	assignmentId,
	publication,
	allPublicationAuthorsAndMembers,
	setIsLoading,
	error,
	success,
	fetchData,
	classes,
}) => {
	const [assignmentMentionNew, setAssignmentMentionNew] = useState(null);
	const [assignmentEdit, setAssignmentEdit] = useState(null);
	const [newDeadlineDate, setNewDeadlineDate] = useState("");

	const editAssignmentFormContent = [
		{
			customType: "wideInput",
			id: "text",
			type: "text",
			value: assignmentEdit,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save assignment",
		},
	];
	useEffect(() => {
		const assignmentEditTemp = publication.finalAssignments.find((assignment) => assignment._id === assignmentId);
		if (assignmentEditTemp) {
			setAssignmentMentionNew(allPublicationAuthorsAndMembers.find((member) => member._id === assignmentEditTemp.assignee));
			setAssignmentEdit(assignmentEditTemp.text);
			setNewDeadlineDate(assignmentEditTemp.deadline);
		}
	}, [assignmentId, publication]);

	const assignmentMentionsChangeHandle = (event) => {
		// console.log(event.target.value);
		const newId = event.target.value;
		setAssignmentMentionNew(allPublicationAuthorsAndMembers.find((member) => member._id === newId));
	};

	const updateTaskIntegration = async (values) => {
		const updatedTask = {
			_id: assignmentId,
			title: values.text,
			body: values.text,
		};
		const cycloptTaskLink = publication.cycloptProject.links.find((link) => link.publisyncId === assignmentId);
		const res = await updateTaskInProject(publication.cycloptToken, publication.cycloptProject.projectId, cycloptTaskLink.integrationId, updatedTask);
		if (res.status !== 200) throw new Error("Error while Updating task", assignmentId);
	};

	const editAssignmentSubmitHandler = async (values) => {
		// console.log("Submit", values);
		setIsLoading(true);
		try {
			const { success: successCode, message } = await editAssignment(
				publication._id, assignmentId, assignmentMentionNew._id, values.text, newDeadlineDate
			);
			await updateTaskIntegration(values);
			// also update cyclopt task!
			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setOpen(false);
		} catch (error_) {
			console.log(error_);
		}

		await fetchData();
		setIsLoading(false);
		setAssignmentMentionNew(null);
		setNewDeadlineDate("");
		setAssignmentEdit(null);
		setOpen(false);
	};

	return (
		<Popup
			width="800px"
			open={open}
			title="Edit assignment"
			onClose={() => {
				setAssignmentMentionNew(null);
				setAssignmentEdit(null);
				setOpen(false);
			}}
		>
			<Grid
				container
				flexDirection="column"
				flex={1}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					mb: 1,
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Choose author or member: "}</Typography>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
						<Select
							className={classes.select}
							label="Mentions"
							value={assignmentMentionNew?._id ?? ""}
							onChange={assignmentMentionsChangeHandle}
						>
							{
								allPublicationAuthorsAndMembers.map((author, ind) => (
									<MenuItem key={ind} value={author._id} name={author.fullname}>{author.fullname}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main" sx={{ marginRight: "0.5rem" }}>{"Deadline: "}</Typography>

					<Box
						width="200px"
					>
						<DatePicker
							type="desktop"
							label=""
							value={newDeadlineDate}
							background="primary"
							color="third"
							onChange={(value) => {
								try {
									setNewDeadlineDate(`${value.$D}/${value.$M + 1}/${value.$y}`);
								} catch (error_) { console.log(error_); }
							}}
						/>
					</Box>
				</Grid>
				<Form
					content={editAssignmentFormContent}
					onSubmit={editAssignmentSubmitHandler}
				/>
			</Grid>
		</Popup>
	);
};

export default EditAssignmentPopup;
