import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Select, MenuItem, FormControl, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    card: {
        justifyContent: 'center',
        display: 'flex',
        minWidth: '420px',
        height: '50px',
        borderRadius: 12,
    },
    title: {
        fontSize: 15,
        fontWeight: 500,
        color: '#555'
    },
    value: {
        fontSize: 15,
        fontWeight: 700,
        color: '#333'
    },
    select: {
        marginLeft: 8,
        fontSize: 15,
        marginTop: -3,
    },
    no_data: {
        color: '#555',
        fontSize: 15
    }
});

const DropdownMetricCard = ({ title, value,  dropdownOptions, onSelectChange, setDropdownValue, publishedPerYearPublications, calculateAvgPubsPerYear, setAvgPubsInTheLast, totalPubs }) => {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState(2);
    const [totalPublications, setTotalPublications] = useState([])

    useEffect(() => {
        setTotalPublications(totalPubs)
    }, [totalPubs]);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setDropdownValue(selectedValue)
        setAvgPubsInTheLast(calculateAvgPubsPerYear(selectedValue, publishedPerYearPublications))
        if (onSelectChange) {
            onSelectChange(selectedValue);
        }
    };

    return (
        <Card className={classes.card} sx={{ m: 1, minWidth: 130 }}>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                >
                    {totalPublications !== 0 ? (
                        <>
                            <Grid item>
                                <Typography className={classes.title} >
                                    {title}
                                    <FormControl>
                                        <Select
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            className={classes.select}
                                            disableUnderline
                                            variant="standard"
                                        >
                                            {dropdownOptions.map((option) => (
                                                <MenuItem key={option[1]} value={option[1]}>
                                                    {option[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.value}>
                                    {value}
                                </Typography>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item>
                                <Typography className={classes.no_data} >
                                    No publications
                                </Typography>
                            </Grid>
                        </>
                    )}

                </Grid>
            </CardContent>
        </Card>
    );
};


export default DropdownMetricCard