import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import { ToggleButton, Grid, Typography, Link } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
	select: {
		padding: "0px 0px 0px 0px !important",
		color: theme.palette.third.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.third.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.third.main, // Set the desired color for the select's border when focused
		},
	},
	memberCard: {
		minHeight: "60px",
		borderRadius: "20px",
		alignItems: "center",
		background: theme.palette.secondary.main,
		boxShadow: "10px 3px 10px -5px #262835",
		flexDirection: "row",
	},
	linkButton: {
		color: "white",
		margin: "0px 5px",
		borderColor: "#fff",
		"&:hover": {
			color: theme.palette.white.dark,
			borderColor: theme.palette.white.dark,
		},
	},
	editButton: {
		backgroundColor: theme.palette.third.main,
		margin: "0px 5px",
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
	deleteButton: {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.main,
		margin: "0px 5px",
		"&:hover": {
			borderColor: theme.palette.error.dark,
			color: theme.palette.error.dark,
		},
	},
}));

export const Member = ({
	member,
	index,
	count,
	setDeleteMember,
	setDeleteMemberPopupOpen,
	setEditMember,
	setEditMemberPopupOpen,
	moveMemberHandler,
}) => {
	const classes = useStyles();

	return (
		<Grid
			container
			item
			xs={2.8}
			m={0.5}
			p={1}
			pt={3}
			className={classes.memberCard}
			sx={{
				border: member?.notRegistered === true && index !== undefined ? "1px solid" : "none",
				borderColor: "error.main",
			}}
		>
			<Grid
				item
				container
				xs={4.5}
				width="100%"
				flexDirection="column"
				alignContent="center"
				justifyContent="center"
			>
				{member.pictureUrl && (
					<img
						src={member.pictureUrl}
						alt=""
						style={{
							height: "120px",
							alignSelf: "center",
							marginBottom: "15px",
							filter: "grayscale(100%)",
						}}
					/>
				)}
				{index !== undefined && (
					<Grid
						item
						container
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
					>
						<Typography fontSize="0.9rem" color="third.main" mr={0.5}>
							{"Order:"}
						</Typography>
						<Select
							value={index + 1}
							sx={{
								height: "13px",
								fontSize: "0.9rem",
								width: "50px",
							}}
							classes={{ select: classes.select }}
							onChange={(event) => {
								moveMemberHandler(member.status, index, event.target.value - 1);
							}}
						>
							{
								[...Array.from({ length: count }).keys()].map((ind2) => (
									<MenuItem
										key={ind2 + 1}
										value={ind2 + 1}
									>
										{ind2 + 1}
									</MenuItem>
								))
							}
						</Select>
					</Grid>
				)}
			</Grid>
			<Grid
				item
				container
				xs={7}
				ml={1}
				alignContent="center"
				justifyContent="center"
				flexDirection="column"
			>
				<Typography fontSize="1rem" color="green.main" fontWeight="bold" mb={1}>
					{(member.title ? `${member.title} ` : "") + member.fullname + (member?.hidden === true ? " (hidden)" : "")}
				</Typography>
				<Typography fontSize="0.9rem" color="#fff" mb={0.5}>
					{member?.role ? `• ${member.role}` : ""}
				</Typography>
				<Typography fontSize="0.9rem" color="#fff" mb={0.5}>
					{member?.specialization ? `• ${member.specialization}` : ""}
				</Typography>

				<Grid
					item
					container
					flexDirection="row"
					justifyContent="center"
					mt={1}
				>
					{member.externalPage && (
						<Link
							href={member.externalPage}
							target="_blank"
							rel="noopener"
							sx={{ textDecoration: "none" }}
						>
							<ToggleButton
								value={member.externalPage}
								title={member.externalPage}
								size="small"
								aria-label={member.externalPage}
								className={classes.linkButton}
							>
								<LinkIcon color="inherit" />
							</ToggleButton>
						</Link>
					)}
					{index !== undefined && (
						<>
							<ToggleButton
								value="Edit"
								title="Edit"
								size="small"
								aria-label="Edit"
								className={classes.editButton}
								onClick={() => {
									setEditMember(member);
									setEditMemberPopupOpen(true);
								}}
							>
								<EditIcon color="white" />
							</ToggleButton>
							<ToggleButton
								value="Delete"
								title="Delete"
								size="small"
								aria-label="Delete"
								className={classes.deleteButton}
								onClick={() => {
									setDeleteMember(member);
									setDeleteMemberPopupOpen(true);
								}}
							>
								<DeleteIcon color="inherit" />
							</ToggleButton>
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
