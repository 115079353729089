// import * as React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import EditIcon from '@mui/icons-material/Edit';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { AddTagPopup, AreYouSurePopup } from "../components/Popups/index.js";

import { memo, useCallback, useEffect, useState, useMemo } from "react";

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, useWindowDimensions } from "../utils/index.js";
import {
	getTeam,
	getTeamStatistics,
	updateTeam,
	deleteTagFromTeam,
	updateTag,
} from "../api/index.js";
import { getEventModule } from "../utils/events-formatting.js";

import MembersIcon from "../assets/teamIcons/Members_icons2.png";
import PhdIcon from "../assets/teamIcons/Phd_icon2.png";
import ProjectsIcon from "../assets/teamIcons/Projects_icons2.png";
import PubliIcon from "../assets/teamIcons/Publi_icon2.png";
import ThesesIcon from "../assets/teamIcons/Theses_icon2.png";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const useStyles = makeStyles((theme) => ({
	buttonCursor: {
		cursor: "pointer",
		backgroundColor: theme.palette.primary.main,
		width: "38px",
		height: "38px",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	select: {
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: theme.spacing(2),
	},
	contentCard: {
		minHeight: "60px",
		borderRadius: "20px",
		alignItems: "center",
		background: theme.palette.secondary.main,
		boxShadow: "10px 3px 10px -5px #262835",
		flexDirection: "row",
		justifyContent: "space-evenly",
		cursor: "pointer",
		"&:hover": {
			background: theme.palette.secondary.dark,
		},
	},
	tagsCard: {
		minHeight: "60px",
		borderRadius: "20px",
		alignItems: "center",
		background: theme.palette.secondary.main,
		boxShadow: "10px 3px 10px -5px #262835",
		flexDirection: "row",
		justifyContent: "left",
		width: "100%",
		paddingLeft: "1rem",
	},
	chipBackground: {
		backgroundColor: theme.palette.primary.main,
		"& .MuiChip-label": {
			color: "#ffffff",
		},
		fontSize: "1.2rem",
	},
	chipBackgroundPopup: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: "#ffffff",
		},
		fontSize: "0.8rem",
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
	greenLink: {
		color: theme.palette.green.main,
		"&:hover": {
			color: theme.palette.green.dark,
		},
	},
	orangeLink: {
		color: theme.palette.light.main,
		"&:hover": {
			color: theme.palette.light.dark,
		},
	},
	addTag: {
		backgroundColor: theme.palette.light.main,
		color: '#fff',
		fontWeight: 'bold',
		width: 30,
		height: 30,
		borderRadius: '50%',
		marginLeft: '0.5rem',
		marginBottom: "0.5rem",
		"&:hover": {
			backgroundColor: theme.palette.light.dark
		}
	},
	tagChip: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		border: "3px solid",
		borderColor: theme.palette.secondary.main,
		minWidth: "100px",
		margin: "0.1rem",
		marginBottom: "0.5rem",
		height: 35
	},
	noTags: {
		color: "gray",
		fontSize: "15px",
		marginLeft: "1rem",
		marginBottom: "0.5rem",
		marginRight: "0.5rem",
	}
}));

const TeamInfo = () => {
	const windowDimensions = useWindowDimensions();
	const showEvents = useMemo(() => windowDimensions?.width > 1200, [windowDimensions.width]);

	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [team, setTeam] = useState([]);
	const [stats, setStats] = useState({});
	const [changeTitlePopupOpen, setChangeTitlePopupOpen] = useState(false);
	const [publicInfoPopupOpen, setPublicInfoPopupOpen] = useState(false);
	const [initialEventsSize, setInitialEventsSize] = useState(0);
	const [addTagPopupOpen, setAddTagPopupOpen] = useState(false);
	const [deleteTagPopupOpen, setDeleteTagPopupOpen] = useState(false);
	const [tagToDelete, setTagToDelete] = useState("");
	const [editTagPopupOpen, setEditTagPopupOpen] = useState(false);
	const [tagToEdit, setTagToEdit] = useState("");

	const { teamid } = useParams();
	const classes = useStyles();
	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const teamContents = [
		{
			type: "Members",
			img: MembersIcon,
			stats: "Projects stats here...",
			url: `/teams/${teamid}/members`,
			publicUrl: `/teams/${teamid}/members/public`,
			statsLiteral: "members",
		},
		{
			type: "Publications",
			img: PubliIcon,
			stats: "Publications stats here...",
			url: `/teams/${teamid}/publications`,
			publicUrl: `/team/${teamid}/publications/public`,
			statsLiteral: "publication",
		},
		{
			type: "Theses",
			img: ThesesIcon,
			stats: "Theses stats here...",
			url: `/teams/${teamid}/theses`,
			publicUrl: `/team/${teamid}/theses/public`,
			statsLiteral: "master_thesis",
		},
		{
			type: "PhDs",
			img: PhdIcon,
			stats: "PhDs stats here...",
			url: `/teams/${teamid}/phds`,
			publicUrl: `/team/${teamid}/phds/public`,
			statsLiteral: "phd_thesis",
		},
		{
			type: "Projects",
			img: ProjectsIcon,
			stats: "Projects stats here...",
			url: `/teams/${teamid}/projects`,
			publicUrl: `/team/${teamid}/projects/public`,
			statsLiteral: "project",
		},
		{
			type: "Proposals",
			img: ProjectsIcon,
			stats: "Proposals stats here...",
			url: `/teams/${teamid}/proposals`,
			publicUrl: null,
			statsLiteral: "proposal",
		},
	];

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, team: tm, message } = await getTeam(teamid);
				console.log(tm);
				if (scs) {
					tm.tags.sort(function (a, b) {
						if (a.toLowerCase() < b.toLowerCase()) {
							return -1;
						}
						if (a.toLowerCase() > b.toLowerCase()) {
							return 1;
						}
						return 0;
					})

					setInitialEventsSize(tm.eventsCount);
					setTeam(tm);
				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			try {
				const { success: scs, stats: sts, message } = await getTeamStatistics(teamid);
				if (scs) {
					setStats(sts);
				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, teamid],
	);

	const editTeamformContent = [
		{
			customType: "input",
			id: "name",
			type: "text",
			placeholder: "Name",
			value: team.name,
			label: "Name",
		},
		{
			customType: "input",
			id: "description",
			type: "text",
			placeholder: "Description",
			multiline: true,
			minRows: 3,
			value: team.description,
			label: "Description",
		},
		{
			customType: "input",
			id: "website",
			type: "text",
			placeholder: "https://...",
			value: team.website,
			label: "Website URL",
		},
		{
			customType: "input",
			id: "logo",
			type: "text",
			placeholder: "https://...",
			value: team.logoUrl,
			label: "Logo URL",
		},
		{
			customType: "input",
			id: "discordURL",
			type: "text",
			placeholder: "https://...",
			value: team.discordURL,
			label: "Discord Webhook URL",
		},
		{
			customType: "input",
			id: "cycloptToken",
			type: "text",
			placeholder: "",
			value: team.cycloptToken,
			label: "Cyclopt API token",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save Changes",
		},
	];

	const editTagFormContent = [
		{
			customType: "input",
			id: "tagName",
			type: "text",
			placeholder: "Tag Name",
			value: tagToEdit,
			label: "Tag Name",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save Changes",
		},
	];

	const editTeamSubmitHandler = async (event) => {
		setIsLoading(true);
		console.log(event);
		try {
			const { success: successCode, message } = await updateTeam(team?._id ?? "", event.name, event.description, event.website, event.logo, event.discordURL, event.cycloptToken);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setChangeTitlePopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const editTagSubmitHandler = async (event) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await updateTag(team._id, tagToEdit, event.tagName);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
			setTagToEdit("");
			setEditTagPopupOpen(false);
		} catch {/* empty */ }

		await fetchData();
		setIsLoading(false);
	}

	const declineTagDelete = () => {
		setTagToDelete("");
		setDeleteTagPopupOpen(false);
	}

	const deleteTagSubmitHandler = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteTagFromTeam(teamid, tagToDelete);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

		} catch {/*empty */ }

		await fetchData();
		setTagToDelete("");
		setIsLoading(false);
		setDeleteTagPopupOpen(false);
	}

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<AddTagPopup
				open={addTagPopupOpen}
				setOpen={setAddTagPopupOpen}
				teamId={teamid}
				setIsLoading={setIsLoading}
				fetchData={fetchData}
				success={success}
				error={error}
			/>
			<AreYouSurePopup
				open={deleteTagPopupOpen}
				title="Delete Tag?"
				content={`Are you sure you want to delete the tag "${tagToDelete}"?`}
				onDecline={declineTagDelete}
				onAccept={deleteTagSubmitHandler}
			/>
			<Popup
				width="800px"
				open={editTagPopupOpen}
				title="Edit Tag"
				onClose={() => {
					setEditTagPopupOpen(false);
					setTagToEdit("");
				}}
			>
				<Form
					content={editTagFormContent}
					onSubmit={editTagSubmitHandler}
				/>
			</Popup>
			<Popup
				width="800px"
				open={changeTitlePopupOpen}
				title="Edit Team info"
				onClose={() => setChangeTitlePopupOpen(false)}
			>
				<Form
					content={editTeamformContent}
					onSubmit={editTeamSubmitHandler}
				/>
			</Popup>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				{/* Header */}
				<Grid
					container
					item
					className={classes.titleBox}
				>
					<Grid
						item
						xs={7}
						ml={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Avatar
							sx={{ mr: 2 }}
							className={classes.buttonCursor}
							onClick={() => { setChangeTitlePopupOpen(true); }}
						>
							<EditIcon />
						</Avatar>
						<Typography variant="h6" mr={1} color="#ffffff">
							{team.name}
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
						pr={3}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						height="100%"
					>
						{team.logoUrl && (
							<img
								src={team.logoUrl}
								alt=""
								style={{
									maxHeight: "50px",
									padding: "2px",
									paddingRight: "10px",
									paddingLeft: "10px",
								}}
							/>
						)}
						{!team.logoUrl && <ImageNotSupportedIcon color="white" fontSize="large" />}
					</Grid>
				</Grid>
				{/* Main page with events and tiles */}
				<Grid
					container
					// mt={2}
					mb={1}
					width="100%"
					justifyContent="space-between"
					alignItems="start"
				>
					{showEvents && team?.events && (
						<Grid
							container
							item
							width="100%"
							overflow='auto'
							xs={2.6}
						>
							<Timeline
								sx={{
									[`& .${timelineItemClasses.root}:before`]: {
										flex: 0,
										padding: 0,
									},

								}}

							>
								{team.events.map((e, ind) => (
									<TimelineItem key={ind}>
										<TimelineSeparator>
											<TimelineDot color="white">
												{
													getEventModule(e)
												}
											</TimelineDot>
											{ind !== team.events.length - 1 && <TimelineConnector />}
										</TimelineSeparator>
										<TimelineContent>
											<Typography fontSize="0.8rem" color="#ffffff">
												{e.message}
											</Typography>
											<Typography color="#acc8da" fontSize="0.7rem">
												{e.createdAt.replace("T", " ").replace("Z", "")}
											</Typography>
										</TimelineContent>
									</TimelineItem>
								))}
							</Timeline>
							{initialEventsSize > 20 && (
								<Typography color="gray" ml={4} mb={1} fontSize="0.8rem">
									{`... and ${initialEventsSize - 20} more`}
								</Typography>
							)}

						</Grid>
					)}
					{/* Tiles */}
					{team?.events && (
						<Grid
							container
							item
							width="100%"
							xs={showEvents ? 9.3 : 12}
							display="flex"
							flexDirection="row"
							alignContent="center"
							justifyContent="center"
							mt={1}
						>
							{/* team contents */}
							{teamContents?.map((e, ind) => (
								<Grid
									key={ind}
									container
									item
									className={classes.contentCard}
									xs={3.8}
									m={0.5}
									p={3}
									onClick={() => {
										navigate(e.url);
									}}
								>
									<Grid
										item
										container
										xs={5}
										width="100%"
										flexDirection="column"
										alignContent="center"
										justifyContent="center"
									>

										<img
											src={e.img}
											alt=""
											style={{
												height: "50px",
												width: "50px",
												alignSelf: "center",
												marginBottom: "15px",
											}}
										/>

										<Typography fontSize="1.2rem" color="white.main" fontWeight="bold">
											{e.type}
										</Typography>
										<Chip
											label={stats[e.statsLiteral]}
											className={classes.chipBackground}
											sx={{
												mr: 0.5,
												mt: 0.5,
												fontWeight: "bold",
											}}
										/>
									</Grid>
									<Grid
										item
										container
										xs={6}
										alignContent="center"
										justifyContent="center"
									>
										{Object.entries(team?.totalStats[e.statsLiteral]).map(([key, value]) => (
											<Grid key={key} item container flexDirection="row" mb={0.5}>
												<Typography
													sx={{
														mr: 0.7,
														color: "#fff",
														fontSize: "0.9rem",
													}}
												>
													{`${key}:`}
												</Typography>
												<Typography
													sx={{
														fontWeight: "bold",
														color: "#00a3a1",
														fontSize: "0.9rem",
													}}
												>
													{`${value}`}
												</Typography>
											</Grid>
										))}
									</Grid>
								</Grid>
							))}
							<Grid
								item
								container
								flexDirection="column"
								xs={12}
								m={0.5}
								p={3}
								paddingTop="0.2rem"
								alignItems="center"
								justifyContent="center"
							>
								<Grid
									container
									className={classes.tagsCard}
								>
									<Grid item xs={12}>
										<Typography fontSize="1.2rem" color="white.main" fontWeight="bold" margin="0.75rem" marginTop="0.5rem" marginBottom="0.1rem">
											Tags
										</Typography>
									</Grid>
									{(team?.tags && team?.tags.length > 0) ? (
										<>
											{team.tags.map((e, ind) => (
												<Chip
													key={ind}
													label={e}
													icon={
														<>
															<IconButton
																size="small"
																onClick={() => {
																	setDeleteTagPopupOpen(true);
																	setTagToDelete(e);
																}}
																edge="start"
																color="inherit"
															>
																<DeleteIcon />
															</IconButton>
															<IconButton
																size="small"
																onClick={() => {
																	setEditTagPopupOpen(true);
																	setTagToEdit(e);
																}}
																edge="start"
																color="inherit"
															>
																<DriveFileRenameOutlineIcon />
															</IconButton>
														</>
													}
													className={classes.tagChip}
												/>
											))}
										</>
									) : (
										<>
											<Typography className={classes.noTags}>
												No Tags
											</Typography>
										</>
									)}
									<Tooltip title="Add your team's tags" arrow>
										<IconButton
											className={classes.addTag}
											onClick={() => setAddTagPopupOpen(true)}
										>
											<AddIcon />
										</IconButton>
									</Tooltip>
								</Grid>
								<Chip
									label="Team's public data information"
									color="secondary"
									sx={{
										mr: 0.5,
										mt: 2,
										fontWeight: 'bold',
									}}
									onClick={() => setPublicInfoPopupOpen(true)}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Popup
				// width="800px"
				open={publicInfoPopupOpen}
				title="Team's public data information"
				onClose={() => setPublicInfoPopupOpen(false)}
			>
				<Grid
					item
					container
					mt={1}
					p={1}
					pl={3}
					flexDirection="row"
				>
					<Grid
						item
						xs={6}
						flexDirection="column"
					>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- Public link to all members: "}
							<Link
								to={`/teams/${teamid}/members/public`}
								target="_blank"
								className={classes.greenLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- Public link to current members: "}
							<Link
								to={`/teams/${teamid}/membersOnly/public`}
								target="_blank"
								className={classes.greenLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- API URL to members: "}
							<Link
								to={`https://publisync.issel.ee.auth.gr/backend/api/public/publicTeam/${teamid}/members`}
								target="_blank"
								className={classes.orangeLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- Public link to projects: "}
							<Link
								to={`/teams/${teamid}/projects/public`}
								target="_blank"
								className={classes.greenLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- API URL to projects: "}
							<Link
								to={`https://publisync.issel.ee.auth.gr/backend/api/public/publicTeam/${teamid}/projects`}
								target="_blank"
								className={classes.orangeLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- Public link to publications: "}
							<Link
								to={`/teams/${teamid}/publications/public`}
								target="_blank"
								className={classes.greenLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- API URL to publications: "}
							<Link
								to={`https://publisync.issel.ee.auth.gr/backend/api/public/publicTeam/${teamid}/publication`}
								target="_blank"
								className={classes.orangeLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- Public link to master theses: "}
							<Link
								to={`/teams/${teamid}/theses/public`}
								target="_blank"
								className={classes.greenLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- API URL to master theses: "}
							<Link
								to={`https://publisync.issel.ee.auth.gr/backend/api/public/publicTeam/${teamid}/master_thesis`}
								target="_blank"
								className={classes.orangeLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- Public link to PhDs: "}
							<Link
								to={`/teams/${teamid}/phds/public`}
								target="_blank"
								className={classes.greenLink}
							>
								{`Link`}
							</Link>
						</Typography>
						<Typography
							sx={{
								color: "white.main",
								fontSize: "0.9rem",
							}}
						>
							{"- API URL to PhDs: "}
							<Link
								to={`https://publisync.issel.ee.auth.gr/backend/api/public/publicTeam/${teamid}/phd_thesis`}
								target="_blank"
								className={classes.orangeLink}
							>
								{`Link`}
							</Link>
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						flexDirection="column"
					>
						{/* Chip that when clicked copies a code */}
						<Link to="https://gist.github.com/etsardou/5430a2f223d11f8d27ceea3058beaba1" target="_blank">
							<Chip
								label="HTML/CSS code for embedding the team's public data"
								className={classes.chipBackgroundPopup}
								sx={{
									mr: 0.5,
									mt: 0.5,
									fontWeight: "bold",
									cursor: "pointer",
								}}
							/>
						</Link>
					</Grid>
				</Grid>
			</Popup>
		</>
	);
};

export default memo(TeamInfo);
