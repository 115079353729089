
export const publicationTypes = [
	{ value: "All types", text: "All types" },
	{ value: "Article", text: "Article" },
	{ value: "Book", text: "Book" },
	{ value: "Edited book", text: "Edited book" },
	{ value: "Conference", text: "Conference" },
	{ value: "Book chapter", text: "Book chapter" },
	{ value: "Announcement", text: "Announcement" },
	// { value: "Master thesis", text: "Master thesis" },
	// { value: "Phd thesis", text: "Phd thesis" },
	{ value: "Misc", text: "Misc" },
];

export const publicationTypesMapping = {
	Article: "publications",
	Book: "publications",
	"Edited book": "publications",
	Conference: "publications",
	"Book chapter": "publications",
	Announcement: "publications",
	"Master thesis": "theses",
	"Phd thesis": "phds",
	Proposal: "proposals",
	Misc: "publications",
};

export const fullPublicationTypes = [
	...publicationTypes,
	{ value: "Master thesis", text: "Master thesis" },
	{ value: "Phd thesis", text: "Phd thesis" },
	{ value: "Proposal", text: "Proposal" },
];

export const publicationStates = [
	{ value: "All states", text: "All states" },
	{ value: "Idea", text: "Idea" },
	{ value: "Implementation", text: "Implementation" },
	{ value: "Composition", text: "Composition" },
	{ value: "Internal Review", text: "Internal Review" },
	{ value: "Internal Revisions", text: "Internal Revisions" },
	{ value: "Submitted", text: "Submitted" },
	{ value: "Rejected", text: "Rejected" },
	{ value: "Revisions", text: "Revisions" },
	{ value: "Accepted", text: "Accepted" },
	{ value: "Published", text: "Published" },
];

export const publicationStatesOrdered = {
	Idea: 1,
	Implementation: 2,
	Composition: 3,
	"Internal Review": 4,
	"Internal Revisions": 5,
	Submitted: 6,
	Rejected: 7,
	Revisions: 8,
	Accepted: 9,
	Published: 10,
};

export const publicationQuartiles = [
	{ value: "All Quartiles", text: "All Quartiles" },
	{ value: "No quartile", text: "None" },
	{ value: "Q1", text: "Q1" },
	{ value: "Q2", text: "Q2" },
	{ value: "Q3", text: "Q3" },
	{ value: "Q4", text: "Q4" },
];