// import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PublicIcon from '@mui/icons-material/Public';
import MenuItem from '@mui/material/MenuItem';
import { PublicationsComponent } from "../components/PublicationsComponent.js";
import { memo, useCallback, useEffect, useState } from "react";

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import PublicationFilters from "../components/PublicationFilters.js";
import { useSnackbar, dayjs } from "../utils/index.js";
import {
	getTeamMinimalInfo,
	addPublicationToTeam,
	removePublication,
	getTeamPublications,
} from "../api/index.js";
import {
	publicationStates,
	publicationTypes,
} from "../utils/publication-constants.js";

import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";
import PublicationMetrics from "../components/PublicationMetrics.js"

const useStyles = makeStyles((theme) => ({
	buttonCursor: {
		cursor: "pointer",
	},
	select: {
		color: theme.palette.green.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.green.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.green.main, // Set the desired color for the select's border when focused
		},
	},
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		borderRadius: theme.spacing(2),
		alignItems: "center",
	},
	metricsBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		borderRadius: theme.spacing(2),
		alignItems: "center",
	},
	publicPageChip: {
		color: theme.palette.third.main,
		borderColor: theme.palette.third.main,
		border: "1px solid",
		"& .MuiChip-label": {
			color: theme.palette.third.main,
		},
		fontSize: "0.9rem",
		"&:hover": {
			borderColor: theme.palette.third.dark,
			color: theme.palette.third.dark,
			"& .MuiChip-label": {
				color: theme.palette.third.dark,
			},
		},
	},
	publicationChip: {
		backgroundColor: theme.palette.light.main,
		"& .MuiChip-label": {
			color: "white",
		},
		fontSize: "1.2rem",
		"&:hover": {
			backgroundColor: theme.palette.light.dark,
		},
	},
}));

const literals = {
	publication: "publications",
	master_thesis: "theses",
	phd_thesis: "PhDs",
	proposal: "proposals",
};

const TeamPublications = ({ content }) => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [team, setTeam] = useState([]);
	const [publications, setPublications] = useState([]);
	const [addPublicationPopupOpen, setAddPublicationPopupOpen] = useState(false);
	const [newPublicationType, setNewPublicationType] = useState("");
	const [newPublicationState, setNewPublicationState] = useState("");
	const [filterType, setFilterType] = useState("")

	// const [publicationsSearchFilter, setPublicationsSearchFilter] = useState("");
	const [filteredPublications, setFilteredPublications] = useState([]);

	const [publicationToDelete, setPublicationToDelete] = useState({});
	const [deletePublicationPopupOpen, setDeletePublicationPopupOpen] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [pageContent, setPageContent] = useState(content);
	const [newPublicationProperTitle, setNewPublicationProperTitle] = useState("");

	const { teamid } = useParams();
	const classes = useStyles();
	const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, team: tm, message } = await getTeamMinimalInfo(teamid);
				if (scs) {
					setTeam(tm);
				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			try {
				const { success: scs, publications: pubs, message } = await getTeamPublications(teamid, pageContent);
				if (scs) {
					pubs.sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt)));
					setPublications(pubs);
					console.log(pubs);
				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			switch (pageContent) {
				case "publication": {
					setNewPublicationProperTitle("publication");
					break;
				}

				case "master_thesis": {
					setNewPublicationProperTitle("master thesis");
					setNewPublicationType("Master thesis");
					break;
				}

				case "phd_thesis": {
					setNewPublicationProperTitle("PhD thesis");
					setNewPublicationType("Phd thesis");
					break;
				}

				case "proposal": {
					setNewPublicationProperTitle("Proposals");
					setNewPublicationType("Proposal");
					break;
				}

				default: {
					setNewPublicationProperTitle("document");
					setNewPublicationType("Misc");
					break;
				}
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, teamid, content],
	);

	const addPublicationFormContent = [
		{
			customType: "input",
			id: "title",
			type: "text",
			placeholder: `New ${newPublicationProperTitle}`,
			label: "Title",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: `Add ${newPublicationProperTitle}`,
		},
	];

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const deletePublication = async () => {
		setIsLoading(true);
		const { success: successCode, message } = await removePublication(publicationToDelete._id);

		if (successCode) {
			success(message);
		} else {
			error(message);
		}

		await fetchData();
		setIsLoading(false);
		setDeletePublicationPopupOpen(false);
		setPublicationToDelete({});
	};

	const declineDeletePublication = () => {
		setDeletePublicationPopupOpen(false);
		setPublicationToDelete({});
	};

	const addPublicationSubmitHandler = async (values) => {
		setIsLoading(true);
		console.log(values);
		try {
			const { success: successCode, message } = await addPublicationToTeam(
				team._id,
				values.title,
				values.doi,
				newPublicationType,
				newPublicationState,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setAddPublicationPopupOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setNewPublicationState("");
		pageContent === "publication" ? setNewPublicationType("") : null;
		setIsLoading(false);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<AreYouSurePopup
				open={deletePublicationPopupOpen}
				title={`Delete ${newPublicationProperTitle}?`}
				content={`Are you sure you want to delete "${publicationToDelete?.title}"?`}
				onDecline={declineDeletePublication}
				onAccept={deletePublication}
			/>
			<Popup
				width="800px"
				open={addPublicationPopupOpen}
				title={`Add ${newPublicationProperTitle} to team`}
				onClose={() => {
					setAddPublicationPopupOpen(false);
					setNewPublicationState("");
					pageContent === "publication" ? setNewPublicationType("") : null;
				}}
			>
				{pageContent === "publication" && (
					<Grid
						container
						flexDirection="row"
						flex={1}
						sx={{
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							mb: 1,
						}}
					>
						<Typography color="third.main">{"Choose type: "}</Typography>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

							<Select
								className={classes.select}
								value={newPublicationType}
								onChange={(event) => {
									setNewPublicationType(event.target.value);
								}}
							>
								{
									// remove "All types" from the list
									publicationTypes.slice(1).map((e, ind) => (
										<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Grid>
				)}
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Choose state: "}</Typography>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

						<Select
							className={classes.select}
							value={newPublicationState}
							onChange={(event) => {
								setNewPublicationState(event.target.value);
							}}
						>
							{
								publicationStates.slice(1).map((e, ind) => (
									<MenuItem key={ind} value={e.value}>{e.text}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Form content={addPublicationFormContent} onSubmit={addPublicationSubmitHandler} />
			</Popup>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					className={classes.titleBox}
				>
					<Grid
						item
						xs={5}
						pl={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h6" color="#ffffff">
							{`${team.name} ${literals[pageContent]}`}
						</Typography>
						<Chip
							icon={<PublicIcon color="inherit" />}
							label="Public page"
							variant="outlined"
							className={classes.publicPageChip}
							sx={{
								ml: 1,
							}}
							onClick={() => {
								navigate(`/teams/${team._id}/${literals[pageContent].toLowerCase()}/public`);
							}}
						/>
						<Chip
							label="+"
							className={classes.publicationChip}
							sx={{
								mr: 0.5,
								ml: 1,
							}}
							onClick={() => setAddPublicationPopupOpen(true)}
						/>
					</Grid>
					<Grid
						item
						xs={7}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						height="100%"
					>
						<Grid
							container
							item
							width="100%"
							display="flex"
							borderRadius="20px"
							alignItems="center"
							minHeight="30px"
							justifyContent="space-between"
						>
							<Grid
								item
								width="100%"
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="end"
							>
								<PublicationFilters
									showStatesFilter
									teams={[]}
									publications={publications}
									setFilteredPublications={setFilteredPublications}
									showTeamFilter={false}
									showTypesFilter={pageContent === "publication"}
									setFilterType={setFilterType}
									showMembersFilter={pageContent === "publication"}
									showSupervisorsFilter={pageContent === "master_thesis"}
									teamId={teamid}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{pageContent === "publication" && (
					<Grid
						container
						item
						className={classes.metricsBox}
					>
						<PublicationMetrics
							filterType={filterType}
							filteredPublications={filteredPublications}
						/>
					</Grid>
				)}
				{
					publications && (
						<Grid
							container
							mt={2}
							mb={2}
							width="100%"
							justifyContent="space-between"
							alignItems="start"
						>
							<Grid
								container
								item
								width="100%"
								xs={12}
								display="flex"
								flexDirection="column"
							>
								<PublicationsComponent
									filteredPublications={filteredPublications}
									setPublicationToDelete={setPublicationToDelete}
									setDeletePublicationPopupOpen={setDeletePublicationPopupOpen}
									showTeam="false"
									publicPreview="false"
								/>
							</Grid>
						</Grid>
					)
				}

			</Grid>
		</>
	);
};

export default memo(TeamPublications);
