import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	main: {
		width: "100%",
		height: "100%",
		backgroundColor: "primary.main",
	},
	mainBox: {
		// padding: "10px 0px 70px 20px",
		// overflow: "auto",
		position: "absolute",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		backgroundColor: "primary.main",
		alignItems: "center",
	},
}));

const AllAccess = ({ c }) => {
	const classes = useStyles();
	return (
		<div className={classes.main}>
			<div className={classes.mainBox} style={{ width: "100%" }}>
				<div className="header-container">
					{c}
				</div>
			</div>
		</div>
	);
};

AllAccess.propTypes = { c: PropTypes.node.isRequired };
AllAccess.whyDidYouRender = true;

export default AllAccess;
