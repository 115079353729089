import { useState, useEffect, memo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { jwt, useSnackbar } from "../utils/index.js";
import api from "../api/index.js";

const Auth = () => {
	const location = useLocation();
	const { error: error_ } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState("/teams");
	const navigate = useNavigate();

	useEffect(() => {
		try {
			setRedirectTo((p) => JSON.parse(sessionStorage.getItem("redirectTo")) || p);
			// sessionStorage.removeItem("redirectTo");
		} catch { /** empty */ }
	}, []);

	const [state, setState] = useState({ user: null, error: null });
	const [error, setError] = useState(queryString.parse(location.search)?.error || null);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const { token, error: err } = queryString.parse(location.search);
				if (error) {
					setError(err);
					navigate("/");
				} else {
					if (token) jwt.setToken(token);
					const usr = await api.get("user/attempt-auth/");
					if (isMounted) setState({ user: usr });
					// console.log(usr);
					const redirectPath = JSON.parse(sessionStorage.getItem("redirectTo"))?.pathname || '/';
					sessionStorage.removeItem("redirectTo");
					// console.log("redirecting!!", redirectPath);
					navigate(redirectPath);
				}
			} catch {
				error_();
				navigate("/");
			}
		})();

		return () => { isMounted = false; };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, error_, location.search]);

	if (error) return (<Navigate replace to="/" state={{ error }} />);
	if (!state.user) return <div />;
	return state.user.ok ? <Navigate replace to={redirectTo} /> : <Navigate replace to="/" />;
};

export default memo(Auth);
