import { useState } from "react";
import { TextField } from "@mui/material";
import { TimePicker, DateTimePicker, DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";

import colors from "../_colors.scss";

const useStyles = makeStyles(() => ({
	root: {
		"& .MuiInputBase-root": {
			padding: 5,
			paddingRight: 15,
			"& .MuiButtonBase-root": {
				padding: 0,
			},
			"& .MuiInputBase-input": {
				padding: 0,
			},
			fontSize: 13,
		},
		color: (props) => colors[props.color] || props.color,
		backgroundColor: (props) => colors[props.background] || props.background,
		borderRadius: (props) => props.borderRadius,
		outline: "none",
		width: (props) => props.width,
	}
}));

const DatePicker = ({
	type = "desktop", // desktop, mobile, time, datetime
	value = null,
	onChange,
	disabled = false,
	label = "Date",
	views = ["day", "month", "year"],
	background = "third",
	color = "white",
	borderRadius = "10px",
	width = "100%",
}) => {
	const classes = useStyles({ background, color, borderRadius, width });
	const [customValue, setCustomValue] = useState(value);

	const handleChange = (newValue) => {
		setCustomValue(newValue);
		if (onChange) onChange(newValue);
	};

	return (
		<>
			{type === "desktop" && (
				<DesktopDatePicker
					className={classes.root}
					views={views}
					disabled={disabled}
					label={label}
					inputFormat="DD/MM/YYYY"
					value={customValue}
					renderInput={(params) => <TextField {...params} />}
					onChange={handleChange}
				/>
			)}
			{type === "mobile" && (
				<MobileDatePicker
					className={classes.root}
					views={views}
					disabled={disabled}
					label={label}
					inputFormat="DD/MM/YYYY"
					value={customValue}
					renderInput={(params) => <TextField {...params} />}
					onChange={handleChange}
				/>
			)}
			{type === "time" && (
				<TimePicker
					className={classes.root}
					disabled={disabled}
					label={label}
					value={customValue}
					renderInput={(params) => <TextField {...params} />}
					onChange={handleChange}
				/>
			)}
			{type === "datetime" && (
				<DateTimePicker
					className={classes.root}
					padding="0"
					views={views}
					disabled={disabled}
					label={label}
					value={customValue}
					renderInput={(params) => <TextField {...params} />}
					onChange={handleChange}
				/>
			)}
		</>
	);
};

export default DatePicker;
