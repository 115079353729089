import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	IconButton,
	Chip,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import SmsIcon from '@mui/icons-material/Sms';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { calculateTimestampToShow } from "../../utils/timestamps.js";
import EditCommentPopup from "../Popups/EditCommentPopup.js";
import AreYouSurePopup from "../Popups/AreYouSurePopup.js";
import { deletePublicationComment } from "../../api/index.js";

const useStyles = makeStyles((theme) => ({
	icons: {
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const Comments = ({
	publication,
	setAddCommentPopupOpen,
	classes,
	allPublicationAuthorsAndMembers,
	setIsLoading,
	error,
	success,
	fetchData,
}) => {
	const [commentToEdit, setCommentToEdit] = useState(null);
	const [openEditCommentPopup, setOpenEditCommentPopup] = useState(false);

	const [commentToDelete, setCommentToDelete] = useState(null);
	const [deleteCommentPopupOpen, setDeleteCommentPopupOpen] = useState(false);
	const newClasses = useStyles();

	useEffect(() => {
		// console.log("Comments mounted!");
	}, []);

	const editCommentHandler = (id) => {
		setCommentToEdit(id);
		setOpenEditCommentPopup(true);
	};

	const deleteCommentHandler = async (id) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deletePublicationComment(publication._id, id);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setDeleteCommentPopupOpen(false);
		setIsLoading(false);
	};

	return (
		<Grid
			container
			item
			// mt={2}
			width="100%"
			// xs={3.95}
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			sx={{
				borderRadius: "20px",
			}}
			className={classes.publicationInfoCard}
		>
			<AreYouSurePopup
				open={deleteCommentPopupOpen}
				title="Delete comment?"
				content={`Are you sure you want to delete comment "${commentToDelete?.text}"?`}
				onDecline={() => setDeleteCommentPopupOpen(false)}
				onAccept={() => deleteCommentHandler(commentToDelete._id)}
			/>
			<EditCommentPopup
				open={openEditCommentPopup}
				setOpen={setOpenEditCommentPopup}
				commentId={commentToEdit}
				allPublicationAuthorsAndMembers={allPublicationAuthorsAndMembers}
				publication={publication}
				setIsLoading={setIsLoading}
				error={error}
				success={success}
				fetchData={fetchData}
				classes={classes}
			/>
			<Grid
				container
				item
				width="100%"
				p={1}
				pl={2}
				mb={1}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{"Comments"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Chip
						key={-1}
						label="Add comment"
						className={classes.actionChip}
						sx={{
							mr: 0.5,
						}}
						onClick={() => {
							setAddCommentPopupOpen(true);
						}}
					/>
				</Grid>
			</Grid>
			<List dense>
				{publication.finalComments.map((comment, ind) => (
					<ListItem
						key={ind}
						secondaryAction={(
							<>
								<IconButton edge="end" onClick={() => editCommentHandler(comment._id)}>
									<DriveFileRenameOutlineIcon className={newClasses.icons} />
								</IconButton>
								<IconButton
									edge="end"
									onClick={() => {
										setCommentToDelete(comment);
										setDeleteCommentPopupOpen(true);
										// deleteCommentHandler(comment._id);
									}}
								>
									<DeleteIcon className={newClasses.icons} />
								</IconButton>
							</>
						)}
					>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: "third.main" }}>
								<SmsIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={(
								<Grid
									container
									style={{
										alignItems: "baseline",
										flexDirection: "column",
									}}
								>
									<Grid
										container
										style={{
											alignItems: "baseline",
											flexDirection: "row",
										}}
									>
										<Typography
											fontSize="0.9rem"
											color="third.main"
										>
											{`From: ${comment.creatorName}`}
										</Typography>
									</Grid>
									{comment.mentionsNames.length > 0 && (
										<Grid
											container
											style={{
												alignItems: "baseline",
												flexDirection: "row",
											}}
										>
											<Typography
												fontSize="0.9rem"
												color="green.main"
												mr={1}
											>
												{"To:"}
											</Typography>
											{
												comment.mentionsNames.map((m, index) => (
													<Typography
														key={index}
														fontSize="0.9rem"
														color="green.main"
														mr={index === comment.mentionsNames.length - 1 ? 0 : 0.5}
													>
														{m + (index === comment.mentionsNames.length - 1 ? "" : ", ")}
													</Typography>
												))

											}
										</Grid>
									)}

									<Typography fontSize="0.9rem" color="#fff">
										{comment.text}
									</Typography>
									<Typography color="gray" fontSize="0.7rem">
										{/* {e.timestamp.replace("T", " ").replace("Z", "")} */}
										{`${calculateTimestampToShow(comment.timestamp)}`}
									</Typography>
								</Grid>
							)}
						/>
					</ListItem>
				))}
				{
					publication.finalComments.length === 0 && (
						<Typography color="gray" pl={2} pb={1}>
							{"No comments"}
						</Typography>
					)
				}
			</List>
		</Grid>
	);
};

export default Comments;
