import { useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Box, Link } from "@mui/material";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { memo, useCallback, useEffect, useState } from "react";
import Spinner from "../components/Spinner.js";
import { useSnackbar, jwt, dayjs } from "../utils/index.js";
import GenericMdEditor from "../components/MdEditor.js";
import ProjectInformationElement from "../components/ProjectInformationElement.js";
import Form from "../components/Form.js";
import Popup from "../components/Popup.js";
import {
	getProject,
	saveProjectInformation,
} from "../api/index.js";

const useStyles = makeStyles({
	select: {
		padding: "0px 0px 0px 0px !important",
		color: "#F1A542", // Set the desired color for the select
		"&:before": {
			borderColor: "#F1A542", // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: "#F1A542", // Set the desired color for the select's border when focused
		},
	},
});

const MemberInfo = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [project, setProject] = useState([]);
	const [textualPopupOpen, setTextualPopupOpen] = useState(false);
	const [checkboxPopupOpen, setCheckboxPopupOpen] = useState(false);
	const [numericalPopupOpen, setNumericalPopupOpen] = useState(false);
	const [datepickerPopupOpen, setDatepickerPopupOpen] = useState(false);
	const [textualMultilinePopupOpen, setTextualMultilinePopupOpen] = useState(false);
	const [currentKey, setCurrentKey] = useState("");
	const [currentTitle, setCurrentTitle] = useState("");

	// eslint-disable-next-line no-unused-vars
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const { teamid } = useParams();
	const { projectid } = useParams();
	const { id: myid } = jwt.decode();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, project: prj, message } = await getProject(projectid);
				if (scs) {
					console.log(prj);
					setProject(prj);
				} else {
					console.log(message);
					error(message ?? '');
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, projectid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const saveInformation = async (values) => {
		const key = Object.keys(values)[0];
		const val = values[key];
		setIsLoading(true);
		try {
			const { success: scs, message } = await saveProjectInformation(projectid, key, val);
			if (scs) {
				success(message);
				(async () => {
					await fetchData();
				})();
			} else {
				error(message);
			}
		} catch (error_) {
			console.log(error_);
			error();
		}

		setTextualPopupOpen(false);
		setTextualMultilinePopupOpen(false);
		setNumericalPopupOpen(false);
		setCheckboxPopupOpen(false);
		setDatepickerPopupOpen(false);
		setIsLoading(false);
	};

	return (
		<>
			<Spinner open={isLoading} />
			<Popup
				width="800px"
				open={textualPopupOpen}
				title={`Change value for ${currentTitle}`}
				onClose={() => setTextualPopupOpen(false)}
			>
				<Form
					content={[
						{
							customType: "wideInput",
							id: currentKey,
							type: "text",
							value: project[currentKey],
							width: 800,
							placeholder: "",
						},
						{
							customType: "button",
							id: "submit",
							type: "submit",
							text: `Change ${currentTitle}`,
						},
					]}
					onSubmit={saveInformation}
				/>
			</Popup>
			<Popup
				width="800px"
				open={numericalPopupOpen}
				title={`Change value for ${currentTitle}`}
				onClose={() => setNumericalPopupOpen(false)}
			>
				<Form
					content={[
						{
							customType: "wideInput",
							id: currentKey,
							type: "number",
							value: project[currentKey],
							width: 800,
							placeholder: "",
						},
						{
							customType: "button",
							id: "submit",
							type: "submit",
							text: `Change ${currentTitle}`,
						},
					]}
					onSubmit={saveInformation}
				/>
			</Popup>
			<Popup
				width="800px"
				open={checkboxPopupOpen}
				title={`Change value for ${currentTitle}`}
				onClose={() => setCheckboxPopupOpen(false)}
			>
				<Form
					content={[
						{
							customType: "checkbox",
							id: currentKey,
							label: currentTitle,
							defaultValue: project[currentKey],
							color: "secondary",
							disabled: false,
						},
						{
							customType: "button",
							id: "submit",
							type: "submit",
							text: `Change ${currentTitle}`,
						},
					]}
					onSubmit={saveInformation}
				/>
			</Popup>
			<Popup
				width="800px"
				open={datepickerPopupOpen}
				title={`Change value for ${currentTitle}`}
				onClose={() => setDatepickerPopupOpen(false)}
			>
				<Form
					content={[
						{
							customType: "date-picker",
							id: currentKey,
							type: "desktop",
							label: currentTitle,
							value: project[currentKey],
						},
						{
							customType: "button",
							id: "submit",
							type: "submit",
							text: `Change ${currentTitle}`,
						},
					]}
					onSubmit={saveInformation}
				/>
			</Popup>
			<Popup
				width="800px"
				open={textualMultilinePopupOpen}
				title={`Change value for ${currentTitle}`}
				onClose={() => setTextualMultilinePopupOpen(false)}
			>
				<Form
					content={[
						{
							customType: "wideInput",
							id: currentKey,
							type: "text",
							value: project[currentKey],
							width: 800,
							multiline: true,
							minRows: 8,
							placeholder: "",
						},
						{
							customType: "button",
							id: "submit",
							type: "submit",
							text: `Change ${currentTitle}`,
						},
					]}
					onSubmit={saveInformation}
				/>
			</Popup>
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					xs={12}
					display="flex"
					flexDirection="column"
					sx={{
						borderRadius: "20px",
					}}
				>
					<Grid
						item
						container
						xs={12}
						flexDirection="row"
						justifyContent="space-between"
					>
						<Grid
							item
							xs={3.9}
							overflow='auto'
							mt={2}
						>
							<Grid
								item
								width="100%"
							>
								{project.logoUrl && (
									<img
										src={project.logoUrl}
										alt=""
										style={{
											maxHeight: "100px",
											backgroundColor: "#ffffff",
											borderRadius: "10px",
											align: "center",
											padding: "10px",
										}}
									/>
								)}
								{!project.logoUrl && <ImageNotSupportedIcon color="#193256" fontSize="large" />}
							</Grid>

							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Title"
								infoKey="title"
								textSize="1.3rem"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualMultilinePopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Moto"
								infoKey="oneLiner"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualMultilinePopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Description"
								infoKey="description"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Members"
								infoKey="members"
							/>
							<ProjectInformationElement
								setPopupOpen={setTextualPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Funding source"
								infoKey="fundingSource"
							/>
							<ProjectInformationElement setPopupOpen={setTextualPopupOpen} setCurrentKey={setCurrentKey} setCurrentTitle={setCurrentTitle} project={project} title="Operational body" infoKey="operationalBody" />
							<ProjectInformationElement setPopupOpen={setTextualPopupOpen} setCurrentKey={setCurrentKey} setCurrentTitle={setCurrentTitle} project={project} title="Funding code" infoKey="fundingCode" />
							<ProjectInformationElement
								setPopupOpen={setDatepickerPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Start date"
								infoKey="startDate"
								infoType="date"
							/>
							<ProjectInformationElement
								setPopupOpen={setDatepickerPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="End date"
								infoKey="endDate"
								infoType="date"
							/>
							<ProjectInformationElement
								setPopupOpen={setNumericalPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Total budget"
								infoKey="totalBudget"
								postfix="€"
							/>
							<ProjectInformationElement
								setPopupOpen={setNumericalPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Team budget"
								infoKey="teamBudget"
								postfix="€"
							/>
							<ProjectInformationElement setPopupOpen={setTextualPopupOpen} setCurrentKey={setCurrentKey} setCurrentTitle={setCurrentTitle} project={project} title="Team role" infoKey="teamRole" />
							<ProjectInformationElement setPopupOpen={setTextualPopupOpen} setCurrentKey={setCurrentKey} setCurrentTitle={setCurrentTitle} project={project} title="External page" infoKey="externalPage" />
							<ProjectInformationElement
								setPopupOpen={setCheckboxPopupOpen}
								setCurrentKey={setCurrentKey}
								setCurrentTitle={setCurrentTitle}
								project={project}
								title="Public visilibity"
								infoKey="public"
								infoType="boolean"
							/>
							<ProjectInformationElement project={project} title="Created at" infoKey="createdAt" infoType="date" />
						</Grid>
						<Grid
							item
							xs={8}
							sx={{
								width: "100%",
							}}
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
						>
							{project && (
								<GenericMdEditor
									screen="ProjectInfo"
									element={project}
									myid={myid}
									setIsLoading={setIsLoading}
									success={success}
									error={error}
									mdHeader="Project full description"
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default memo(MemberInfo);
