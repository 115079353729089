import { useEffect } from "react";
import Popup from "../Popup.js";
import { addNonTeamAuthor } from "../../api/index.js";
import Form from "../Form.js";

const AddNonTeamMemberPopup = ({
	open,
	setOpen,
	publication,
	setIsLoading,
	error,
	success,
	fetchData,
}) => {
	const addNonTeamMemberFormContent = [
		{
			customType: "input",
			id: "email",
			type: "text",
			placeholder: "e-mail",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Add author",
		},
	];
	useEffect(() => {
		// console.log("hello there");
	}, []);

	const addNonTeamMemberSubmitHandler = async (values) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await addNonTeamAuthor(publication._id, values.email);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setOpen(false);
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	return (
		<Popup
			width="800px"
			open={open}
			title="Add non-team member as author"
			onClose={() => setOpen(false)}
		>
			<Form
				content={addNonTeamMemberFormContent}
				validationSchema="addNonTeamMemberToPublicationSchema"
				onSubmit={addNonTeamMemberSubmitHandler}
			/>
		</Popup>
	);
};

export default AddNonTeamMemberPopup;
