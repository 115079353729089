/* eslint-disable arrow-body-style */
/* eslint-disable semi */
/* eslint-disable no-continue */
/* eslint-disable no-else-return */
import { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { PublicationsList } from "./PublicationsList.js";
import { PublicationsBlock } from "./PublicationsBlock.js";
import { fullPublicationTypes, publicationStatesOrdered } from "../utils/publication-constants.js";
import PropTypes from 'prop-types';

export const PublicationsComponent = ({
	filteredPublications,
	setPublicationToDelete,
	setSelectedPublication,
	setDeletePublicationPopupOpen,
	showTeam,
	publicPreview,
}) => {
	const [publicationsInProgress, setPublicationsInProgress] = useState([]);
	const [publicationsPublished, setPublicationsPublished] = useState([]);

	useEffect(() => {
		// console.log(filteredPublications);
		setPublicationsInProgress(filteredPublications.filter((pub) => pub.state.state !== "Published").sort((a, b) => {
			if (publicationStatesOrdered[a.state.state] > publicationStatesOrdered[b.state.state]) {
				return -1;
			} else if (publicationStatesOrdered[a.state.state] < publicationStatesOrdered[b.state.state]) {
				return 1;
			}

			return 0;
		}));
		let publishedAll = filteredPublications.filter((pub) => pub.state.state === "Published");
		// sort published
		publishedAll = publishedAll.sort((a, b) => {
			if (a.publicationDate > b.publicationDate) {
				return -1;
			} else if (a.publicationDate < b.publicationDate) {
				return 1;
			}

			return 0;
		});
		const publishedPerYear = [];
		const currentYear = new Date().getFullYear();
		for (let year = currentYear; year >= 2000; year--) {
			const pubsOfYear = publishedAll.filter((pub) => new Date(pub.publicationDate).getFullYear() === year);
			if (pubsOfYear.length === 0) {
				continue;
			}

			const publishedCategories = [];
			for (const type of fullPublicationTypes) {
				const pubsOfType = pubsOfYear.filter((pub) => pub.publicationType === type.value);
				if (pubsOfType.length === 0) {
					continue;
				}

				publishedCategories.push({
					type,
					pubsOfType,
				});
			}

			publishedPerYear.push({
				year,
				publications: publishedCategories,
			});
		}

		setPublicationsPublished(publishedPerYear);
	}, [filteredPublications]);

	return (
		<>
			{
				publicPreview === "false" && (
					<>
						<Grid
							container
							direction="row"
							alignItems="center"
							sx={{ mt: 2, borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
							backgroundColor="third.main"
						>
							<Typography
								sx={{
									mx: 3,
									fontSize: "1.4rem",
									fontWeight: "bold",
									color: "green.main",
									backgroundColor: "white.main",
									py: 1,
									px: 2,
									ml: 4,
								}}
							>
								{"In progress"}
							</Typography>
							<Typography
								sx={{
									mr: 0.7,
									color: "primary.main",
								}}
							>
								{"Total -"}
							</Typography>
							<Typography
								sx={{
									mr: 2,
									fontWeight: "bold",
									color: "primary.main",
								}}
							>
								{publicationsInProgress.length}
							</Typography>
						</Grid>
						<Box
							sx={{
								p: 2,
								backgroundColor: "secondary.main",
								borderBottomLeftRadius: "20px",
								borderBottomRightRadius: "20px",
							}}
						>
							<PublicationsList
								filteredPublications={publicationsInProgress}
								setPublicationToDelete={setPublicationToDelete}
								setSelectedPublication={setSelectedPublication}
								setDeletePublicationPopupOpen={setDeletePublicationPopupOpen}
								showTeam={showTeam}
								publicPreview={publicPreview}
							/>
						</Box>
						<Grid
							container
							item
							width="100%"
							pl={3}
							pr={1}
							mt={3}
							display="flex"
							borderRadius="20px"
							alignItems="center"
							minHeight="50px"
							justifyContent="space-between"
						>
							<Grid
								item
							>
								<Typography variant="h5" color="#ffffff">
									{"Published"}
								</Typography>
							</Grid>
						</Grid>
						{
							publicationsPublished.length === 0 && (
								<Typography color="gray" pl={4} pb={2} pt={2}>
									{"No publications"}
								</Typography>
							)
						}
					</>
				)
			}
			{
				publicationsPublished.map((item, index) => {
					return (
						<Box key={`accordion-${index}`} sx={{ mt: 1 }}>
							<PublicationsBlock
								item={item}
								yearIndex={index}
								setPublicationToDelete={setPublicationToDelete}
								setSelectedPublication={setSelectedPublication}
								setDeletePublicationPopupOpen={setDeletePublicationPopupOpen}
								showTeam={showTeam}
								publicPreview={publicPreview}
							/>
						</Box>
					)
				})
			}
		</>
	)
};

PublicationsComponent.propTypes = {
	filteredPublications: PropTypes.array.isRequired,
	setPublicationToDelete: PropTypes.func,
	setSelectedPublication: PropTypes.func,
	setDeletePublicationPopupOpen: PropTypes.func,
	showTeam: PropTypes.oneOf(['true', 'false']).isRequired,
	publicPreview: PropTypes.oneOf(['true', 'false']).isRequired,
};
