import { Grid, Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useCallback, useEffect, useState } from "react";

import Popup from "../components/Popup.js";
import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";
import { getTeamsData, createTeam, removeTeam } from "../api/index.js";
import { Team } from "../components/Team.js";
import AreYouSurePopup from "../components/Popups/AreYouSurePopup.js";

const useStyles = makeStyles((theme) => ({
	chipBackground: {
		backgroundColor: theme.palette.light.main,
		"& .MuiChip-label": {
			color: "#ffffff",
		},
		fontSize: "1.2rem",
		"&:hover": {
			backgroundColor: theme.palette.light.dark,
		},
	},
	titleBox: {
		backgroundColor: theme.palette.secondary.main,
		color: "inherit",
		width: "100%",
		padding: "0.2rem 0.5rem",
		marginBottom: "0.5rem",
		display: "flex",
		minHeight: "60px",
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: theme.spacing(2),
	},
}));

const Groups = () => {
	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [teams, setTeams] = useState([]);
	const [filteredTeams, setFilteredTeams] = useState(teams);
	const [popupOpen, setPopupOpen] = useState(false);
	const classes = useStyles();

	const [deleteTeam, setDeleteTeam] = useState({ id: null, name: null });
	const [deleteTeamPopupOpen, setDeleteTeamPopupOpen] = useState(false);

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, teams: tms } = await getTeamsData();
				console.log(tms);
				if (scs) {
					setTeams(tms);
				} else {
					error();
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error],
	);

	const submitHandler = async (values) => {
		setIsLoading(true);

		try {
			// eslint-disable-next-line max-len
			const { success: successCode, message } = await createTeam(values.name, values.description, values.website, values.logo, values.discordURL);

			if (successCode) {
				success(message);
				setPopupOpen(false);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const onDelete = async () => {
		setIsLoading(true);

		const { success: successCode, message } = await removeTeam(deleteTeam?.id);
		if (successCode) {
			success("Team deleted!");
		} else {
			console.log(message);
			error();
		}

		await fetchData();
		setDeleteTeamPopupOpen(false);
		setIsLoading(false);
		setDeleteTeam({ id: null, name: null });
	};

	const onDecline = () => {
		setDeleteTeamPopupOpen(false);
		setDeleteTeam({ id: null, name: null });
	};

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFilteredTeams(teams.filter((us) => isFuzzyMatch(us.name, searchFilter)));
	}, [searchFilter, teams]);

	const formContent = [
		{
			customType: "input",
			id: "name",
			type: "text",
			placeholder: "Name",
		},
		{
			customType: "input",
			id: "description",
			type: "text",
			placeholder: "Description",
			multiline: true,
			minRows: 3,
		},
		{
			customType: "input",
			id: "website",
			type: "text",
			placeholder: "Website URL",
		},
		{
			customType: "input",
			id: "logo",
			type: "text",
			placeholder: "Logo URL",
		},
		{
			customType: "input",
			id: "discordURL",
			type: "text",
			placeholder: "Discord Webhook URL",
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Create",
		},
	];

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					className={classes.titleBox}
				>
					<Grid
						item
						xs={7}
						ml={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						<Typography variant="h6" color="#ffffff">
							{"Your teams"}
						</Typography>
						<Chip
							key={-1}
							label="+"
							sx={{
								mr: 0.5,
								ml: 1,
							}}
							className={classes.chipBackground}
							onClick={() => setPopupOpen(true)}
						/>
					</Grid>
					<Grid
						item
						xs={3}
						pr={3}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						height="100%"
					>
						<Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
					</Grid>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="center"
				>
					{filteredTeams.map((team, index) => (
						<Team
							key={`team-${index}`}
							team={team}
							index={index}
							setDeleteTeam={setDeleteTeam}
							setDeleteTeamPopupOpen={setDeleteTeamPopupOpen}
						/>
					))}
				</Grid>
			</Grid>
			<Popup
				width="800px"
				open={popupOpen}
				title="Create team"
				onClose={() => setPopupOpen(false)}
			>
				<Form content={formContent} validationSchema="newTeamSchema" onSubmit={submitHandler} />
			</Popup>
			<AreYouSurePopup
				open={deleteTeamPopupOpen}
				title="Delete team?"
				content={`Are you sure you want to delete the team "${deleteTeam?.name}"?`}
				onDecline={onDecline}
				onAccept={onDelete}
			/>
		</>
	);
};

export default memo(Groups);
