import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Chip,
	Link,
	IconButton,
	Divider,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CommentIcon from '@mui/icons-material/Comment';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SaveIcon from '@mui/icons-material/Save';

import DatePicker from "../DatePicker.js";
import { jwt, dayjs } from "../../utils/index.js";
import { deleteReviewFromPublication } from "../../api/index.js";
import AreYouSurePopup from "../Popups/AreYouSurePopup.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	reviewerChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: theme.palette.primary.main,
		},
	},
	icons: {
		color: theme.palette.third.main,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
	commentIcon: {
		color: theme.palette.green.main,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.green.dark,
		},
	},
	link: {
		color: theme.palette.third.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const Reviews = ({
	myid,
	publication,
	classes,
	handleClickAddReview,
	lockReviewingProcessHandler,
	setDeadline,
	changeReviewDeadlineHandler,
	removeReviewerSubmitHandler,
	setReview,
	handleClickAddDocumentForReview,
	setAddReviewersCommentsPopupOpen,
	handleClickAddReviewer,
	setAddReviewPopupOpen,
	setAddRevisedDocumentPopupOpen,
	setVersionCommentPopupOpen,
	setComment,
	setAddResponseToReviewersPopupOpen,
	handleClickExternalReviewLock,
	success,
	error,
	setIsLoading,
	fetchData,
}) => {
	const [reviewToDelete, setReviewToDelete] = useState({});
	const [deleteReviewPopupOpen, setDeleteReviewPopupOpen] = useState(false);
	const newClasses = useStyles();

	useEffect(() => {
		// console.log("Reviews mounted!");
	}, []);

	const deleteReviewFromPublicationHandler = async () => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteReviewFromPublication(publication._id, reviewToDelete._id);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
		setReviewToDelete({});
		setDeleteReviewPopupOpen(false);
	};

	const declineDeleteReviewFromPublicationHandler = () => {
		setReviewToDelete({});
		setDeleteReviewPopupOpen(false);
	};

	return (
		<Grid
			container
			item
			width="100%"
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			sx={{
				borderRadius: "20px",
			}}
			className={classes.publicationInfoCard}
		>
			<AreYouSurePopup
				open={deleteReviewPopupOpen}
				title="Delete Review?"
				content={`Are you sure you want to delete the "${reviewToDelete.type}" review?`}
				onDecline={declineDeleteReviewFromPublicationHandler}
				onAccept={deleteReviewFromPublicationHandler}
			/>
			<Grid
				container
				item
				width="100%"
				p={1}
				pl={2}
				mb={1}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{"Reviews"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Chip
						key={-1}
						label="Add reviewing process"
						className={classes.actionChip}
						sx={{
							mr: 0.5,
						}}
						aria-controls={open ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						onClick={handleClickAddReview}
					/>
				</Grid>
			</Grid>
			{publication.finalReviews.length > 0 && (
				<Timeline
					sx={{
						[`& .${timelineItemClasses.root}:before`]: {
							flex: 0,
							padding: 0,
						},
					}}
				>
					{publication.finalReviews.map((e, ind) => (
						<TimelineItem key={ind}>
							<TimelineSeparator>
								<TimelineDot
									color="white"
								>
									<RateReviewIcon color="light" />
								</TimelineDot>
								{ind !== publication.finalReviews.length - 1 && <TimelineConnector />}
							</TimelineSeparator>
							<TimelineContent>
								<Grid
									container
									mt={1}
									width="100%"
								>
									<Typography
										fontSize="1.2rem"
										color="#ffffff"
										sx={{
											mr: 1,
										}}
									>
										{`${e.type.charAt(0).toUpperCase() + e.type.slice(1)} review`}
									</Typography>
									{
										e.locked ? (
											<LockIcon
												sx={{
													pt: 0.5,
												}}
												className={newClasses.icons}
												onClick={() => {
													lockReviewingProcessHandler(e._id);
												}}
											/>
										) : (
											<LockOpenIcon
												sx={{
													pt: 0.5,
												}}
												className={newClasses.icons}
												onClick={() => {
													lockReviewingProcessHandler(e._id);
												}}
											/>
										)

									}
									<DeleteForeverIcon
										sx={{
											pt: 0.5,
										}}
										className={newClasses.icons}
										onClick={() => {
											// deleteReviewFromPublicationHandler(e._id);
											console.log(e);
											setReviewToDelete(e);
											setDeleteReviewPopupOpen(true);
										}}
									/>

								</Grid>
								<Typography color="#ffffff" fontSize="0.7rem">
									{e.timestamp.replace("T", " ").replace("Z", "")}
								</Typography>
								<Grid
									container
									flexDirection="row"
									style={{
										alignItems: "center",
									}}
								>
									<Typography fontSize="0.9rem" color="#ffffff">
										{"Deadline:"}
									</Typography>
									<Typography fontSize="0.9rem" color="#ffffff">
										&nbsp;
									</Typography>
									<DatePicker
										type="datetime"
										width="60%"
										background="primary"
										color="third"
										label=""
										value={e.deadline ? new Date(e.deadline) : null}
										onChange={(value) => {
											setDeadline(dayjs(value).set('hour', 20).toISOString());
										}}
									/>
									<IconButton
										edge="end"
										onClick={() => changeReviewDeadlineHandler(e._id)}
									>
										<SaveIcon className={newClasses.icons} />
									</IconButton>
								</Grid>
								<Grid
									container
									mt={1}
									flexDirection="column"
									alignContent="flex-start"
								>
									{/* Document for review handling */}
									<Grid
										container
										sx={{
											mt: 1,
										}}
										flexDirection="row"
										alignContent="flex-start"
									>
										{
											e.documentForReviewSavedName ? (
												<>
													<Typography
														fontSize="0.9rem"
														color="green.main"
														sx={{
															mr: 1,
															pt: 0.5,
														}}
													>
														{e.type === "internal" ? "Document for review: " : "Document for revisions: "}
													</Typography>
													<Link
														fontSize="0.9rem"
														sx={{
															mr: 1,
															pt: 0.5,
														}}
														href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.documentForReviewFolder}/${e.documentForReviewSavedName}?token=${jwt.getToken()}`}
														target="_blank"
														className={newClasses.link}
													>
														{`Version ${e.documentForReviewVersion}`}
													</Link>
												</>
											) : (
												<Typography
													fontSize="0.9rem"
													color="gray"
													sx={{
														mr: 1,
														pt: 0.5,
													}}
												>
													{e.type === "internal" ? "No document for review selected" : "No document for revisions selected"}
												</Typography>
											)
										}
										{
											!e.locked && e.reviewersSubmitted.length === 0 && (
												<DriveFileRenameOutlineIcon
													sx={{
														pt: 0.5,
													}}
													className={newClasses.icons}
													onClick={(event) => {
														setReview(e);
														handleClickAddDocumentForReview(event);
													}}
												/>
											)
										}
									</Grid>

									{/* Reviewers comments handling */}
									{
										e.type === "external" && (
											<Grid
												container
												mt={1}
												flexDirection="row"
												alignContent="flex-start"
											>
												<Typography
													sx={{
														mt: 0.5,
														mr: 1,
													}}
													fontSize="0.9rem"
													color={e.externalReviewersCommentsSavedName ? "green.main" : "gray"}
												>
													{e.externalReviewersCommentsSavedName ? "Reviewers' comments: " : "No reviewers' comments"}
												</Typography>
												{
													e.externalReviewersCommentsSavedName && (
														<Link
															fontSize="0.9rem"
															href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.externalReviewersCommentsFolder}/${e.externalReviewersCommentsSavedName}?token=${jwt.getToken()}`}
															target="_blank"
															className={newClasses.link}
															sx={{
																mt: 0.5,
																mr: 1,
															}}
														>
															{`${e.externalReviewersCommentsOriginalName.slice(0, 30)}
												${e.externalReviewersCommentsOriginalName.length > 30 ? "..." : ""}`}
														</Link>
													)
												}
												{
													!e.locked && (
														<DriveFileRenameOutlineIcon
															sx={{
																pt: 0.5,
															}}
															className={newClasses.icons}
															onClick={() => {
																setReview(e);
																setAddReviewersCommentsPopupOpen(true);
															}}
														/>
													)
												}
											</Grid>

										)
									}

									{/* Reviewers handling */}
									<Grid
										container
										sx={{
											mt: 1,
										}}
										flexDirection="row"
										alignContent="flex-start"
									>
										<Typography
											sx={{
												mt: 0.5,
												mr: 1,
											}}
											fontSize="0.9rem"
											color={e.finalReviewers.length === 0 ? "gray" : "green.main"}
										>
											{
												e.finalReviewers.length === 0
													? `No ${e.type === "internal" ? "reviewers" : "revisions assignees"} added yet`
													: (e.type === "internal" ? "Reviewers:" : "Revisions assignees (ordered):")
											}
										</Typography>
										{!e.locked && (
											<ControlPointIcon
												sx={{
													pt: 0.5,
												}}
												className={newClasses.icons}
												onClick={(event) => {
													setReview(e);
													handleClickAddReviewer(event);
												}}
											/>
										)}
									</Grid>
									{
										e.finalReviewers.map((r) => (
											<Grid
												key={r._id}
												sx={{
													mt: 0.5,
												}}
												display="flex"
												alignItems="center"
											>
												<Chip
													label={r.fullname}
													className={newClasses.reviewerChip}
													sx={{
														mr: 0.5,
														mt: 0.5,
													}}
													disabled={e.locked}
													onDelete={() => {
														removeReviewerSubmitHandler(e._id, r._id);
													}}
												/>
												{
													r._id === myid && !(r._id in e.reviewingAssociations) && (
														<Chip
															label={`Submit your ${e.type === "internal" ? "review" : "revisions"}`}
															className={classes.actionChip}
															sx={{
																mr: 1,
																mt: 0.5,
															}}
															onClick={() => {
																setReview(e);
																if (e.type === "internal") {
																	setAddReviewPopupOpen(true);
																} else {
																	setAddRevisedDocumentPopupOpen(true);
																}
															}}
														/>
													)
												}
												{
													r._id !== myid && !(r._id in e.reviewingAssociations) && (
														<Chip
															label="No review submitted yet"
															color="primary"
															variant="outlined"
															sx={{
																mr: 1,
															}}
														/>
													)
												}
												{
													(r._id in e.reviewingAssociations) && (
														<>
															{e.reviewingAssociations[r._id].savedName && (
																<Link
																	sx={{
																		// mt: 1,
																		mx: 1,
																	}}
																	fontSize="0.9rem"
																	href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.reviewingAssociations[r._id].folder}/${e.reviewingAssociations[r._id].savedName}?token=${jwt.getToken()}`}
																	target="_blank"
																	className={newClasses.link}
																>
																	{`${e.reviewingAssociations[r._id].originalName.slice(0, 15)}
												${e.reviewingAssociations[r._id].originalName.length > 15 ? "..." : ""}`}
																</Link>
															)}
															{e.reviewingAssociations[r._id].comments && (
																<CommentIcon
																	sx={{
																		mr: 1,
																		pt: 0.5,
																	}}
																	className={newClasses.commentIcon}
																	onClick={() => {
																		setComment(e.reviewingAssociations[r._id].comments);
																		setVersionCommentPopupOpen(true);
																	}}
																/>
															)}
															{
																!e.locked && (
																	<DriveFileRenameOutlineIcon
																		sx={{
																			pt: 0.5,
																		}}
																		className={newClasses.icons}
																		onClick={() => {
																			setReview(e);
																			if (e.type === "internal") {
																				setAddReviewPopupOpen(true);
																			} else {
																				setAddRevisedDocumentPopupOpen(true);
																			}
																		}}
																	/>
																)
															}
														</>
													)
												}
											</Grid>
										))
									}

									{/* Response to reviewers handling */}
									{
										e.type === "external" && (
											<Grid
												container
												mt={1}
												flexDirection="row"
												alignContent="flex-start"
											>
												<Typography
													sx={{
														mt: 0.5,
														mr: 1,
													}}
													fontSize="0.9rem"
													color={e.responseToReviewersSavedName ? "green.main" : "gray"}
												>
													{e.responseToReviewersSavedName ? "Response to reviewers: " : "No response to reviewers"}
												</Typography>
												{
													e.responseToReviewersSavedName && (
														<Link
															fontSize="0.9rem"
															href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.responseToReviewersFolder}/${e.responseToReviewersSavedName}?token=${jwt.getToken()}`}
															target="_blank"
															className={newClasses.link}
															sx={{
																mt: 0.5,
																mr: 1,
															}}
														>
															{`${e.responseToReviewersOriginalName.slice(0, 18)}
												${e.responseToReviewersOriginalName.length > 18 ? "..." : ""}`}
														</Link>
													)
												}
												{
													!e.locked && (
														<DriveFileRenameOutlineIcon
															sx={{
																pt: 0.5,
															}}
															className={newClasses.icons}
															onClick={() => {
																setReview(e);
																setAddResponseToReviewersPopupOpen(true);
															}}
														/>
													)
												}
											</Grid>

										)
									}

									{/* External reviewing process handling - final step */}
									{
										e.type === "external" && !e.locked && (e.finalReviewers.length === e.reviewersSubmitted.length && e.finalReviewers.length > 0) && (
											<Grid
												container
												mt={1}
												flexDirection="row"
												alignContent="flex-start"
											>
												<Chip
													key={-1}
													label="Lock and change status to ''Internal Review'"
													color="warning"
													sx={{
														mr: 0.5,
													}}
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={open ? "true" : undefined}
													onClick={() => {
														handleClickExternalReviewLock(e._id);
													}}
												/>
											</Grid>
										)
									}

									{/* Revised document handling */}
									{
										e.type === "internal" && (
											<Grid
												container
												mt={1}
												flexDirection="row"
												alignContent="flex-start"
											>
												<Typography
													sx={{
														mt: 0.5,
														mr: 1,
													}}
													fontSize="0.9rem"
													color={e.reviewedDocumentSavedName ? "green.main" : "gray"}
												>
													{e.reviewedDocumentSavedName ? "Revised document: " : "Revised document not uploaded yet"}
												</Typography>
												{
													e.reviewedDocumentSavedName && (
														<Link
															fontSize="0.9rem"
															href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${e.reviewedDocumentFolder}/${e.reviewedDocumentSavedName}?token=${jwt.getToken()}`}
															target="_blank"
															className={newClasses.link}
															sx={{
																mt: 0.5,
																mr: 1,
															}}
														>
															{`Version ${e.reviewedDocumentVersion}`}
														</Link>
													)
												}
												{
													!e.locked && (
														(e.finalReviewers.length === e.reviewersSubmitted.length && e.finalReviewers.length > 0) ? (
															<DriveFileRenameOutlineIcon
																sx={{
																	pt: 0.5,
																}}
																className={newClasses.icons}
																onClick={() => {
																	setReview(e);
																	setAddRevisedDocumentPopupOpen(true);
																}}
															/>
														) : (
															<Typography
																sx={{
																	mt: 0.5,
																	mr: 1,
																}}
																fontSize="0.9rem"
																color="gray"
															>
																{`- All ${e.type === "internal" ? "reviewers" : "revisions assignees"} must submit their ${e.type === "internal" ? "reviews" : "revisions"} first`}
															</Typography>
														)
													)
												}
											</Grid>
										)
									}

								</Grid>
								{
									ind !== publication.finalReviews.length - 1 && (
										<Divider
											sx={{
												mt: 3,
												height: 0.001,
											}}
										/>
									)
								}

							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			)}
			{
				publication.finalReviews.length === 0 && (
					<Typography color="gray" pl={2} pb={1}>
						{"No reviews"}
					</Typography>
				)
			}
		</Grid>
	);
};

export default Reviews;
