import { useEffect } from "react";
import {
	Grid,
	Typography,
} from "@mui/material";
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
} from "@mui/lab";
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { getEventModule } from "../../utils/events-formatting.js";

const Events = ({
	publication,
	initialEventsSize,
}) => {
	useEffect(() => {
		// console.log("Events mounted!");
	}, []);
	return (
		<>
			<Grid
				container
				item
				width="100%"
				p={1}
				pl={2}
				mb={1}
				// mt={1}
				display="flex"
				minHeight="30px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Typography variant="h6" color="secondary">
					{"Events"}
				</Typography>
			</Grid>
			<Timeline
				sx={{
					[`& .${timelineItemClasses.root}:before`]: {
						flex: 0,
						padding: 0,
						margin: 0,
					},
				}}
			// className={classes.timeline}
			>
				{publication.events.map((e, ind) => (
					<TimelineItem key={ind}>
						<TimelineSeparator>
							<TimelineDot
								color="white"
							>
								{
									getEventModule(e)
								}
							</TimelineDot>
							{ind !== publication.events.length - 1 && <TimelineConnector />}
						</TimelineSeparator>
						<TimelineContent>
							<Typography
								fontSize="0.8rem"
								color={ind === 0 ? "white.main" : "third.main"}
								pt={1}
							>
								{
									e.message
										.replace(`for "${publication.title}"`, "")
										.replace(`in publication "${publication.title}"`, "")
										.replace(`to "${publication.title}"`, "")
										.replace(`from publication "${publication.title}"`, "")
										.replace(`: "${publication.title}"`, "")
								}
							</Typography>
							<Typography
								color={ind === 0 ? "white.main" : "third.main"}
								fontSize="0.6rem"
							>
								{e.createdAt.replace("T", " ").replace("Z", "")}
							</Typography>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
			{initialEventsSize > 20 && (
				<Typography color="gray" ml={4} mb={1} fontSize="0.8rem">
					{`... and ${initialEventsSize - 20} more`}
				</Typography>
			)}
		</>
	);
};

export default Events;
