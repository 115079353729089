import { useEffect } from "react";
import {
	Grid,
	Typography,
	List,
	IconButton,
	ListItem,
	ListItemText,
	Link,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { jwt } from "../../utils/index.js";
import { calculateTimestampToShow } from "../../utils/timestamps.js";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.third.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
	icons: {
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const AssetsPreview = ({
	items,
	editItemClicked,
	deleteItemClicked,
	success,
}) => {
	const classes = useStyles();
	useEffect(() => {
		// console.log("Assets Preview mounted!");
	}, []);
	return (
		<List dense>
			{items.map((item, index) => (
				<ListItem
					key={index}
					secondaryAction={(
						<>
							<IconButton
								edge="end"
								title="Copy link"
								onClick={() => {
									let link = "";
									// eslint-disable-next-line unicorn/prefer-ternary
									if (item.type === "link") {
										link = item.link;
									} else {
										link = `${REACT_APP_MAIN_SERVER_URL}/uploads/${item.folder}/${item.savedName}?token=${jwt.getToken()}`;
									}

									navigator.clipboard.writeText(link);
									success("Copied link to clipboard!");
								}}
							>
								<ContentCopyIcon className={classes.icons} />
							</IconButton>
							<IconButton
								edge="end"
								title="Edit title"
								onClick={() => {
									editItemClicked(item);
								}}
							>
								<DriveFileRenameOutlineIcon className={classes.icons} />
							</IconButton>
							<IconButton
								edge="end"
								title="Delete item"
								onClick={() => {
									deleteItemClicked(item);
								}}
							>
								<DeleteIcon className={classes.icons} />
							</IconButton>
						</>
					)}
				>
					<ListItemText
						primary={(
							<Grid
								container
								style={{
									alignItems: "baseline",
									flexDirection: "column",
								}}
							>
								<Typography fontSize="0.9rem" color="#fff">
									{item.title}
								</Typography>
								{
									item.type === "link"
										? (
											<Link href={item.link} target="_blank" className={classes.link}>
												{`${item.link.slice(0, 18)}
												${item.link.length > 18 ? "..." : ""}`}
											</Link>
										) : (
											<Link href={`${REACT_APP_MAIN_SERVER_URL}/uploads/${item.folder}/${item.savedName}?token=${jwt.getToken()}`} target="_blank" className={classes.link}>
												{`${item.originalName.slice(0, 18)}
												${item.originalName.length > 18 ? "..." : ""}`}
											</Link>
										)
								}
								<Typography color="gray" fontSize="0.7rem">
									{
										item.updatedAt === undefined
											? `${calculateTimestampToShow(item.timestamp)}`
											: `${calculateTimestampToShow(item.updatedAt)}`
									}
								</Typography>
							</Grid>
						)}
					/>
				</ListItem>
			))}
			{
				items.length === 0 && (
					<Typography color="gray" pl={2} pb={1}>
						{"Nothing to show."}
					</Typography>
				)
			}
		</List>
	);
};

export default AssetsPreview;
