import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Box,
	Tab,
	Tabs,
	Badge,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import CustomTabPanel from "../Common/TabPanel.js";
import Preparation from "./Preparation.js";
import Publication from "./Publication.js";

const useStyles = makeStyles(() => ({
	tabs: {
		color: "white !important",
	},
	tabsIndicator: {
		backgroundColor: "white",
		height: "4px",
	},
}));

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Metadata = ({
	setIsLoading,
	isLoading,
	classes,
	publication,
	setAbstractShowDialog,
	setChangeAbstractPopupOpen,
	setChangeKeywordsPopupOpen,
	setChangePublishedPopupOpen,
	success,
	error,
	setAddPreprintPopupOpen,
	clearPreprint,
	setAddCameraReadyPopupOpen,
	clearCameraReady,
	typeChangeHandle,
	setChangeTargetPopupOpen,
	changeDeadlineHandler,
	deadline,
	setDeadline,
	fetchData,
}) => {
	const [tabValue, setTabValue] = useState(publication.state.state === "Published" ? 1 : 0); // current tab value
	const newClasses = useStyles();

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		// console.log("Component mounted!");
	}, []);
	return (
		<Grid
			container
			item
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			borderRadius="20px"
			sx={{
				minHeight: "450px",
			}}
			className={classes.publicationInfoCard}
		>
			<Grid
				container
				item
				width="100%"
				p={1}
				pl={2}
				mb={1}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary">
						{"Metadata"}
					</Typography>
				</Grid>
			</Grid>
			<Grid sx={{ width: '100%' }}>
				<Box pb={0.1} sx={{ borderBottom: 1, borderColor: 'third.main' }}>
					<Tabs
						centered
						value={tabValue}
						aria-label="assets-tabs"
						classes={{ indicator: newClasses.tabsIndicator }}
						onChange={handleChangeTab}
					>
						{publication.metaType === "publication" && (
							<Tab
								label={(
									<Badge color="white">
										{"Preparation"}
									</Badge>
								)}
								className={newClasses.tabs}
								{...a11yProps(0)}

							/>
						)}
						<Tab
							label={(
								<Badge color="white">
									{"Publication"}
								</Badge>
							)}
							className={newClasses.tabs}
							{...a11yProps(publication.metatype === "publication" ? 1 : 0)}
						/>
					</Tabs>
				</Box>
				{publication.metaType === "publication" && (
					<CustomTabPanel value={tabValue} index={0}>
						<Preparation
							classes={classes}
							publication={publication}
							typeChangeHandle={typeChangeHandle}
							setChangeTargetPopupOpen={setChangeTargetPopupOpen}
							changeDeadlineHandler={changeDeadlineHandler}
							deadline={deadline}
							setDeadline={setDeadline}
						/>
					</CustomTabPanel>
				)}
				<CustomTabPanel value={tabValue} index={publication.metaType === "publication" ? 1 : 0}>
					<Publication
						setIsLoading={setIsLoading}
						isLoading={isLoading}
						classes={classes}
						publication={publication}
						setAbstractShowDialog={setAbstractShowDialog}
						setChangeAbstractPopupOpen={setChangeAbstractPopupOpen}
						setChangeKeywordsPopupOpen={setChangeKeywordsPopupOpen}
						setChangePublishedPopupOpen={setChangePublishedPopupOpen}
						success={success}
						error={error}
						setAddPreprintPopupOpen={setAddPreprintPopupOpen}
						clearPreprint={clearPreprint}
						setAddCameraReadyPopupOpen={setAddCameraReadyPopupOpen}
						clearCameraReady={clearCameraReady}
						fetchData={fetchData}
					/>
				</CustomTabPanel>
			</Grid>
		</Grid>
	);
};

export default Metadata;
