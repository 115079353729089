import Popup from "../Popup.js";
import Form from "../Form.js";
import { addTagToTeam } from "../../api/index.js";

const AddTagPopup = ({
    open,
    setOpen,
    teamId,
    setIsLoading,
    fetchData,
    success,
    error
}) => {

    const addAssignmentFormContent = [
        {
            customType: "input",
            id: "text",
            type: "text",
            placeholder: "Type a tag",
        },
        {
            customType: "button",
            id: "submit",
            type: "submit",
            text: "Add tag",
        },
    ];

    const addTagSubmitHandler = async (values) => {
        setIsLoading(true);
        try {
            const { success: successCode, message } = await addTagToTeam(teamId, values.text);
            if (successCode) {
                success(message);
            } else {
                error(message);
            }

            setOpen(false);
        } catch { /*empty*/ }

        await fetchData();
        setIsLoading(false);
        setOpen(false);
    }

    return (
        <Popup
            width="800px"
            open={open}
            title="Add tag"
            onClose={() => {
                setOpen(false);
            }}
        >
            <Form
                content={addAssignmentFormContent}
                onSubmit={addTagSubmitHandler}
            />
        </Popup>
    );
};

export default AddTagPopup;
