import { React } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    card: {
        justifyContent: 'center',
        display: 'flex',
        minWidth: '300px',
        height: '50px',
        borderRadius: 12,
    },
    title: {
        fontSize: 15,
        fontWeight: 500,
        color: '#555',
        display: 'flex',
        alignItems: 'center',
    },
    value: {
        fontSize: 15,
        fontWeight: 700,
        color: '#333',
    },
});

const MetricCard = ({ title, value }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card} sx={{ m: 1, minWidth: 130 }}>
            <CardContent>
                <Grid container direction="row" spacing={1}>
                    {(value === 0 || value === '0.00') ? (
                        <>
                            <Grid item>
                                <Typography className={classes.title} gutterBottom>
                                    No publications
                                </Typography>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item>
                                <Typography className={classes.title} gutterBottom>
                                    {title}:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.value}>
                                    {value}
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MetricCard;
