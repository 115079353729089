/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Chip,
	List,
	ListItem,
	IconButton,
	ListItemAvatar,
	ListItemText,
	Avatar,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { calculateTimestampToShow } from "../../utils/timestamps.js";
import { getAllTasks, deleteTasksFromProject, updateTaskInProject } from "../../utils/cycloptAPI.js";
import {
	deleteAssignment,
	toggleAssignment,
	updatePublication,
	editPublicationAssignment,
	getPublicationAssignments,
} from "../../api/index.js";
import { AreYouSurePopup, AddAssignmentPopup } from "../Popups/index.js";
import EditAssignmentPopup from "../Popups/EditAssignmentPopup.js";
import { formatDate } from "../../utils/timestamps.js";

const useStyles = makeStyles((theme) => ({
	icons: {
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const Assignments = ({
	publication,
	setPublication,
	allPublicationAuthorsAndMembers,
	classes,
	success,
	error,
	setIsLoading,
	fetchData,
}) => {
	// const [cycloptTasks, setCycloptTasks] = useState([]);
	const [pubAssignments, setPubAssignments] = useState([]);
	const [addAssignmentPopupOpen, setAddAssignmentPopupOpen] = useState(false);
	const [editAssignmentPopupOpen, setEditAssignmentPopupOpen] = useState(false);
	const [assignmentToEdit, setAssignmentToEdit] = useState(null);
	const [assignmentToDelete, setAssignmentToDelete] = useState(null);
	const [deleteAssignmentPopupOpen, setDeleteAssignmentPopupOpen] = useState(false);
	const newClasses = useStyles();

	const deleteAssignmentHandler = async (id) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await deleteAssignment(publication._id, id);
			// console.log(id);
			// console.log(publication.cycloptProject.links);
			const cycloptTaskLink = publication.cycloptProject.links.find((link) => link.publisyncId === id);
			const cycloptTaskIdIndex = publication.cycloptProject.links.findIndex((link) => link.useReqId === id);
			// console.log("Found this task!", cycloptTaskLink);
			if (cycloptTaskLink) {
				// console.log("deleting cyclopt task!", cycloptTaskLink);
				await deleteTasksFromProject(publication.cycloptToken, publication.cycloptProject.projectId, [cycloptTaskLink.integrationId]);

				// remove from links
				console.log("links before", publication.cycloptProject.links);
				const deletedLink = publication.cycloptProject.links.splice(cycloptTaskIdIndex, 1);
				// eslint-disable-next-line arrow-body-style
				publication.cycloptProject.links = publication.cycloptProject.links.map((link) => {
					return {
						integrationId: link.integrationId,
						publisyncId: link.publisyncId,
					};
				});
				console.log("links after", publication.cycloptProject.links);
				const newCycloptProject = {
					projectId: publication.cycloptProject.projectId,
					links: publication.cycloptProject.links,
				};
				const { success: successCode2, message: message2 } = await updatePublication(publication._id, publication.title, publication.doi, newCycloptProject);
			}

			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setDeleteAssignmentPopupOpen(false);
		setIsLoading(false);
	};

	const updateTaskIntegration = async (assignmentId) => {
		const updatedAssignment = pubAssignments?.find((assignment) => assignment._id === assignmentId);
		console.log("Updating assignment:", updatedAssignment);
		const updatedTask = {
			_id: updatedAssignment._id,
			title: updatedAssignment.text,
			body: updatedAssignment.text ?? "",
			status: updatedAssignment.resolved ? "Backlog" : "Delivered", // toggling status at the same time
		};

		const cycloptTaskLink = publication.cycloptProject.links.find((link) => link.publisyncId === assignmentId);

		const res = await updateTaskInProject(publication.cycloptToken, publication.cycloptProject.projectId, cycloptTaskLink.integrationId, updatedTask);
		if (res.status !== 200) throw new Error("Error while Updating task", assignmentId);
	};

	const toggleAssignmentHandler = async (assignmentId) => {
		setIsLoading(true);
		try {
			const { success: successCode, message } = await toggleAssignment(publication._id, assignmentId);
			updateTaskIntegration(assignmentId);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		await fetchData();
		setIsLoading(false);
	};

	const editAssignmentHandler = (id) => {
		setAssignmentToEdit(id);
		setEditAssignmentPopupOpen(true);
	};

	const getCycloptTasks = async () => {
		if (!publication.cycloptProject?.projectId) return;
		const res = await getAllTasks(publication.cycloptToken, publication.cycloptProject.projectId);
		// console.log("Cyclopt Tasks:", res.data.tasks);
		// console.log(publication._id);
		// if (res !== 200) return;
		const tasks = res.data.tasks;
		let updatedOne = false;
		// here we need to update the assignments in case something was changed from the cyclopt side
		for (const task of tasks) {
			const taskFound = publication.cycloptProject.links.find((link) => link.integrationId === task.id);
			// console.log("Found Link!");
			// eslint-disable-next-line no-continue
			if (!taskFound) continue; // that means that the task was created from the cyclopt side;

			const taskIsClosed = task.status === "Delivered";
			const assignment = pubAssignments?.find((ass) => ass._id === taskFound.publisyncId);
			// console.log(assignment);
			if (assignment && assignment.resolved !== taskIsClosed) {
				// update
				// console.log("Found different status, changing! new one is:", taskIsClosed);
				await editPublicationAssignment(assignment._id, taskIsClosed);
				updatedOne = true;
			}
		}

		if (updatedOne) {
			const { assignments: updatedAssignments } = await getPublicationAssignments(publication._id);
			// console.log("Updated assignments:", updatedAssignments);
			setPubAssignments(updatedAssignments);
		}
	};

	useEffect(() => {
		// console.log("Assignments mounted!");
		if (publication) setPubAssignments(publication.finalAssignments);
	}, [publication]);

	useEffect(() => {
		if (publication.cycloptToken) {
			// get tasks for this project
			getCycloptTasks();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pubAssignments]);

	return (
		<Grid
			container
			item
			width="100%"
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			sx={{
				borderRadius: "20px",
			}}
			className={classes.publicationInfoCard}
		>
			<AreYouSurePopup
				open={deleteAssignmentPopupOpen}
				title="Delete assignment?"
				content={`Are you sure you want to delete assignment "${assignmentToDelete?.text}"?`}
				onDecline={() => setDeleteAssignmentPopupOpen(false)}
				onAccept={() => deleteAssignmentHandler(assignmentToDelete._id)}
			/>
			<AddAssignmentPopup
				publication={publication}
				open={addAssignmentPopupOpen}
				setOpen={setAddAssignmentPopupOpen}
				classes={classes}
				setIsLoading={setIsLoading}
				success={success}
				error={error}
				fetchData={fetchData}
				allPublicationAuthorsAndMembers={allPublicationAuthorsAndMembers}
			/>
			<EditAssignmentPopup
				open={editAssignmentPopupOpen}
				setOpen={setEditAssignmentPopupOpen}
				assignmentId={assignmentToEdit}
				publication={publication}
				allPublicationAuthorsAndMembers={allPublicationAuthorsAndMembers}
				setIsLoading={setIsLoading}
				error={error}
				success={success}
				fetchData={fetchData}
				classes={classes}
			/>
			<Grid
				container
				item
				width="100%"
				p={1}
				pl={2}
				mb={1}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{"Assignments"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Chip
						key={-1}
						label="Add assignment"
						className={classes.actionChip}
						sx={{
							mr: 0.5,
						}}
						onClick={() => {
							setAddAssignmentPopupOpen(true);
						}}
					/>
				</Grid>
			</Grid>
			<List dense>
				{pubAssignments?.map((e, ind) => (
					<ListItem
						key={ind}
						secondaryAction={(
							<>
								<IconButton edge="end" onClick={() => toggleAssignmentHandler(e._id)}>
									{e.resolved ? (
										<CheckBoxIcon className={newClasses.icons} />
									) : (
										<CheckBoxOutlineBlankIcon className={newClasses.icons} />
									)}
								</IconButton>
								<IconButton edge="end" onClick={() => editAssignmentHandler(e._id)}>
									<DriveFileRenameOutlineIcon className={newClasses.icons} />
								</IconButton>
								<IconButton
									edge="end"
									onClick={() => {
										setAssignmentToDelete(e);
										setDeleteAssignmentPopupOpen(true);
									}}
								>
									<DeleteIcon className={newClasses.icons} />
								</IconButton>
							</>
						)}
					>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: "third.main" }}>
								<AssignmentTurnedInIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={(
								<Grid
									container
									style={{
										alignItems: "baseline",
										flexDirection: "column",
									}}
									xs={8.7}
								>
									<Typography fontSize="0.9rem" color={e.resolved ? "gray" : "green.main"}>
										{`${allPublicationAuthorsAndMembers.find((author) => author._id === e.assignee)?.fullname}`}
									</Typography>
									<Typography fontSize="0.9rem" color={e.resolved ? "gray" : "#ffffff"}>
										{e.text}
									</Typography>
									{e.deadline &&
										<Grid
											container
											flexDirection="row"
										>
											<Typography
												fontSize="0.75rem"
												color={e.resolved ? "gray" : "#ffffff"}
												sx={{
													marginRight: "0.2rem"
												}}
											>
												{"Deadline: "}
											</Typography>
											<Typography
												fontSize="0.75rem"
												color={e.resolved ? "gray" : (Date.now() > new Date(e.deadline).getTime() ? "error.main" : "green.main")}
											>
												{formatDate(e.deadline)}
											</Typography>
										</Grid>
									}
									<Typography color="gray" fontSize="0.7rem">
										{
											e.updatedAt === undefined
												? `${calculateTimestampToShow(e.timestamp)}`
												: `${calculateTimestampToShow(e.updatedAt)}`
										}
									</Typography>
								</Grid>
							)}
						/>
					</ListItem>
				))}
				{
					pubAssignments?.length === 0 && (
						<Typography color="gray" pl={2} pb={1}>
							{"No assignments"}
						</Typography>
					)
				}
			</List>
		</Grid>
	);
};

export default Assignments;
