import { Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Spinner from "../components/Spinner.js";
import { useSnackbar } from "../utils/index.js";
import { getPublicTeamMembers } from "../api/index.js";
import { Member } from "../components/Member.js";

const PublicTeamMembers = (props) => {
	const { error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [members, setMembers] = useState([]);
	const [ordered, setOrdered] = useState({});

	const { teamid } = useParams();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, members: mems, ordered: ord } = await getPublicTeamMembers(teamid);
				console.log("Members", ord);
				if (scs) {
					setMembers(mems);
					setOrdered(ord);
				} else {
					error();
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, teamid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="flex-start"
				justifyContent="center"
				pb={5}
			>
				{props.allMembers && (
					<Typography variant="h5" color="white.main" my={2} ml={3}>
						{"Team members"}
					</Typography>
				)}
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{ordered?.members?.filter((m) => m !== null).map((member, index) => (
						<Member
							key={`member-${index}`}
							member={member}
						/>
					))}
				</Grid>
				{members.some((m) => m.status === "Collaborator") && props.allMembers && (
					<>
						<Typography variant="h5" color="white.main" my={2} ml={3}>
							{"Collaborators"}
						</Typography>
						<Grid
							container
							width="100%"
							justifyContent="flex-start"
							px={2}
						>
							{ordered?.collaborators?.filter((m) => m !== null).map((member, index) => (
								<Member
									key={`member-${index}`}
									member={member}
								/>
							))}
						</Grid>
					</>
				)}

				{members.some((m) => m.status === "Past member") && props.allMembers && (
					<>
						<Typography variant="h5" color="white.main" my={2} ml={3}>
							{"Past members"}
						</Typography>

						<Grid
							container
							width="100%"
							justifyContent="flex-start"
							px={2}
						>
							{ordered?.pastMembers?.filter((m) => m !== null).map((member, index) => (
								<Member
									key={`member-${index}`}
									member={member}
								/>
							))}
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default memo(PublicTeamMembers);
