import { useEffect } from "react";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	FormControl,
	MenuItem,
	Select,
	IconButton,
	Link,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import TitleIcon from '@mui/icons-material/Title';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SaveIcon from '@mui/icons-material/Save';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DatePicker from "../DatePicker.js";
import { dayjs } from "../../utils/index.js";
import { publicationTypes } from "../../utils/publication-constants.js";

const useStyles = makeStyles((theme) => ({
	select: {
		padding: "0px 0px 0px 0px !important",
		color: theme.palette.green.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.green.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.green.main, // Set the desired color for the select's border when focused
		},
	},
	link: {
		color: theme.palette.green.main,
		textDecoration: "underline",
		textDecorationColor: theme.palette.green.main,
		"&:hover": {
			color: theme.palette.green.dark,
		},
	},
	actionButton: {
		color: theme.palette.third.main,
		"&:hover": {
			color: theme.palette.third.dark,
		},
	},
}));

const Preparation = ({
	publication,
	typeChangeHandle,
	setChangeTargetPopupOpen,
	changeDeadlineHandler,
	setDeadline,
}) => {
	const newClasses = useStyles();
	useEffect(() => {
		// console.log("Preparation mounted!");
	}, []);
	return (
		<List dense>
			<ListItem
				sx={{
					mt: 1,
				}}

			>
				<ListItemAvatar>
					<Avatar sx={{ bgcolor: "third.main" }}>
						<TitleIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={(
						<Grid container style={{ alignItems: "baseline" }}>
							<Typography fontSize="0.9rem" color="#ffffff">
								{"Type:"}
							</Typography>
							<Typography fontSize="0.9rem" color="#ffffff">
								&nbsp;
							</Typography>
							<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
								<Select
									className={newClasses.select}
									value={publication.publicationType}
									label="Type"
									onChange={typeChangeHandle}
								>
									{publicationTypes.map((type) => (
										<MenuItem key={type.value} value={type.value}>
											{type.text}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
				/>
			</ListItem>
			<ListItem
				sx={{
					py: 0,
				}}
				secondaryAction={(
					<IconButton
						edge="end"
						onClick={() => setChangeTargetPopupOpen(true)}
					>
						<DriveFileRenameOutlineIcon className={newClasses.actionButton} />
					</IconButton>
				)}
			>
				<ListItemAvatar>
					<Avatar sx={{ bgcolor: "third.main" }}>
						<ModeStandbyIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={(
						<>
							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"Target:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" color={publication.target ? "green.main" : "gray"} pr={1.5}>
									{
										publication.target ? `${publication.target}` : "No target"
									}
								</Typography>
							</Grid>
							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"URL:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" pr={1.5} color={publication.targetUrl ? "green.main" : "gray"}>
									{
										publication.targetUrl
											? (
												<Link href={publication.targetUrl} target="_blank" rel="noopener" className={newClasses.link}>
													{"Target link"}
												</Link>
											)
											: "No URL"
									}
								</Typography>
							</Grid>
							<Grid container style={{ alignItems: "baseline" }}>
								<Typography fontSize="0.9rem" color="#ffffff">
									{"Template:"}
								</Typography>
								<Typography fontSize="0.9rem" color="#ffffff">
									&nbsp;
								</Typography>
								<Typography fontSize="0.9rem" pr={1.5} color={publication.targetTemplate ? "green.main" : "gray"}>
									{
										publication.targetTemplate
											? (
												<Link href={publication.targetTemplate} target="_blank" rel="noopener" className={newClasses.link}>
													{"Template link"}
												</Link>
											)
											: "No template"
									}
								</Typography>
							</Grid>
						</>
					)}
				/>
			</ListItem>
			<ListItem
				sx={{
					mt: 1,
				}}
				secondaryAction={(
					<IconButton
						edge="end"
						onClick={() => changeDeadlineHandler()}
					>
						<SaveIcon className={newClasses.actionButton} />
					</IconButton>
				)}
			>
				<ListItemAvatar>
					<Avatar sx={{ bgcolor: "third.main" }}>
						<AccessAlarmIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={(
						<Grid
							container
							flexDirection="row"
							style={{
								alignItems: "baseline",
							}}
						>
							<Typography fontSize="0.9rem" color="#ffffff">
								{"Deadline:"}
							</Typography>
							<Typography fontSize="0.9rem" color="#ffffff">
								&nbsp;
							</Typography>
							<DatePicker
								type="datetime"
								label=""
								width="60%"
								background="primary"
								color="third"
								value={new Date(publication.deadline)}
								onChange={(value) => {
									console.log(dayjs(value));
									setDeadline(dayjs(value).set('hour', 20).toISOString());
								}}
							/>
						</Grid>
					)}
				/>
			</ListItem>
		</List>
	);
};

export default Preparation;
