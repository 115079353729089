// import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import PublicIcon from '@mui/icons-material/Public';

import { memo, useCallback, useEffect, useState, useMemo, Fragment } from "react";

import Popup from "../components/Popup.js";
import Spinner from "../components/Spinner.js";
import Form from "../components/Form.js";
import { useSnackbar, dayjs, useWindowDimensions } from "../utils/index.js";
import {
	getTeamEvents,
} from "../api/index.js";
import { getEventModule } from "../utils/events-formatting.js";

const TeamEvents = () => {
	const windowDimensions = useWindowDimensions();
	const showEvents = useMemo(() => windowDimensions?.width > 1200, [windowDimensions.width]);

	const { error, success } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [events, setEvents] = useState([]);

	const { teamid } = useParams();
	const navigate = useNavigate();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, events: eve, message } = await getTeamEvents(teamid);
				if (scs) {
					setEvents(eve.reverse());
				} else {
					console.log(message);
					error(message ?? '');
					if (message.includes('401')) {
						setTimeout(() => {
							navigate('/');
						}, 1000);
					}
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[error, teamid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					p={1}
					pr={2}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
					sx={{
						borderBottom: 1,
						borderColor: "#555",
					}}
				>
					<Grid
						item
						xs={7}
						ml={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h6" mr={1} color="#ffffff">
							{`Team events`}
						</Typography>
					</Grid>
				</Grid>
				{
					events && (
						<Grid
							container
							// mt={2}
							mb={2}
							width="100%"
							justifyContent="space-between"
							alignItems="start"
						>
							{showEvents && (
								<Grid
									container
									item
									width="100%"
									overflow='auto'
									flexDirection="column"
									xs={12}
								>
									<Timeline
										sx={{
											[`& .${timelineItemClasses.root}:before`]: {
												flex: 0,
												padding: 0,
											},

										}}

									>
										{events?.map((e, ind) => (
											<TimelineItem key={ind}>
												<TimelineSeparator>
													<TimelineDot
														color="white"
													>
														{
															getEventModule(e)
														}
													</TimelineDot>
													{ind !== events.length - 1 && <TimelineConnector />}
												</TimelineSeparator>
												<TimelineContent>
													<Typography fontSize="0.8rem" color="green.main">
														{e.message}
													</Typography>
													<Typography color="third.main" fontSize="0.7rem">
														{e.createdAt.replace("T", " ").replace("Z", "")}
													</Typography>
												</TimelineContent>
											</TimelineItem>
										))}
									</Timeline>
								</Grid>
							)}

						</Grid>
					)
				}

			</Grid>
		</>
	);
};

export default memo(TeamEvents);
