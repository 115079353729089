import { useEffect, useState } from "react";
import { Grid, Typography, Select, MenuItem, FormControl, Chip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Popup from "../Popup.js";
import { editPublicationComment } from "../../api/index.js";
import Form from "../Form.js";

const useStyles = makeStyles((theme) => ({
	membersChip: {
		backgroundColor: theme.palette.third.main,
		"& .MuiChip-label": {
			color: "white",
		},
	},
}));

const EditCommentPopup = ({
	open,
	setOpen,
	commentId,
	allPublicationAuthorsAndMembers,
	publication,
	setIsLoading,
	error,
	success,
	fetchData,
	classes,
}) => {
	const [commentMentionsNew, setCommentMentionsNew] = useState([]);
	const [commentEdit, setCommentEdit] = useState(null);
	const newClasses = useStyles();

	const editCommentFormContent = [
		{
			customType: "wideInput",
			id: "text",
			type: "text",
			value: commentEdit,
			multiline: true,
			minRows: 6,
		},
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save comment",
		},
	];
	useEffect(() => {
		const commentEditTemp = publication.finalComments.find((comment) => comment._id === commentId);
		if (commentEditTemp) {
			setCommentEdit(commentEditTemp.text);
			const commentMentions = [];
			for (let i = 0; i < commentEditTemp.mentionsNames.length; i++) {
				commentMentions.push({
					id: commentEditTemp.mentions[i],
					name: commentEditTemp.mentionsNames[i],
				});
			}

			// console.log(commentMentions);
			setCommentMentionsNew(commentMentions);
		}
		// console.log(commentEditTemp?.mentionsNames);
	}, [commentId, publication]);

	const commentMentionsChangeHandle = (event) => {
		console.log(event.target);
		if (event.target.value === "all") {
			const newCommentedMembers = [];
			for (const member of allPublicationAuthorsAndMembers) {
				newCommentedMembers.push({
					id: member._id,
					name: member.fullname,
				});
			}

			setCommentMentionsNew(newCommentedMembers);
		} else {
			for (const mention of commentMentionsNew) {
				if (mention.id === event.target.value) {
					error("This user is already mentioned");
					return;
				}
			}

			// Find the name and add in state
			for (const member of allPublicationAuthorsAndMembers) {
				if (member._id === event.target.value) {
					setCommentMentionsNew((current) => [...current, {
						id: member._id,
						name: member.fullname,
					}]);
					break;
				}
			}
		}
	};

	const removeCommentMentionSubmitHandler = (mention) => {
		setCommentMentionsNew((current) => current.filter((mentionOld) => mentionOld.id !== mention.id));
	};

	const editCommentSubmitHandler = async (values) => {
		console.log("Submit", values);
		setIsLoading(true);
		try {
			const { success: successCode, message } = await editPublicationComment(
				publication._id, commentId, commentMentionsNew, values.text,
			);

			if (successCode) {
				success(message);
			} else {
				error(message);
			}

			setOpen(false);
		} catch (error_) {
			console.log(error_);
		}

		await fetchData();
		setIsLoading(false);
		setCommentMentionsNew([]);
		setCommentEdit(null);
		setOpen(false);
	};

	return (
		<Popup
			width="800px"
			open={open}
			title="Edit comment"
			onClose={() => {
				setCommentMentionsNew([]);
				setCommentEdit(null);
				setOpen(false);
			}}
		>
			<Grid
				container
				flexDirection="column"
				flex={1}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					mb: 1,
				}}
			>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 1,
					}}
				>
					<Typography color="third.main">{"Mention some members: "}</Typography>
					<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
						<Select
							className={classes.select}
							label="Mentions"
							value=""
							onChange={commentMentionsChangeHandle}
						>
							<MenuItem key="all-0" value="all">{"All"}</MenuItem>
							{
								allPublicationAuthorsAndMembers.map((author, ind) => (
									<MenuItem key={ind} value={author._id} name={author.fullname}>{author.fullname}</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					container
					flexDirection="row"
					flex={1}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						mb: 2,
					}}
				>
					{commentMentionsNew.map((mention) => (
						<Chip
							key={mention.id}
							label={mention.name}
							className={newClasses.membersChip}
							sx={{
								mr: 0.5,
							}}
							onDelete={() => {
								removeCommentMentionSubmitHandler(mention);
							}}
						/>
					))}
				</Grid>

				<Form
					content={editCommentFormContent}
					onSubmit={editCommentSubmitHandler}
				/>
			</Grid>
		</Popup>
	);
};

export default EditCommentPopup;
