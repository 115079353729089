/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	// Link,
	FormControl,
	Select,
	Box,
	MenuItem,
} from "@mui/material";
// import FileUpload from "../FileUpload.js";
import Form from "../Form.js";
// import { jwt } from "../../utils/index.js";
// import { addAssetToPublication } from "../../api/index.js";
import Popup from "../Popup.js";
import { updatePublication } from "../../api/index.js";
import {
	getUserProjects,
	deleteTasksFromProject,
	populateProjectWithTasks,
	updateTaskInProject,
} from "../../utils/cycloptAPI.js";

const EditPublicationPopup = ({
	publication,
	open,
	setOpen,
	fetchData,
	classes,
	success,
	error,
}) => {
	const [cycloptProjects, setCycloptProjects] = useState([]);
	const [selectedProjectId, setSelectedProjectId] = useState("");
	const [selectInitialValue, setSelectInitialValue] = useState("");
	const [progressComment, setProgressComment] = useState("");
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [initialCycloptProject, setInitialCycloptProject] = useState({}); // links to cyclopt

	const changePublicationForm = [
		{
			customType: "input",
			id: "title",
			type: "text",
			value: publication.title,
			multiline: false,
			// minRows: 1,
			label: "Title",
		},
		// {
		// 	customType: "input",
		// 	id: "doi",
		// 	type: "text",
		// 	value: publication.doi ?? '',
		// 	label: "Doi",
		// },
		{
			customType: "button",
			id: "submit",
			type: "submit",
			text: "Save changes",
			disabled: disableSubmit,
		},
	];

	const getCycloptProjects = async () => {
		const res = await getUserProjects(publication.cycloptToken);
		if (res.status === 200) {
			setCycloptProjects(res.data.projects);
		} else {
			setSelectInitialValue("Error getting cyclopt projects, please check the api token.");
		}
	};

	useEffect(() => {
		// console.log("Mounted edit publication popup");
		if (publication.cycloptToken) {
			// console.log(publication);
			getCycloptProjects();
			setSelectedProjectId(publication.cycloptProject?.projectId ?? "");
			setInitialCycloptProject(publication.cycloptProject ?? {});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publication]);

	const updateTaskIntegration = async (assignmentId, integrationProjectId, newLinks) => {
		const updatedAssignment = publication.finalAssignments.find((assignment) => assignment._id === assignmentId);
		console.log("Updating assignment:", updatedAssignment);
		const updatedTask = {
			_id: updatedAssignment._id,
			title: updatedAssignment.text,
			body: updatedAssignment.text ?? "",
			status: updatedAssignment.resolved ? "Delivered" : "Backlog", // write current status
		};

		const cycloptTaskLink = newLinks.find((link) => link.publisyncId === assignmentId);

		// eslint-disable-next-line max-len
		const res = await updateTaskInProject(publication.cycloptToken, integrationProjectId, cycloptTaskLink.integrationId, updatedTask);
		if (res.status !== 200) throw new Error("Error while Updating task", assignmentId);
	};

	const updatePublicationSubmitHandler = async (event) => {
		setDisableSubmit(true);
		// setIsLoading(true);
		// console.log(event); // event has the form (publication title)

		// console.log(publication.finalAssignments);
		const tasks = publication.finalAssignments.map((assignment) => {
			return {
				_id: assignment._id,
				title: assignment.text,
				body: assignment.text ?? "",
			};
		});

		if (selectedProjectId === initialCycloptProject.projectId) {
			try {
				const { success: successCode, message } = await updatePublication(publication._id, event.title, event.doi);
				if (successCode) {
					success(message);
				} else {
					error(message);
				}
			} catch { /* empty */ }
		} else {
			// here add assignments as tasks to cyclopt project
			// delete old tasks if exit from the old project selected
			const tasksIds = initialCycloptProject?.links?.map((task) => {
				return task.integrationId;
			});

			try {
				setProgressComment('Deleting tasks from old project...');
				let res = await deleteTasksFromProject(publication.cycloptToken, initialCycloptProject.projectId, tasksIds);

				// add new tasks to the new project selected
				res = await populateProjectWithTasks(publication.cycloptToken, selectedProjectId, tasks, setProgressComment);
				if (res.status !== 200) throw new Error("Error while linking tasks");

				const taskLinks = res.links;
				const newCycloptProject = {
					projectId: selectedProjectId,
					links: taskLinks,
				};
				// eslint-disable-next-line max-len
				const { success: successCode, message } = await updatePublication(publication._id, event.title, event.doi, newCycloptProject);
				if (successCode) {
					let index = 0;
					for (const assignment of publication.finalAssignments) {
						// update task status in cyclopt project accordingly
						setProgressComment(`Updating task ${index + 1} of ${tasks.length}...`);
						await updateTaskIntegration(assignment._id, selectedProjectId, taskLinks);
						index += 1;
					}

					success(message);
				} else {
					error(message);
				}
			} catch { /* empty */ }
		}

		await fetchData();
		setDisableSubmit(false);
		setOpen(false);
		setProgressComment("");
	};

	return (
		<Popup
			width="800px"
			open={open}
			title="Edit publication"
			onClose={() => {
				setOpen(false);
				setSelectedProjectId(publication.cycloptProject?.projectId ?? "");
			}}
		>
			<Grid
				container
				flexDirection="row"
				flex={1}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					mb: 1,
				}}
			>
				<Typography color="third.main">{"Cyclopt project "}</Typography>
				<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>

					<Select
						className={classes.select}
						value={selectedProjectId}
						onChange={(event) => {
							console.log(event.target.value);
							setSelectedProjectId(event.target.value);
							// setNewTeamMemberAsAssignment(event.target.value);
						}}
					>
						<MenuItem key="00" value="">{ selectInitialValue === "" ? "Select..." : selectInitialValue }</MenuItem>
						{
							cycloptProjects.map((project, index) => (
								<MenuItem key={index} value={project._id}>{project.name}</MenuItem>
							))
						}
					</Select>
				</FormControl>
			</Grid>
			<Grid
				container
				flexDirection="row"
				flex={1}
				sx={{
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					mb: 1,
				}}
			>
				<Box width="50%">
					<Form
						content={changePublicationForm}
						// validationSchema="addTeamMemberToPublicationSchema"
						onSubmit={updatePublicationSubmitHandler}
					/>
				</Box>
			</Grid>
			<Box width="100%" sx={{ mt: 2, textAlign: "center" }}>
				<Typography>
					{progressComment}
				</Typography>
			</Box>
		</Popup>
	);
};

export default EditPublicationPopup;
