import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Route, Routes, BrowserRouter as Router, useLocation } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorBoundary } from "react-error-boundary";
import { CssBaseline } from "@mui/material";

import "./index.scss";
import colors from "./_colors.scss";
import "react-table-6/react-table.css";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Protected from "./components/Protected.js";
import GuestOnly from "./components/GuestOnly.js";
import AllAccess from "./components/AllAccess.js";
import ErrorFallback from "./components/ErrorFallback.js";
import Snackbar from "./components/Snackbar.js";
import NotFound from "./screens/NotFound.js";
import SignIn from "./screens/SignIn.js";
import ForgotPassword from "./screens/ForgotPassword.js";
import ResetPassword from "./screens/ResetPassword.js";
import SignUp from "./screens/SignUp.js";
import InvitedSignUp from "./screens/InvitedSignUp.js";
import Auth from "./screens/Auth.js";
import Examples from "./screens/Examples.js";
import Home from "./screens/Home.js";
// import Users from "./screens/Users.js";
import Teams from "./screens/Teams.js";
import TeamMembers from "./screens/TeamMembers.js";
import TeamProjects from "./screens/TeamProjects.js";
import TeamEvents from "./screens/TeamEvents.js";
import TeamPublications from "./screens/TeamPublications.js";
import Publications from "./screens/Publications.js";
import Assignments from "./screens/Assignments.js";
import TeamInfo from "./screens/TeamInfo.js";
import PublicTeamInfo from "./screens/PublicTeamInfo.js";
import PublicationInfo from "./screens/PublicationInfo.js";
import PublicTeamMembers from "./screens/PublicTeamMembers.js";
import PublicTeamProjects from "./screens/PublicTeamProjects.js";
import PublicProjectInfo from "./screens/PublicProjectInfo.js";
import ButtonsExample from "./examples/Buttons.js";
import FormExample from "./examples/Form.js";
import DropdownExample from "./examples/Dropdown.js";
import TooltipExample from "./examples/Tooltip.js";
import TableExample from "./examples/Table.js";
import FileUploadExample from "./examples/FileUpload.js";
import BrokerExample from "./examples/Broker.js";
import PlotExample from "./examples/Plot.js";
import AccordionExample from "./examples/Accordion.js";
import SearchExample from "./examples/Search.js";
import ToastExample from "./examples/Toast.js";
import PopupExample from "./examples/Popup.js";
import DialogExample from "./examples/Dialog.js";
import DatePickerExample from "./examples/DatePicker.js";
import LocalizationExample from "./examples/Localization.js";
import { adjustColors, jwt, colorSuggestions } from "./utils/index.js";

import "./i18n.js";
import ProjectInfo from "./screens/ProjectInfo.js";
import MemberInfo from "./screens/MemberInfo.js";

const {
	NODE_ENV,
	REACT_APP_SENTRY_DSN,
	REACT_APP_SENTRY_ENVIRONMENT,
} = process.env;

Sentry.init({
	dsn: REACT_APP_SENTRY_DSN,
	environment: REACT_APP_SENTRY_ENVIRONMENT,
	ignoreErrors: [
		"ResizeObserver loop limit exceeded",
		"Non-Error promise rejection captured",
	],
	enabled: NODE_ENV === "production",
});

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
			light: colors.primaryLight || adjustColors(colors.primary, 100),
			dark: colors.primaryDark || adjustColors(colors.primary, -80),
		},
		secondary: {
			main: colors.secondary || colorSuggestions.secondary,
			light: colors.secondaryLight || adjustColors(colors.secondary || colorSuggestions.secondary, 100),
			dark: colors.secondaryDark || adjustColors(colors.secondary || colorSuggestions.secondary, -80),
		},
		third: {
			main: colors.third || colorSuggestions.third,
			light: colors.thirdLight || adjustColors(colors.third || colorSuggestions.third, 100),
			dark: colors.thirdDark || adjustColors(colors.third || colorSuggestions.third, -80),
		},

		success: { main: colors.success },
		error: { main: colors.error, dark: colors.errorDark },
		warning: { main: colors.warning },
		info: { main: colors.info },

		dark: { main: colors.dark },
		light: { main: colors.light, dark: colors.lightDark },
		grey: { main: colors.grey, dark: colors.greyDark },
		green: { main: colors.green, dark: colors.greenDark },
		white: { main: "#ffffff", dark: colors.whiteDark },
	},
});

const App = () => {
	const location = useLocation();
	const [authenticated, setAuthenticated] = useState(false);
	const [publicPage, setPublicPage] = useState(false);
	const [properHeight, setProperHeight] = useState("100vh");

	useEffect(() => {
		setAuthenticated(jwt.isAuthenticated());
		setPublicPage(location.pathname.endsWith("/public"));
		if (location.pathname.endsWith("/public")) setProperHeight("calc(100vh)");
		else if (jwt.isAuthenticated()) setProperHeight(`calc(100vh - 100px)`);
		else setProperHeight(`calc(100vh - 70px)`);
	}, [location]);

	return (
		<StyledEngineProvider injectFirst>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						{!publicPage && <Header isAuthenticated={authenticated} />}
						<main style={{ position: "relative", zIndex: 0, height: properHeight, backgroundColor: theme.palette.primary.main }}>
							<Routes>
								<Route index element={<GuestOnly c={<SignIn />} />} />
								<Route path="auth" element={<GuestOnly c={<Auth />} />} />
								<Route path="forgot-password" element={<GuestOnly c={<ForgotPassword />} />} />
								<Route path="reset-password" element={<GuestOnly c={<ResetPassword />} />} />
								<Route path="sign-up" element={<GuestOnly c={<SignUp />} />} />
								<Route path="register" element={<GuestOnly c={<InvitedSignUp />} />} />

								{/* Public */}
								<Route path="teams/:teamid/publications/public" element={<AllAccess c={<PublicTeamInfo content="publication" />} />} />
								<Route path="teams/:teamid/membersOnly/public" element={<AllAccess c={<PublicTeamMembers />} />} />
								<Route path="teams/:teamid/members/public" element={<AllAccess c={<PublicTeamMembers allMembers />} />} />
								<Route path="teams/:teamid/theses/public" element={<AllAccess c={<PublicTeamInfo content="master_thesis" />} />} />
								<Route path="teams/:teamid/phds/public" element={<AllAccess c={<PublicTeamInfo content="phd_thesis" />} />} />
								<Route path="teams/:teamid/projects/public" element={<AllAccess c={<PublicTeamProjects />} />} />
								<Route path="teams/:teamid/projects/:projectid/public" element={<AllAccess c={<PublicProjectInfo />} />} />

								{/* Private */}
								<Route path="home" element={<Protected c={<Home />} />} />
								<Route path="teams" element={<Protected c={<Teams />} />} />
								<Route path="publications" element={<Protected c={<Publications />} />} />
								<Route path="assignments" element={<Protected c={<Assignments />} />} />

								{/* Team specific */}
								<Route path="teams/:teamid" element={<Protected c={<TeamInfo />} />} />
								<Route path="teams/:teamid/publications" element={<Protected c={<TeamPublications content="publication" />} />} />
								<Route path="teams/:teamid/theses" element={<Protected c={<TeamPublications content="master_thesis" />} />} />
								<Route path="teams/:teamid/phds" element={<Protected c={<TeamPublications content="phd_thesis" />} />} />
								<Route path="teams/:teamid/proposals" element={<Protected c={<TeamPublications content="proposal" />} />} />
								<Route path="teams/:teamid/events" element={<Protected c={<TeamEvents />} />} />
								<Route path="teams/:teamid/members" element={<Protected c={<TeamMembers />} />} />
								<Route path="teams/:teamid/members/:memberid" element={<Protected c={<MemberInfo />} />} />
								<Route path="teams/:teamid/projects" element={<Protected c={<TeamProjects />} />} />
								<Route path="teams/:teamid/projects/:projectid" element={<Protected c={<ProjectInfo />} />} />

								{/* Publication specific */}
								{/* <Route path="teams/:teamid/documents/:publicationid" element={<Protected c={<PublicationInfo />} />} /> */}
								<Route path="teams/:teamid/theses/:publicationid" element={<Protected c={<PublicationInfo />} />} />
								<Route path="teams/:teamid/publications/:publicationid" element={<Protected c={<PublicationInfo />} />} />
								<Route path="teams/:teamid/phds/:publicationid" element={<Protected c={<PublicationInfo />} />} />
								<Route path="teams/:teamid/proposals/:publicationid" element={<Protected c={<PublicationInfo />} />} />

								{/* EXAMPLES */}
								<Route path="examples" element={<Protected c={<Examples />} />} />
								<Route path="buttons" element={<Protected c={<ButtonsExample />} />} />
								<Route path="form" element={<Protected c={<FormExample />} />} />
								<Route path="dropdown" element={<Protected c={<DropdownExample />} />} />
								<Route path="tooltip" element={<Protected c={<TooltipExample />} />} />
								<Route path="table" element={<Protected c={<TableExample />} />} />
								<Route path="file-upload" element={<Protected c={<FileUploadExample />} />} />
								<Route path="broker" element={<Protected c={<BrokerExample />} />} />
								<Route path="plot" element={<Protected c={<PlotExample />} />} />
								<Route path="accordion" element={<Protected c={<AccordionExample />} />} />
								<Route path="search" element={<Protected c={<SearchExample />} />} />
								<Route path="toast" element={<Protected c={<ToastExample />} />} />
								<Route path="popup" element={<Protected c={<PopupExample />} />} />
								<Route path="dialog" element={<Protected c={<DialogExample />} />} />
								<Route path="datepicker" element={<Protected c={<DatePickerExample />} />} />
								<Route path="localization" element={<Protected c={<LocalizationExample />} />} />
								{/* END OF EXAMPLES */}

								<Route path="*" element={<NotFound />} />
							</Routes>
							{authenticated && !publicPage && <Footer />}
						</main>
						<Snackbar />
					</LocalizationProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(
	<Router>
		<App />
	</Router>,
);
