import {
    Grid,
    Button,
    CircularProgress,
    Typography
} from "@mui/material";
import { useState, useEffect } from "react";
import PublicationMetricCard from "../components/PublicationMetricCard.js";
import { fullPublicationTypes } from "../utils/publication-constants.js";
import DropdownMetricCard from "./PublicationDropdownMetricCard.js";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    show_graphs_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 90,
        backgroundColor: "#fff",
        color: '#333',
        fontWeight: 700,
        fontSize: 15,
        height: '50px',
        minWidth: '200px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#0D192B',
            color: '#fff',
        },
    },
    no_data: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        width: 460,
        height: 300,
        border: '1px solid white',
        borderRadius: 20,
        fontSize: 20
    }
});

const PublicationMetrics = ({ filterType, filteredPublications }) => {
    const classes = useStyles();
    const [currentType, setCurrentType] = useState('');
    const [publicationsPublished, setPublicationsPublished] = useState([]);
    const [publishedPerYearPublications, setPublishedPerYearPublications] = useState([]);
    const [averagePublicationsPerYear, setAveragePublicationsPerYear] = useState('');
    const [cardSelectOptions, setCardSelectOptions] = useState([]);
    const [metricDropdownValue, setMetricDropdownValue] = useState(2);
    const [avgPubsInTheLast, setAvgPubsInTheLast] = useState('');
    const [publishingYears, setPublishingYears] = useState([]);
    const [pubCountPerYear, setPubCountPerYear] = useState([]);
    const [pubQuartiles, setPubQuartiles] = useState([]);
    const [pubQuartilesCount, setPubQuartilesCount] = useState([]);
    const [impactFactorValues, setImpactFactorValues] = useState([]);
    const [impactFactorCount, setImpactFactorCount] = useState([]);
    const [showGraphs, setShowGraphs] = useState(false);
    const [loading, setLoading] = useState(true);

    const calculateAvgPubsPerYear = (years, pubs) => {
        if (pubs.length !== 0) {
            const currentYear = new Date().getFullYear();
            let pubsCount = 0;
            let totalPublications = 0;
            for (let count = 0; count < years; count++) {
                if (pubs[pubsCount].year && currentYear - count === pubs[pubsCount].year) {
                    for (let innerPubCount = 0; innerPubCount < pubs[pubsCount].publications.length; innerPubCount++) {
                        totalPublications += pubs[pubsCount].publications[innerPubCount].pubsOfType.length;
                    }
                    pubsCount++;
                    if (pubsCount === pubs.length) break;
                }
            }
            return (totalPublications / years).toFixed(2);
        } else {
            return '0.00';
        }
    };

    const yearsPubsArrays = (pubsPerYear) => {
        const years = [];
        const pubsCounts = [];
        for (let count = pubsPerYear.length - 1; count >= 0; count--) {
            years.push(pubsPerYear[count].year);
            let pubsYearCount = 0;
            for (let subCount = 0; subCount < pubsPerYear[count].publications.length; subCount++) {
                pubsYearCount += pubsPerYear[count].publications[subCount].pubsOfType.length;
            }
            pubsCounts.push(pubsYearCount);
        }
        return [years, pubsCounts];
    };

    const quartilesPubsArray = (publishedPubs) => {
        const quartiles = ['Q1', 'Q2', 'Q3', 'Q4'];
        const quartilesPubsCount = [0, 0, 0, 0];
        for (const pub of publishedPubs) {
            if (pub.quartile) {
                quartilesPubsCount[quartiles.indexOf(pub.quartile)] += 1;
            }
        }
        return [quartiles, quartilesPubsCount];
    };

    const impactFactorArray = (publishedPubs) => {
        const roundedIFArray = publishedPubs.map(pub => pub.impactFactor ? Math.round(pub.impactFactor * 2) / 2 : null).filter(ifValue => ifValue !== null);
        const valuesArray = [];
        const ifValuesCount = [];
        for (let count = 0.5; count <= Math.max(...roundedIFArray, 0.5); count += 0.5) {
            valuesArray.push(count);
            ifValuesCount.push(0);
        }
        for (const ifValue of roundedIFArray) {
            ifValuesCount[valuesArray.indexOf(ifValue)] += 1;
        }
        return [valuesArray, ifValuesCount];
    };

    const chartSetting = {
        width: 460,
        height: 300,
    };

    useEffect(() => {
        setLoading(true);

        const currentType = filterType;
        setCurrentType(currentType);

        let publishedPubs = filteredPublications.filter(filteredPublication => filteredPublication.state.state === "Published" && filteredPublication.publicationDate);

        const publishedPerYear = [];
        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= 2000; year--) {
            const pubsOfYear = publishedPubs.filter(pub => new Date(pub.publicationDate).getFullYear() === year);
            if (pubsOfYear.length === 0) continue;

            const publishedCategories = [];
            for (let type of fullPublicationTypes) {
                const pubsOfType = pubsOfYear.filter(pub => pub.publicationType === type.value);
                if (pubsOfType.length === 0) continue;
                publishedCategories.push({ type, pubsOfType });
            }
            publishedPerYear.push({ year, publications: publishedCategories });
        }

        if (publishedPerYear.length) {
            setAveragePublicationsPerYear((publishedPubs.length / (currentYear - publishedPerYear[publishedPerYear.length - 1].year + 1)).toFixed(2));
        } else {
            setAveragePublicationsPerYear(0);
        }

        const cardOptions = [];
        for (let count = currentYear - 1999; count > 1; count--) {
            cardOptions.unshift([`${count} years`, count]);
        }
        cardOptions.unshift(['year', 1]);

        setCardSelectOptions(cardOptions);
        setPublishedPerYearPublications(publishedPerYear);
        setPublicationsPublished(publishedPubs);
        setAvgPubsInTheLast(calculateAvgPubsPerYear(metricDropdownValue, publishedPerYear));

        const [pubYears, yearPubCount] = yearsPubsArrays(publishedPerYear);
        setPublishingYears(pubYears);
        setPubCountPerYear(yearPubCount);

        const [quartiles, quartilesPubsCount] = quartilesPubsArray(publishedPubs);
        setPubQuartiles(quartiles);
        setPubQuartilesCount(quartilesPubsCount);

        const [ifValues, ifValuesCount] = impactFactorArray(publishedPubs);
        setImpactFactorValues(ifValues);
        setImpactFactorCount(ifValuesCount);

        setLoading(false);
    }, [filterType, filteredPublications]);

    const showGraphsClicked = () => {
        setShowGraphs(prev => !prev);
    };

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid container spacing={2} mb={4} direction="row" alignItems="center" justifyContent="center" sx={{ marginBottom: 0 }}>
                <Grid item xs={12} sm={3}>
                    <PublicationMetricCard
                        title={currentType === "All types" ? "Total Publications" : `Total ${currentType}s`}
                        value={publicationsPublished.length}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <PublicationMetricCard
                        title={currentType === "All types" ? "Avg Publications per year" : `Avg ${currentType}s per year`}
                        value={averagePublicationsPerYear}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <DropdownMetricCard
                        title={currentType === "All types" ? "Avg Publications in the last" : `Avg ${currentType}s in the last`}
                        value={avgPubsInTheLast}
                        dropdownOptions={cardSelectOptions}
                        setDropdownValue={setMetricDropdownValue}
                        publishedPerYearPublications={publishedPerYearPublications}
                        calculateAvgPubsPerYear={calculateAvgPubsPerYear}
                        setAvgPubsInTheLast={setAvgPubsInTheLast}
                        totalPubs={publicationsPublished.length}
                    />
                </Grid>
                <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">

                    <Button
                        variant="contained"
                        size="large"
                        className={classes.show_graphs_button}
                        onClick={showGraphsClicked}
                    >
                        {showGraphs ? 'Hide graphs' : 'Show graphs'}
                        {!showGraphs ? <ArrowDropDown style={{ color: 'inherit', fontSize: 23 }} /> :
                            <ArrowDropUp style={{ color: 'inherit', fontSize: 23 }} />}

                    </Button>
                </Grid>
            </Grid>

            {loading && (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <CircularProgress />
                </div>
            )}

            {!loading && showGraphs && (
                <Grid container direction="row" spacing={2} alignItems="center" justifyContent="center" display='flex' sx={{ marginBottom: 3, marginTop: 1 }}>
                    <Grid item xs={12} sm={4} sx={{ alignItems: "center", justifyContent: "center", display: 'flex' }}>
                        {publishingYears.length > 0 && pubCountPerYear.length > 0 ? (
                            <BarChart
                                xAxis={[{ scaleType: 'band', data: publishingYears }]}
                                series={[{ data: pubCountPerYear, label: currentType === "All types" ? 'Publications per year' : `${currentType}s per year` }]}
                                sx={{
                                    [`.${axisClasses.root}`]: {
                                        [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                                            stroke: 'white',
                                        },
                                        [`.${axisClasses.tickLabel}`]: {
                                            fill: 'white',
                                        },
                                    },
                                    "& .MuiChartsLegend-series text": { fill: "white !important" },

                                    border: '1px solid white',
                                    borderRadius: 5,
                                }}
                                {...chartSetting}
                            />
                        ) : (
                            <Typography
                                alignItems='center'
                                display='flex'
                                justifyContent='center'
                                className={classes.no_data}
                            >
                                No data available
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ alignItems: "center", justifyContent: "center", display: 'flex' }}>
                        {pubQuartiles.length > 0 && pubQuartilesCount.length > 0 && pubQuartilesCount.reduce((accumulator, current) => accumulator + current) > 0 ? (
                            <BarChart
                                xAxis={[{ scaleType: 'band', data: pubQuartiles }]}
                                series={[{ data: pubQuartilesCount, label: 'Count of Quartile' }]}
                                sx={{
                                    [`.${axisClasses.root}`]: {
                                        [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                                            stroke: 'white',
                                        },
                                        [`.${axisClasses.tickLabel}`]: {
                                            fill: 'white',
                                        },
                                    },
                                    "& .MuiChartsLegend-series text": { fill: "white !important" },

                                    border: '1px solid white',
                                    borderRadius: 5,
                                }}
                                {...chartSetting}
                            />
                        ) : (
                            <Typography
                                alignItems='center'
                                display='flex'
                                justifyContent='center'
                                className={classes.no_data}
                            >
                                No data available
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ alignItems: "center", justifyContent: "center", display: 'flex' }}>
                        {impactFactorValues.length > 0 && impactFactorCount.length > 0 && impactFactorCount.reduce((accumulator, current) => accumulator + current) > 0 ? (
                            <BarChart
                                xAxis={[{ scaleType: 'band', data: impactFactorValues }]}
                                series={[{ data: impactFactorCount, label: 'Impact Factor' }]}
                                sx={{
                                    [`.${axisClasses.root}`]: {
                                        [`& .${axisClasses.tick}, & .${axisClasses.line}`]: {
                                            stroke: 'white',
                                        },
                                        [`& .${axisClasses.tickLabel}`]: {
                                            fill: 'white',
                                        },
                                    },
                                    "& .MuiChartsLegend-series text": { fill: "white !important" },

                                    border: '1px solid white',
                                    borderRadius: 5,
                                }}
                                {...chartSetting}
                            />
                        ) : (
                            <Typography
                                alignItems='center'
                                display='flex'
                                justifyContent='center'
                                className={classes.no_data}
                            >
                                No data available
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default PublicationMetrics;
