import { memo } from "react";
import { AppBar, Box, Link, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Image } from "mui-image";

import logo from "../assets/images/isselLogo.png";

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		flexBasis: "auto",
		background: theme.palette.dark.main,
		zIndex: 1200,
		height: "30px",
		borderRadius: "0px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	box: {
		height: "100%",
		width: "fit-content",
		padding: "10px 20px",
		marginLeft: "20px",
	},
	outer: {
		backgroundColor: theme.palette.primary.main,
	},
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<Box sx={{ width: "100%", position: "absolute", bottom: 0 }}>
			<Box className={classes.outer}>
				<AppBar id="footer" position="static" className={classes.grow}>
					<Toolbar className="header-container">
						<Box className={classes.grow} style={{ height: "100%" }} />
						<Box className={classes.grow} display="flex" style={{ height: "100%", justifyContent: "center", alignItems: "flex-end", marginRight: "20px" }}>
							<Typography fontSize="small">{`@${(new Date()).getFullYear()} ISSEL | All Rights Reserved`}</Typography>
						</Box>
					</Toolbar>
				</AppBar>
			</Box>
		</Box>
	);
};

export default memo(Footer);
