import { Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Search from "../components/Search.js";
import Spinner from "../components/Spinner.js";
import { isFuzzyMatch, useSnackbar } from "../utils/index.js";
import { getPublicTeamProjects } from "../api/index.js";
import { Project } from "../components/Project.js";

const PublicTeamProjects = () => {
	const { error } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState(projects);
	const { teamid } = useParams();

	const fetchData = useCallback(
		async () => {
			setIsLoading(true);
			try {
				const { success: scs, projects: prjs } = await getPublicTeamProjects(teamid);
				if (scs) {
					prjs.sort((a, b) => {
						if (a.startDate < b.startDate) {
							return 1;
						}

						if (a.startDate > b.startDate) {
							return -1;
						}

						return 0;
					});
					setProjects(prjs);
				} else {
					error();
				}
			} catch (error_) {
				console.log(error_);
				error();
			}

			setIsLoading(false);
		},
		[error, teamid],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	useEffect(() => {
		setFilteredProjects(projects.filter((us) => isFuzzyMatch(us.title, searchFilter)));
	}, [searchFilter, projects]);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Grid
					container
					item
					width="100%"
					mt={2}
					pr={2}
					mb={2}
					display="flex"
					minHeight="60px"
					borderRadius="20px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid
						item
						xs={7}
						ml={2}
						display="flex"
						flexDirection="row"
						alignItems="center"
						height="60px"
					>
						<Typography variant="h5" color="white.main" ml={3}>
							{"Team projects"}
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						pr={3}
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						height="100%"
					>
						<Search value={searchFilter} extraStyles={{ border: 1, borderColor: "#000" }} onChange={(event) => setSearchFilter(event.target.value)} />
					</Grid>
				</Grid>
				{/* Current projects */}
				<Grid
					container
					item
					width="100%"
					p={1}
					pl={5}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5" color="white.main">
						{"Ongoing projects"}
					</Typography>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{filteredProjects
						.filter((p) => (p.endDate === undefined || p.endDate === null || new Date(p.endDate) > new Date()) && !p.isPlatform)
						.map((project, index) => (
							<Project
								key={`project-${index}`}
								project={project}
							/>
						))}
				</Grid>
				{/* Platforms */}
				<Grid
					container
					item
					width="100%"
					p={1}
					pl={5}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5" color="white.main">
						{"Platforms / Websites"}
					</Typography>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{filteredProjects
						.filter((p) => p.isPlatform)
						.map((project, index) => (
							<Project
								key={`project-${index}`}
								project={project}
							/>
						))}
				</Grid>
				{/* Past projects */}
				<Grid
					container
					item
					width="100%"
					p={1}
					pl={5}
					mb={1}
					display="flex"
					minHeight="60px"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5" color="white.main">
						{"Past projects"}
					</Typography>
				</Grid>
				<Grid
					container
					width="100%"
					justifyContent="flex-start"
					px={2}
				>
					{filteredProjects
						.filter((p) => p.endDate !== undefined && p.endDate !== null && new Date(p.endDate) < new Date() && !p.isPlatform)
						.map((project, index) => (
							<Project
								key={`project-${index}`}
								project={project}
							/>
						))}
				</Grid>
			</Grid>
		</>
	);
};

export default memo(PublicTeamProjects);
