import { useState, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";

import {
	Grid,
	Typography,
	FormControl,
	MenuItem,
	Select,
} from "@mui/material";
import { makeStyles } from '@mui/styles';

import {
	saveMarkdown,
	saveMarkdownTheme,
	getMarkdownTheme,
} from "../../api/index.js";
import { calculateTimestampToShow } from "../../utils/timestamps.js";

const useStyles = makeStyles((theme) => ({
	select: {
		padding: "0px 0px 0px 0px !important",
		color: theme.palette.primary.main, // Set the desired color for the select
		"&:before": {
			borderColor: theme.palette.primary.main, // Set the desired color for the select's border
		},
		"&:after": {
			borderColor: theme.palette.primary.main, // Set the desired color for the select's border when focused
		},
	},
}));

const MyMdEditor = ({
	myid,
	publication,
	classes,
	setIsLoading,
	success,
	error,

}) => {
	const [markDownValue, setMarkDownValue] = useState(publication.markdown);
	const [autoSaveTimerId, setAutoSaveTimerId] = useState(null);
	const [lastSaveTimestamp, setLastSaveTimestamp] = useState("");
	const [timestampToShow, setTimestampToShow] = useState("");
	const [pageStart, setPageStart] = useState(true);
	const newClasses = useStyles();

	const themes = [
		{
			value: "light",
			label: "light",
		},
		{
			value: "dark",
			label: "dark",
		},
	];
	const [themeMode, setThemeMode] = useState("");

	const getUserMarkdownTheme = async () => {
		const res = await getMarkdownTheme(myid);
		// console.log(res);
		if (res.success) {
			setThemeMode(res.message);
		} else {
			setThemeMode("dark");
		}
	};

	const calculateTimestamp = (timestamp) => {
		const stringToShow = calculateTimestampToShow(timestamp);
		setTimestampToShow(stringToShow);
	};

	const saveChanges = async () => {
		// console.log(markDownValue);
		setLastSaveTimestamp(new Date());
		try {
			const { success: successCode, message } = await saveMarkdown(publication._id, markDownValue);
			console.log(message);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }
	};

	const saveTheme = async (e) => {
		setThemeMode(e.target.value);
		setIsLoading(true);
		try {
			const { success: successCode, message } = await saveMarkdownTheme(myid, e.target.value);
			console.log(message);
			if (successCode) {
				success(message);
			} else {
				error(message);
			}
		} catch { /* empty */ }

		setIsLoading(false);
	};

	useEffect(() => {
		getUserMarkdownTheme();
		// console.log("Last modified", publication.lastMarkdownModification);
		if (publication.lastMarkdownModification) {
			setLastSaveTimestamp(new Date(publication.lastMarkdownModification));
		}

		// const id = setInterval(calculateTimestampToShow, 10000);
		return () => {
			// clearInterval(id);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// console.log("changed timestamp", lastSaveTimestamp);
		if (lastSaveTimestamp) {
			calculateTimestamp(lastSaveTimestamp); // This will always use latest value of count
		}
	}, [lastSaveTimestamp]);

	useEffect(() => {
		if (pageStart) {
			setPageStart(false);
		} else {
			console.log("MD changed!");
			// console.log(markDownValue);
			// stop previous save timer if exists
			if (autoSaveTimerId) {
				clearTimeout(autoSaveTimerId);
			}

			// set new auto save timer
			const newTimeout = setTimeout(saveChanges, 3000);
			setAutoSaveTimerId(newTimeout);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markDownValue]);

	return (
		<Grid
			container
			item
			display="flex"
			flexDirection="column"
			backgroundColor="secondary.main"
			sx={{
				borderRadius: "20px",
			}}
		>
			{/* Title */}
			<Grid
				container
				item
				width="100%"
				pl={2}
				display="flex"
				minHeight="50px"
				maxHeight="50px"
				alignItems="center"
				backgroundColor="third.main"
				sx={{
					borderTopLeftRadius: "20px",
					borderTopRightRadius: "20px",
				}}
			>
				<Grid
					item
					xs={6}
				>
					<Typography variant="h6" color="secondary.main">
						{"Notes"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="flex-end"
				>
					{
						lastSaveTimestamp
							? 	(
								<Typography variant="h10" color="grey.dark" sx={{ mr: 2 }}>
									{`Last update: ${timestampToShow}`}
								</Typography>
							)
							: ''
					}
					<FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
						<Select
							className={newClasses.select}
							label="Mentions"
							value={themeMode}
							onChange={(e) => {
								saveTheme(e);
							}}
						>
							{
								themes.map((e, ind) => (
									<MenuItem key={ind} value={e.value}>{e.label}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					{/* <Chip
						key={-1}
						label="save"
						color="success"
						sx={{
							mr: 0.5,
						}}
						onClick={() => {
							saveChanges();
						}}
					/> */}
				</Grid>
			</Grid>
			{/* Editor */}
			<Grid
				data-color-mode={themeMode}
				sx={{
					p: 2,
				}}
			>
				<MDEditor
					value={markDownValue}
					height={600}
					maxHeight={600}
					fullscreen={false}
					overflow={false}
					tabSize={2}
					preview="live"
					style={{ zIndex: 100 }}
					onChange={setMarkDownValue}
				/>
			</Grid>
		</Grid>
	);
};

export default MyMdEditor;
