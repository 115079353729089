import { useState, useEffect, useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Button, Paper, Breadcrumbs, Box } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
	ExpandMore,
	MoreVert as MoreIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Image } from "mui-image";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { jwt, capitalize, useSnackbar } from "../utils/index.js";
import logo from "../assets/images/PW_Horizontal_mixed.png";
import logoutIcon from "../assets/icons/logout.png";
import teamsIcon from "../assets/icons/teams.png";
import assignmentsIcon from "../assets/icons/assignments.png";
import publicationsIcon from "../assets/icons/publications.png";
import { getTeamsData } from "../api/index.js";

const useStyles = makeStyles((theme) => ({
	outer: {
		backgroundColor: theme.palette.primary.main,
	},
	grow: {
		flexGrow: 1,
		flexBasis: "auto",
		background: theme.palette.primary.main,
		zIndex: 1200,
		height: "70px",
		alignItems: "center",
	},
	root: {
		height: "30px",
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(3),
		borderRadius: "0px",
		background: theme.palette.secondary.main,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	icon: {
		marginRight: 0.5,
		width: 20,
		height: 20,
	},
	expanded: {
		background: "transparent",
	},
	innerSmallAvatar: {
		color: theme.palette.common.black,
		fontSize: "inherit",
	},
	anchorOriginBottomRightCircular: {
		".MuiBadge-anchorOriginBottomRightCircular": {
			right: 0,
			bottom: 0,
		},
	},
	avatar: {
		width: "30px",
		height: "30px",
		background: "white",
	},
	iconButton: {
		padding: "3px 6px",
	},
	menuItemButton: {
		width: "100%",
		bgcolor: "grey.light",
		"&:hover": {
			bgcolor: "grey.dark",
		},
	},
	grey: {
		color: "grey.500",
	},
}));

const ButtonWithText = ({ text, icon, more, handler }) => (
	<Button sx={{ height: "100%", display: "flex", flexDirection: "column", p: 1, mx: 1 }} onClick={(event) => handler(event)}>
		<Image src={icon} alt={text} fit="contain" sx={{ p: 0, my: 0, height: "100%", maxWidth: "200px" }} />
		<Typography align="center" color="third.main" fontSize="small" fontWeight="bold" display="flex" alignItems="center" sx={{ textTransform: "capitalize" }}>
			{text}
			{more && <ExpandMore />}
		</Typography>
	</Button>
);

const Header = ({ isAuthenticated, showBreadcrums = true }) => {
	const classes = useStyles();

	const location = useLocation();
	const navigate = useNavigate();
	const [anchorElTeams, setAnchorElTeams] = useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const isMenuOpenTeams = Boolean(anchorElTeams);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleTeamsMenuOpen = (event) => setAnchorElTeams(event.currentTarget);
	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
	const handleTeamsMenuClose = () => { setAnchorElTeams(null); handleMobileMenuClose(); };
	const handleMobileMenuOpen = (event) => setMobileMoreAnchorEl(event.currentTarget);
	const closeAll = () => {
		handleTeamsMenuClose();
		handleMobileMenuClose();
	};

	const { error, _ } = useSnackbar();
	const [teams, setTeams] = useState([]);

	const fetchData = useCallback(
		async () => {
			try {
				if (!isAuthenticated) return;
				const { success: scs, teams: tms } = await getTeamsData();
				const final = [];

				if (scs) {
					final.push({
						title: "All Teams",
						path: "/teams",
						icon: teamsIcon,
					});
					// eslint-disable-next-line guard-for-in
					for (const t in tms) {
						final.push(
							{
								title: tms[t].name,
								path: `/teams/${tms[t]._id}`,
								icon: teamsIcon,
							},
						);
					}

					setTeams(final);
				} else {
					error();
				}
			} catch {
				error();
			}
		},
		[error, isAuthenticated],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const CrumpLink = styled(Link)(({ theme }) => ({
		display: "flex",
		color: theme.palette.green.main,
		"&:hover": {
			color: theme.palette.green.dark,
		},
	}));

	const buttons = [
		{
			icon: teamsIcon,
			text: "Teams",
			handler: (event) => {
				closeAll();
				handleTeamsMenuOpen(event);
			},
			more: teams,
		},
		{
			icon: publicationsIcon,
			text: "Publications",
			handler: () => {
				closeAll();
				navigate("/publications");
			},
		},
		{
			icon: assignmentsIcon,
			text: "Assignments",
			handler: () => {
				closeAll();
				navigate("/assignments");
			},
		},
		{
			icon: logoutIcon,
			text: "Logout",
			handler: () => {
				closeAll();
				jwt.destroyToken();
				navigate("/");
			},
		},
	];

	const renderMobileMenu = (
		<Menu
			keepMounted
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{buttons.map((button) => (
				<MenuItem key={button.text} onClick={button.handler}>
					<Image src={button.icon} width="20px" />
					<p style={{ marginLeft: "5px" }}>{button.text}</p>
					{button.more && <ExpandMore />}
				</MenuItem>
			))}
		</Menu>
	);

	const renderTeamsMenu = (
		<Menu
			keepMounted
			anchorEl={anchorElTeams}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpenTeams}
			onClose={handleTeamsMenuClose}
		>
			{buttons.find((button) => button.text === "Teams").more.map((moreButton) => (
				<MenuItem key={moreButton.title} onClick={() => { closeAll(); navigate(moreButton.path); }}>
					<Image src={moreButton.icon} width="20px" />
					<p style={{ marginLeft: "5px" }}>{moreButton.title}</p>
				</MenuItem>
			))}
		</Menu>
	);

	const pathnames = location.pathname.split("/").filter(Boolean);
	const crumps = [];
	crumps.push(
		<CrumpLink to="/">
			{"Home"}
		</CrumpLink>,
	);

	for (const [ind, path] of pathnames.entries()) {
		let text = capitalize(path);
		// eslint-disable-next-line no-continue
		if (path === "home") continue;
		switch (path) {
			case "file-upload": {
				text = "File Upload";
				break;
			}

			default:
		}

		crumps.push(<CrumpLink to={`/${pathnames.slice(0, ind + 1).join("/")}`}>{text}</CrumpLink>);
	}

	return (
		<Box className={classes.outer}>
			<AppBar id="header" position="static" className={classes.grow}>
				<Toolbar className="header-container" style={{ alignItems: "center" }}>
					<Box component={Link} to="/">
						<Image src={logo} alt="Logo" sx={{ p: 0, my: 0, mx: 2, height: "100%", maxWidth: "200px" }} />
					</Box>
					<Box className={classes.grow} style={{ height: "100%" }} />
					{isAuthenticated
						&& (
							<>
								<Box sx={{ display: { xs: "none", sm: "none", md: "flex" }, height: "100%", py: 1 }}>
									{buttons.map((button) => (
										<ButtonWithText
											key={button.text}
											icon={button.icon}
											text={button.text}
											handler={button.handler}
											more={button.more}
										/>
									))}
								</Box>
								<Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
									<IconButton color="primary" onClick={handleMobileMenuOpen}><MoreIcon /></IconButton>
								</Box>
							</>
						)}
				</Toolbar>
			</AppBar>
			{isAuthenticated && showBreadcrums
				&& (
					<Paper elevation={0} className={classes.root}>
						<Breadcrumbs
							className="header-container"
							fontSize="small"
							color="common.white"
							separator={<NavigateNextIcon fontSize="small" />}
						>
							{crumps.map((e, ind) => <div key={`crump_${ind}`}>{e}</div>)}
						</Breadcrumbs>
					</Paper>
				)}
			{isAuthenticated
				&& (
					<>
						{renderMobileMenu}
						{renderTeamsMenu}
					</>
				)}
		</Box>
	);
};

export default memo(Header);
