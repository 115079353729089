import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CakeIcon from '@mui/icons-material/Cake';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import KeyIcon from '@mui/icons-material/Key';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SmsIcon from '@mui/icons-material/Sms';
import TitleIcon from '@mui/icons-material/Title';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ImageIcon from '@mui/icons-material/Image';
import LoupeIcon from '@mui/icons-material/Loupe';
import ApiIcon from '@mui/icons-material/Api';
import { styled } from "@mui/styles";
import { Icon } from '@mui/material';

const StyledLightDarkIcon = styled(Icon)(({ theme }) => ({
	color: theme.palette.light.dark,
}));

const StyledGreyDarkIcon = styled(Icon)(({ theme }) => ({
	color: theme.palette.grey.dark,
}));

const relations = {
	"Updated publication info": (<LoupeIcon color="green" />),
	"Updated tag": (<LoupeIcon color="green" />),
	"Link asset added": (<InsertLinkIcon color="green" />),
	"Image asset added": (<ImageIcon color="green" />),
	"File asset added": (<AttachFileIcon color="green" />),
	"Link asset deleted": (<InsertLinkIcon color="error" />),
	"Image asset deleted": (<ImageIcon color="error" />),
	"File asset deleted": (<AttachFileIcon color="error" />),
	"was updated!": (<StyledLightDarkIcon><Diversity3Icon /></StyledLightDarkIcon>),
	"added in team": (<PersonAddIcon color="green" />),
	"Team created": (<CakeIcon color="primary" />),
	"removed from the team": (<LoupeIcon color="error" />),
	"removed from": (<PersonRemoveIcon color="error" />),
	"Publication created": (<PostAddIcon color="green" />),
	"Publication deleted": (<PlaylistRemoveIcon color="error" />),
	"added to the team": (<LoupeIcon color="green" />),
	"added as author in publication": (<GroupAddIcon color="green" />),
	"added as supervisor in publication": (<GroupAddIcon color="green" />),
	"added as Corresponding author in publication": (<GroupAddIcon color="green" />),
	"added as Assistant supervisor in publication": (<GroupAddIcon color="green" />),
	"State changed to": (<StyledLightDarkIcon><PublishedWithChangesIcon /></StyledLightDarkIcon>),
	"Type changed to": (<StyledLightDarkIcon><MotionPhotosAutoIcon /></StyledLightDarkIcon>),
	"Title changed to": (<StyledLightDarkIcon><TitleIcon /></StyledLightDarkIcon>),
	"Abstract changed": (<StyledLightDarkIcon><HdrAutoIcon /></StyledLightDarkIcon>),
	"Target title changed": (<StyledLightDarkIcon><ModeStandbyIcon /></StyledLightDarkIcon>),
	"Target url changed": (<StyledLightDarkIcon><ModeStandbyIcon /></StyledLightDarkIcon>),
	"Target template changed": (<StyledLightDarkIcon><ModeStandbyIcon /></StyledLightDarkIcon>),
	"Review added": (<StyledLightDarkIcon><RateReviewIcon /></StyledLightDarkIcon>),
	"added as a reviewer": (<ReviewsIcon color="green" />),
	"removed as reviewer": (<ReviewsIcon color="error" />),
	"Review deleted": (<RateReviewIcon color="error" />),
	"Keywords changed": (<KeyIcon color="light" />),
	"Published information changed": (<ThumbUpIcon color="light" />),
	"Deadline changed": (<AccessAlarmIcon color="light" />),
	"Live document URL changed": (<FavoriteIcon color="light" />),
	"New version added": (<DocumentScannerIcon color="green" />),
	"Document version deleted": (<DocumentScannerIcon color="error" />),
	"Document version cleared": (<DocumentScannerIcon color="error" />),
	"New review added": (<RateReviewIcon color="light" />),
	"Document for review submitted": (<RateReviewIcon color="light" />),
	"Revisions added": (<DocumentScannerIcon color="light" />),
	"Reviewing process locked": (<LockIcon color="error" />),
	"Reviewing process unlocked": (<LockOpenIcon color="green" />),
	"External reviewers' comments": (<ContactSupportIcon color="light" />),
	"Team name changed": (<TitleIcon color="light" />),
	"New assignment": (<AssignmentTurnedInIcon color="green" />),
	"Assignment deleted": (<AssignmentTurnedInIcon color="error" />),
	"Assignment opened": (<AssignmentTurnedInIcon color="light" />),
	"Assignment resolved": (<StyledGreyDarkIcon><AssignmentTurnedInIcon /></StyledGreyDarkIcon>),
	"New camera-ready": (<DocumentScannerIcon color="green" />),
	"New preprint": (<DocumentScannerIcon color="green" />),
	"Camera-ready file cleared": (<DocumentScannerIcon color="error" />),
	"Preprint file cleared": (<DocumentScannerIcon color="error" />),
	"Deadline set for": (<AccessAlarmIcon color="light" />),
	"New comment": (<SmsIcon color="green" />),
	"Comment deleted": (<SmsIcon color="error" />),
	"Response to reviewers added": (<SelfImprovementIcon color="green" />),
	"Project created": (<ApiIcon color="green" />),
	"Project deleted": (<ApiIcon color="error" />),
};

export const getEventModule = (e) => {
	for (const key in relations) {
		if (e.message.includes(key)) {
			return relations[key];
		}
	}

	return null;
};
