import { Input as MUIInput, InputAdornment /* , Typography */ } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useState /* , useRef */ } from "react";

const useStyles = makeStyles((theme) => ({
	search: {
		background: theme.palette.primary.main,
		color: "third",
		borderRadius: "10px",
		position: "relative",
		padding: "5px 10px",
	},
	inputColor: {
		color: theme.palette.third.main,
	},
}));

const Search = ({
	value: searchValue,
	width = "100%",
	onChange,
}) => {
	const classes = useStyles();
	const [value, setValue] = useState(searchValue);
	// const inputRef = useRef();

	useEffect(() => {
		setValue(searchValue);
	}, [searchValue]);

	return (
		<MUIInput
			// ref={inputRef}
			disableUnderline
			type="search"
			value={value}
			name="search"
			size="small"
			placeholder="Search..."
			className={classes.search}
			sx={{ width }}
			startAdornment={(
				<InputAdornment
					// sx={{ position: "absolute", display: value ? "none" : "flex", marginLeft: "0px" }} position="end"
					position="start"
				>
					<SearchIcon color="third" />
					{/* <Typography ml={1}>{"Search"}</Typography> */}
				</InputAdornment>
			)}
			classes={{ input: classes.inputColor }}
			onChange={onChange}
		/>
	);
};

export default memo(Search);
