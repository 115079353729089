import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import Badge from '@mui/material/Badge';
import { ToggleButton, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line camelcase
import Members_icons2 from "../assets/teamIcons/Members_icons2.png";
// eslint-disable-next-line camelcase
import Phd_icon2 from "../assets/teamIcons/Phd_icon2.png";
// eslint-disable-next-line camelcase
import Projects_icons2 from "../assets/teamIcons/Projects_icons2.png";
// eslint-disable-next-line camelcase
import Publi_icon2 from "../assets/teamIcons/Publi_icon2.png";
// eslint-disable-next-line camelcase
import Theses_icon2 from "../assets/teamIcons/Theses_icon2.png";

const useStyles = makeStyles((theme) => ({
	teamCard: {
		minHeight: "60px",
		borderRadius: "20px",
		alignItems: "center",
		background: theme.palette.secondary.main,
		boxShadow: "10px 3px 10px -5px #262835",
		flexDirection: "column",
	},
	deleteButton: {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.main,
		border: "1px solid",
		margin: "0.1rem 0.2rem",
		"&:hover": {
			borderColor: theme.palette.error.dark,
			color: theme.palette.error.dark,
		},
	},
	showButton: {
		backgroundColor: theme.palette.third.main,
		margin: "0.1rem 0.2rem",
		"&:hover": {
			backgroundColor: theme.palette.third.dark,
		},
	},
}));

const teamContentInfo = [
	{
		type: "publication",
		// eslint-disable-next-line camelcase
		img: Publi_icon2,
	},
	{
		type: "master_thesis",
		// eslint-disable-next-line camelcase
		img: Theses_icon2,
	},
	{
		type: "phd_thesis",
		// eslint-disable-next-line camelcase
		img: Phd_icon2,
	},
	{
		type: "project",
		// eslint-disable-next-line camelcase
		img: Projects_icons2,
	},
];

export const Team = ({
	team,
	index,
	setDeleteTeam,
	setDeleteTeamPopupOpen,
}) => {
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<Grid
			key={`comp_${index}`}
			container
			item
			xs={3.5}
			p={2}
			m={1}
			className={classes.teamCard}
			elevation={3}
		>
			<Grid
				item
				sx={{
					borderRadius: 10,
					flex: 1,
					m: 1,
					pt: 1,
					px: 1,
				}}
			>
				{team.logoUrl && (
					<img
						src={team.logoUrl}
						alt=""
						style={{
							maxHeight: "70px",
							borderRadius: "10px",
						}}
					/>
				)}
				{!team.logoUrl && <ImageNotSupportedIcon color="white" fontSize="large" />}
			</Grid>
			<Grid
				item
				sx={{
					flex: 1,
					mb: 0.5,
				}}
			>
				<Typography fontSize="1.5rem" color="#fff" fontWeight="bold">
					{team.name}
				</Typography>
			</Grid>
			<Grid
				item
				sx={{
					flex: 1,
					mb: 0.5,
				}}
			>
				<Typography variant="caption" color="#fff" fontSize="0.8rem" display="block">
					{team.description}
				</Typography>
			</Grid>
			<Grid
				item
				container
				m={1}
				flex={1}
				width="100%"
				justifyContent="space-between"
			>
				<Grid item alignSelf="center">
					<ToggleButton
						value="Show"
						title="Show"
						size="small"
						aria-label="Show"
						className={classes.showButton}
						onClick={() => { navigate(`/teams/${team._id}`); }}
					>
						<VisibilityIcon color="white" />
					</ToggleButton>
					<ToggleButton
						value="Delete"
						title="Delete"
						size="small"
						aria-label="Delete"
						className={classes.deleteButton}
						onClick={() => {
							setDeleteTeam({ id: team._id, name: team.name });
							setDeleteTeamPopupOpen(true);
						}}
					>
						<DeleteIcon />
					</ToggleButton>
				</Grid>
				<Grid item alignSelf="center" mr={3}>
					<Badge badgeContent={team.members.length} color="primary" sx={{ marginRight: 2, color: "#fff" }} max={999_999}>
						<img
							// eslint-disable-next-line camelcase
							src={Members_icons2}
							alt=""
							style={{
								height: "30px",
								width: "30px",
							}}
						/>
					</Badge>
					{
						teamContentInfo.map((content, _index) => (
							<Badge key={_index} badgeContent={team.stats[content.type]} color="primary" sx={{ marginRight: 2, color: "#fff" }} max={999_999}>
								<img
									src={content.img}
									alt=""
									style={{
										height: "30px",
										width: "30px",
									}}
								/>
							</Badge>
						))
					}
				</Grid>
			</Grid>
		</Grid>
	);
};
